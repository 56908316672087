import find from "lodash/find";

export async function updateStoreByTypeSupport(store, serverRes, history) {
    const { allowedSegments, isAllowedToSendNotification, isCanApproveHideCoupons, isAllowedToCheckEntitlement, isCanPurchaseCoupons, isCanAddSteps } = serverRes;
    store.isAllowedToSendNotification = isAllowedToSendNotification;
    store.isCanApproveHideCoupons = isCanApproveHideCoupons;
    store.isCanPurchaseCoupons = isCanPurchaseCoupons;
    store.isCanAddSteps = isCanAddSteps;
    store.isAllowedToCheckEntitlement = isAllowedToCheckEntitlement;
    store.setAllowedSegments(allowedSegments);

    const currentSegmentInArray = store.currentSegmentId ? find(allowedSegments, item => item.segmentId == store.currentSegmentId) : null;
    const currentSegmentToSet = currentSegmentInArray || store.currentSegment || allowedSegments[0];

    store.setCurrentSegmentId(currentSegmentToSet.segmentId);
    store.setCurrentSegment(currentSegmentToSet, history);
    store.setDashboardFeatures(currentSegmentToSet.dashboardFeatures);
}
