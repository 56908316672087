export const validateFieldsBeforeSendNotification = store => {
    store.isEmptyNotificationText = false;
    store.isEmptyLink = false;
    store.isEmptyNotificationType = false;
    store.isEmptyLink = false;
    if (!store.text) {
        store.isEmptyNotificationText = true;
    }
    if (!store.isExternalNot && !store.isInternalNot) {
        store.isEmptyNotificationType = true;
    }
    if (!store.link && store.navigateTo == "OPEN_URL") {
        store.isEmptyLink = true;
    }
};
