export const activationTypes = {
    CODE: 0,
    LINK: 1,
    CODE_AND_LINK: 2,
    FIT_CREDIT_COINS: 3,
    CODE_AND_PHONE_CALL: 4,
    DELIVERY: 5,
    PICKUP: 6,
    DONATION: 7,
    COLLECTING_POINT: 8,
    MULTI_OPTION: 9,
    HEALTH_REPORT: 10,
};
