import React, { useEffect, useState } from "react";
import { makeStyles } from "@mui/styles";
import Grid from "@mui/material/Unstable_Grid2";
import { Page } from "components";
import { observer } from "mobx-react";
import { useRootStore } from "commonStores/analytics/rootStoreContext";
import { createScreenHomeStore, ScreenHomeStoreProvider } from "./store";
import { t } from "common/localization/translate";
import { Header } from "./components/header";
import { GeneralTotalNumbersCubesRow } from "./components/totalNumbersCubesRow";
import { TotalClicksChart } from "./components/totalClicksChart";
import { LoadingSpinner } from "common/ui/loadingSpinner";
import { TotalImpressionChart } from "./components/totalImpressionChart";
import { TopCoupons } from "./components/clickOnCoupons/components/index";

const useStyles = makeStyles(theme => ({
    root: {
        padding: theme.spacing(3),
        width: "100%",
        height: "100%",
    },
    container: {
        "& > *": {
            height: "100%",
            padding: theme.spacing(1.5),
        },
        row: {
            height: "100%",
        },
    },
}));
export const Home = observer(() => {
    const rootStore = useRootStore();
    const [store] = useState(() => createScreenHomeStore(rootStore));
    const classes = useStyles();
    useEffect(() => {
        store.getScreenData();
    }, []);
    const { isFetching } = store;
    return (
        <ScreenHomeStoreProvider value={store}>
            <LoadingSpinner isFetching={isFetching}>
                <Page className={classes.root} title={t("business.home.title")}>
                    <Header />
                    <GeneralTotalNumbersCubesRow />
                    <Grid className={classes.container} container spacing={3}>
                        <TotalImpressionChart />
                        <TotalClicksChart />
                        <TopCoupons />
                    </Grid>
                </Page>
            </LoadingSpinner>
        </ScreenHomeStoreProvider>
    );
});
