import React from "react";
import { makeStyles } from "@mui/styles";
import { observer } from "mobx-react";

import { Card, CardHeader, CardContent, Divider, Avatar } from "@mui/material";

import AssessmentOutlinedIcon from "@mui/icons-material/AssessmentOutlined";
import PieChart from "common/ui/pieChart";
import { t } from "common/localization/translate";
import { useScreenHomeStore } from "../../store";
import { LoadingSpinner } from "common/ui/loadingSpinner";
import NumberCube from "./components/numberCube";

const useStyles = makeStyles(theme => ({
    root: {
        marginTop: theme.spacing(5),
        height: 500,
    },
    content: {
        padding: 0,
        "&:last-child": {
            paddingBottom: 0,
            height: "90%",
        },
    },
    chartContainer: {
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
    },
    chart: {
        height: 281,
    },
    statsContainer: {
        display: "flex",
    },
    statsItem: {
        flexGrow: 1,
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        padding: theme.spacing(3, 2),
        "&:not(:last-of-type)": {
            borderRight: `1px solid ${theme.palette.divider}`,
        },
    },
    avatar: {
        backgroundColor: theme.palette.primary.main,
        height: 40,
        width: 40,
        marginLeft: theme.spacing(7),
    },
}));

const BonusesChart = observer(() => {
    const classes = useStyles();
    const { arrayOfBonusesForChart, isFetching } = useScreenHomeStore();
    return (
        <Card className={classes.root}>
            <CardHeader
                action={
                    <Avatar className={classes.avatar} size="small">
                        <AssessmentOutlinedIcon />
                    </Avatar>
                }
                title={t("screenHome.bonusesTotals.title")}
            />
            <Divider />
            <CardContent className={classes.content}>
                <LoadingSpinner isFetching={isFetching} isFullHeight={true}>
                    <div>
                        <div className={classes.chartContainer}>
                            <PieChart className={classes.chart} data={arrayOfBonusesForChart} />
                        </div>
                        <Divider />
                        <div className={classes.statsContainer}>
                            {arrayOfBonusesForChart.map(item => (
                                <NumberCube item={item} key={item.id} />
                            ))}
                        </div>
                    </div>
                </LoadingSpinner>
            </CardContent>
        </Card>
    );
});

export default BonusesChart;
