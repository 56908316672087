import React from "react";
import { makeObservable, action, observable, computed } from "mobx";
import { getScreenData as getScreenDataServer } from "./server/getScreenData";
import { getPurchasesTableData as getPurchasesTableDataLogic } from "./logic/getPurchasesTableData";
import { getNowInMoment, subtractFromDate } from "common/dateAndTime/moment";

const ScreenPurchasesStoreContext = React.createContext({});
export const ScreenPurchasesStoreProvider = ScreenPurchasesStoreContext.Provider;
export const useScreenPurchasesStore = () => React.useContext(ScreenPurchasesStoreContext);

class ScreenPurchasesStore {
    constructor(rootStore) {
        this.rootStore = rootStore;
        this.initProperties();
        makeObservable(this, {
            isFetching: observable,
            couponsPurchasesCount: observable,
            startDate: observable,
            endDate: observable,
            purchasesTableData: computed,
            initProperties: action.bound,
            getScreenData: action.bound,
            setStartDate: action.bound,
            setEndDate: action.bound,
            setIsFetching: action.bound,
            setCouponsPurchasesCount: action.bound,
        });
    }

    initProperties() {
        this.isFetching = false;
        this.couponsPurchasesCount = 0;
        this.endDate = getNowInMoment();
        this.startDate = subtractFromDate(this.endDate, 7, "days");
    }

    async getScreenData() {
        const segmentId = this.rootStore.userInfoStore.currentSegment ? this.rootStore.userInfoStore.currentSegment.segmentId : null;
        if (!segmentId) {
            return;
        }
        this.isFetching = true;
        const couponsPurchasesCount = await getScreenDataServer(segmentId, this.startDate, this.endDate);
        this.setCouponsPurchasesCount(couponsPurchasesCount);
        this.setIsFetching(false);
    }

    setCouponsPurchasesCount(couponsPurchasesCount) {
        this.couponsPurchasesCount = couponsPurchasesCount;
    }

    setStartDate(startDate) {
        this.startDate = startDate;
    }

    setEndDate(endDate) {
        this.endDate = endDate;
    }

    setIsFetching(isFetching) {
        this.isFetching = isFetching;
    }

    get purchasesTableData() {
        return getPurchasesTableDataLogic(this);
    }
}

export function createScreenPurchasesStore(rootStore) {
    const store = new ScreenPurchasesStore(rootStore);
    return store;
}
