import React from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import { observer } from "mobx-react-lite";
import { useScreenValidationCodeStore } from "../store";
import { makeStyles } from "@mui/styles";
import { t } from "common/localization/translate";

const useStyles = makeStyles(theme => ({
    title: {
        backgroundColor: theme.palette.primary.main,
        "& h6": {
            color: theme.palette.white,
            fontSize: 16,
            fontFamily: "Assistant",
            fontWeight: "bold",
        },
    },
    content: {
        fontFamily: "Assistant",
        fontSize: 14,
    },
}));

export const SuccessChangeValidationPopUp = observer(() => {
    const store = useScreenValidationCodeStore();
    const classes = useStyles();
    const handleClose = () => {
        store.isSuccessChangeValidationPopUp = false;
    };

    return (
        <div>
            <Dialog open={store.isSuccessChangeValidationPopUp}>
                <DialogTitle className={classes.title}>{t("suppliers.validation.changeValidation")}</DialogTitle>
                <DialogContent>
                    <DialogContentText>{t("suppliers.validation.changeValidationIsSucceeded")}</DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleClose} color="primary" autoFocus>
                        אחלה
                    </Button>
                </DialogActions>
            </Dialog>
        </div>
    );
});
