import React from "react";
import Dialog from "@mui/material/Dialog";
import { makeStyles } from "@mui/styles";
import { useScreenPurchaseFromSupportStore } from "../../store/index";
import { observer } from "mobx-react-lite";
import { PurchaseWithCardInputs } from "../addressForm/components/purchaseWithCardInputs";
import { DeliveryModalContent } from "./deliveryModalContent";
import { OtpContainer } from "./otpContainer";
import { Close } from "@mui/icons-material";

const useStyles = makeStyles(() => ({
    dialogDiv: {
        height: "auto",
    },
}));

export const DeliveryModal = observer(() => {
    const store = useScreenPurchaseFromSupportStore();
    const { isShowDeliveryModal, isWithCurrency, shouldShowOtp, shouldAskForEmailBeforeCreditPurchase } = store;
    const classes = useStyles();

    const onClose = () => {
        if (shouldShowOtp || isWithCurrency) {
            store.cleanDeliveryInfo();
            store.setIsDeliveryModalVisible(false);
            store.paymentUrl = "";
            store.isWithCurrency = false;
            store.shouldShowOtp = false;
            store.otpError = false;
            store.otpApproved = false;
            shouldShowCloseButton = false;
            containerStyle = null;
            store.streetAndHouseSearch = null;
            store.streetAndHouseSearch = null;
            store.selectedPickupPoint = null;
        }
        if (isWithCurrency) {
            store.getScreenData();
        }
    };

    let content = <DeliveryModalContent />;
    let containerStyle, shouldShowCloseButton;

    if (isWithCurrency) {
        if (!shouldAskForEmailBeforeCreditPurchase) {
            containerStyle = { maxHeight: "80vh", overflowY: "auto", padding: 20 };
            shouldShowCloseButton = true;
        }
        content = <PurchaseWithCardInputs />;
    }

    if (shouldShowOtp) {
        containerStyle = null;
        shouldShowCloseButton = false;
        content = <OtpContainer />;
    }

    return (
        <div>
            <Dialog
                open={isShowDeliveryModal}
                aria-labelledby="responsive-dialog-title"
                onClose={onClose}
                maxWidth="sm"
                PaperProps={{
                    style: { position: "relative", overflow: "visible" },
                }}
            >
                {shouldShowCloseButton && (
                    <button
                        onClick={onClose}
                        style={{
                            position: "absolute",
                            top: 10,
                            right: 10,
                            background: "white",
                            border: "none",
                            cursor: "pointer",
                            zIndex: 1000,
                            padding: 8,
                            borderRadius: "50%",
                            boxShadow: "0px 2px 10px rgba(0,0,0,0.1)",
                        }}
                    >
                        <Close style={{ fontSize: 24 }} />
                    </button>
                )}
                <div className={[classes.dialogDiv, containerStyle]}>{content}</div>
            </Dialog>
        </div>
    );
});
