import React from "react";
import { makeStyles } from "@mui/styles";
import { observer } from "mobx-react";
// import { SizeAndColorInputs } from "./components/sizeAndColorInputs";
import { activationTypes } from "../../store/logic/coupontypeActivationTypes";
import { NameInput } from "./components/nameInput";
import { PhoneInput } from "./components/phoneInput";
import { DeliveryInputs } from "./components/deliveryInputs";
import { useScreenPurchaseFromSupportStore } from "../../store";
import { PickupInputs } from "./components/pickupInputs";

const useStyles = makeStyles(() => ({
    row: {
        width: "100%",
        display: "flex",
        justifyContent: "flex-start",
        marginTop: "20px",
        marginBottom: "35px",
        marginLeft: "-10px",
    },
}));

export const AddressForm = observer(() => {
    const classes = useStyles();
    const store = useScreenPurchaseFromSupportStore();
    const { activationType } = store.coupon;

    return (
        <form>
            <div className={classes.row}>
                <NameInput />
                <PhoneInput />
            </div>
            <div className={classes.row}>
                <DeliveryInputs activationType={activationType} />
                <PickupInputs activationType={activationType} />
            </div>
            {/* <SizeAndColorInputs /> */}
        </form>
    );
});

export const getIsDeliveryType = activationType => {
    const isDeliveryType = activationType == activationTypes.DELIVERY || activationType == activationTypes.PICKUP || activationType == activationTypes.COLLECTING_POINT;
    return isDeliveryType;
};
