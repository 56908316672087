import React from "react";
import { observer } from "mobx-react";
import { useScreenPurchaseFromSupportStore } from "../../../store";
import { makeStyles } from "@mui/styles";
import { Typography } from "@mui/material";
import { t } from "../../../../../../common/localization/translate";

const useStyles = makeStyles(() => ({
    streetsContainer: {
        display: "flex",
        position: "absolute",
        flexDirection: "column",
        gap: "0px",
        padding: "0px 16px 2px 16px",
        marginTop: "8px",
        maxWidth: "205px",
        minWidth: "205px",
        maxHeight: "150px",
        overflowY: "auto",
        border: "1px solid #ddd",
        borderRadius: "8px",
        backgroundColor: "#fff",
        "&::-webkit-scrollbar": {
            display: "none",
        },
        msOverflowStyle: "none",
        scrollbarWidth: "none",
        zIndex: 100,
    },
    listItem: {
        padding: "4px 0px",
        borderBottom: "1px solid #ddd",
        backgroundColor: "#fff",
        cursor: "pointer",
        transition: "background-color 0.2s ease",
        "&:last-child": {
            borderBottom: "none",
        },
    },
    spinnerContainer: {
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        height: "100%",
    },
    title: {
        fontSize: "16px",
        fontWeight: "600",
    },
}));

export const PickupPointsList = observer(() => {
    const store = useScreenPurchaseFromSupportStore();
    const classes = useStyles();
    const { pickupPoints, isOpenedPickupPoints, streetAndHouseSearch, isFetchingPickupPoints } = store;

    const handlePickupClick = address => {
        store.setSelectedPickupPoint(address);
        store.isOpenedPickupPoints = false;
    };

    if (!pickupPoints?.length || !isOpenedPickupPoints || !streetAndHouseSearch || isFetchingPickupPoints) {
        return null;
    }

    return (
        <div className={classes.streetsContainer}>
            {pickupPoints.map((address, index) => (
                <div key={index} className={classes.listItem} onClick={() => handlePickupClick(address)}>
                    <Typography className={classes.title}>{address.pickupName}</Typography>
                    <Typography>{`${address.street} ${address.house}, ${address.city}`}</Typography>
                    <Typography className={classes.title}>{t("screenPurchaseFromSupport.coupons.deliveryModal.pickup.collectionPoints.km", { distance: address.distance })}</Typography>
                </div>
            ))}
        </div>
    );
});
