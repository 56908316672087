import React from "react";
import { makeObservable, observable } from "mobx";
import { getScreenDataServer } from "./server/getScreenData";
import { hideCouponTypeServer } from "./server/hideCouponTypeServer";

const ScreenHideCouponTypesStoreContext = React.createContext({});
export const ScreenHideCouponTypesStoreProvider = ScreenHideCouponTypesStoreContext.Provider;
export const useScreenHideCouponTypesStore = () => React.useContext(ScreenHideCouponTypesStoreContext);

class ScreenHideCouponTypesStore {
    constructor(rootStore) {
        this.rootStore = rootStore;
        this.isFetching = false;
        this.couponTypesList = null;
        this.chosenCouponTypeId = null;
        this.couponTypeTitle = null;
        this.isAreYouSureHideCouponTypePopUp = null;
        this.isSuccessHideCouponType = null;
        this.isFetchingHidingCoupon = false;
        makeObservable(this, {
            isFetching: observable,
            couponTypesList: observable,
            chosenCouponTypeId: observable,
            couponTypeTitle: observable,
            isAreYouSureHideCouponTypePopUp: observable,
            isSuccessHideCouponType: observable,
            isFetchingHidingCoupon: observable,
        });
    }

    async getScreenData() {
        this.isFetching = true;
        const segmentId = this.rootStore.userInfoStore.currentSegment ? this.rootStore.userInfoStore.currentSegment.segmentId : null;
        if (!segmentId) {
            return;
        }
        const data = await getScreenDataServer(segmentId);
        if (data.isSuccess && data.extra.couponTypes.length > 0) {
            this.couponTypesList = data.extra.couponTypes;
            this.chosenCouponTypeId = data.extra.couponTypes[0].couponTypeId;
            this.couponTypeTitle = data.extra.couponTypes[0].couponTitle;
        }
        this.isFetching = false;
    }

    async hideCouponType() {
        this.isFetchingHidingCoupon = true;
        const segmentId = this.rootStore.userInfoStore.currentSegment ? this.rootStore.userInfoStore.currentSegment.segmentId : null;
        const data = await hideCouponTypeServer(this.chosenCouponTypeId, segmentId);
        this.isSuccessHideCouponType = data.isSuccess;
        this.isFetchingHidingCoupon = false;
    }
}

export function createScreenHideCouponTypesStore(rootStore) {
    const store = new ScreenHideCouponTypesStore(rootStore);
    return store;
}
