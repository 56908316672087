import React from "react";
import { observer } from "mobx-react";
import { EntitlementStatus } from "./entitlementStatus";
import { UnderageStatus } from "./underageStatus";
import { Typography } from "@mui/material";
import Grid from "@mui/material/Unstable_Grid2";
import { Phone } from "./phone";
import { makeStyles } from "@mui/styles";
import { t } from "common/localization/translate";

export const EntitlementData = observer(() => {
    const classes = useStyles();

    return (
        <Grid>
            <Typography className={classes.title}>{t("screenEntitlementData.entitlement")}</Typography>
            <EntitlementStatus />
            <UnderageStatus />
            <Phone />
        </Grid>
    );
});

const useStyles = makeStyles(() => ({
    title: {
        fontSize: "14px",
        display: "inline-block",
        paddingLeft: 5,
        fontWeight: 900,
        textDecoration: "underline",
    },
}));
