import React from "react";
import { makeStyles } from "@mui/styles";
import { Avatar, ListItem, ListItemAvatar, ListItemText, Typography } from "@mui/material";
import { t } from "common/localization/translate";
import Grid from "@mui/material/Unstable_Grid2";
import classNames from "classnames";

const useStyles = makeStyles(theme => ({
    avatar: {
        fontSize: 14,
        fontWeight: theme.typography.fontWeightBold,
        color: theme.palette.white,
        backgroundColor: theme.palette.primary.dark,
    },
    listItem: {
        height: 60,
        direction: "column",
        alignItems: "center",
        justify: "center",
    },
    clicks: {
        direction: "column",
        textAlign: "end",
    },
    couponActive: {
        color: "green",
    },
    couponNonActive: {
        color: "red",
    },
    couponText: {
        alignSelf: "center",
    },
}));

export const SingleRow = props => {
    const { place, isWithDivider, name, count, purchasedCount, isActive } = props;
    const classes = useStyles();
    const purchasedCountText = t("business.home.clicksCube.purchases.per.coupon", { count: purchasedCount ? purchasedCount.count : 0 });
    const couponActive = isActive ? t("editCouponType.active") : t("editCouponType.inactive");
    return (
        <ListItem divider={isWithDivider} key={name} className={classes.listItem}>
            <Grid container>
                <Grid item xs={0.5}>
                    <ListItemAvatar>
                        <Avatar className={classes.avatar} size="small">
                            {place}
                        </Avatar>
                    </ListItemAvatar>
                </Grid>
                <Grid item xs={6} className={classes.couponText}>
                    <ListItemText primary={name} />
                </Grid>
                <Grid item xs={2} className={classes.couponText}>
                    <Typography variant="subtitle2" className={isActive ? classes.couponActive : classes.couponNonActive}>
                        {couponActive}
                    </Typography>
                </Grid>
                <Grid item xs={3} className={classNames(classes.clicks, classes.couponText)}>
                    <Typography variant="subtitle2">
                        {t("business.home.clicksCube.count", { count })} {purchasedCountText}
                    </Typography>
                </Grid>
            </Grid>
        </ListItem>
    );
};
