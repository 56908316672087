import React from "react";
import { SvgIcon } from "@mui/material";

export const AddStepsImage = props => (
    <SvgIcon {...props} viewBox="0 0 16 16" width="16" height="16">
        <path
            d="M4.07906 11.1967C3.67812 11.2555 3.01812 11.3649 2.57906 10.8667C2.13999 10.3686 1.76031 9.16579 1.59343 8.09079C1.34718 6.50642 1.59156 5.1711 2.28093 4.3311C2.68718 3.83642 3.22031 3.54985 3.82281 3.50423C4.33874 3.46454 5.10968 3.66642 5.85406 4.85392C6.32031 5.59798 6.67499 6.58642 6.82718 7.56548C7.01093 8.74735 7.18999 10.0086 6.56343 10.4608C6.20999 10.7161 5.95624 10.8045 5.35656 10.9474C4.86343 11.0645 4.48031 11.1377 4.07906 11.1967ZM3.36593 12.2133L6.76874 11.5114C7.05624 11.452 7.28687 11.6102 7.39374 12.0839C7.74437 13.6389 7.24187 15.4995 5.66812 15.4995C4.17781 15.4995 3.18468 13.8008 3.02249 12.8877C2.94843 12.4736 3.05406 12.2774 3.36593 12.2133ZM10.6431 7.94767C10.0434 7.80548 9.78968 7.71642 9.43624 7.4611C8.80968 7.00892 8.98874 5.74767 9.17249 4.56579C9.32468 3.58673 9.67937 2.59704 10.1456 1.85423C10.8894 0.666728 11.6603 0.464853 12.1769 0.504541C12.7787 0.550478 13.3122 0.836416 13.7184 1.3311C14.4084 2.1711 14.6528 3.50642 14.4059 5.09079C14.2387 6.16579 13.8603 7.36454 13.4187 7.86673C12.9772 8.36892 12.3209 8.25548 11.9187 8.19673C11.5166 8.13798 11.1359 8.06454 10.6431 7.94767ZM12.6334 9.21329L9.23062 8.51142C8.94312 8.45204 8.71249 8.61017 8.60562 9.08392C8.25531 10.6389 8.75781 12.4995 10.3316 12.4995C11.8219 12.4995 12.815 10.8008 12.9772 9.88767C13.0509 9.4736 12.9453 9.27735 12.6334 9.21329Z"
            fill={props.color || "currentColor"}
        />
    </SvgIcon>
);
