import React from "react";
import { observer } from "mobx-react";
import { useScreenHomeStore } from "../../../store";
import { t } from "common/localization/translate";
import InputLabel from "@mui/material/InputLabel";
import HelpIcon from "@mui/icons-material/Help";
import Tooltip from "@mui/material/Tooltip";
import { makeStyles } from "@mui/styles";
import { Button } from "@mui/material";
import Grid from "@mui/material/Unstable_Grid2";
import CircularProgress from "@mui/material/CircularProgress";
import Divider from "@mui/material/Divider";

const useStyles = makeStyles(theme => ({
    root: {
        marginTop: 20,
    },
    label: {
        fontSize: 14,
        fontWeight: "bold",
    },
    buttons: {
        backgroundColor: theme.palette.primary.main,
        fontSize: 15,
        width: 230,
        height: 40,
        color: theme.palette.white,
        marginRight: 5,
        marginTop: 5,
        "&:hover": {
            backgroundColor: theme.palette.primary.white,
        },
    },
    toolTipIcon: {
        color: theme.palette.primary.main,
    },
    divider: {
        backgroundColor: theme.palette.primary.main,
    },
}));

export const FinishLoadCodes = observer(props => {
    const { isProjectManager } = props;

    if (isProjectManager) {
        return null;
    }

    const store = useScreenHomeStore();
    const classes = useStyles();

    const onClickFinishEnteringCodes = () => {
        store.isAreYouSureFinishLoadCodesPopUp = true;
    };

    const finishLoadCodesButtonContent = store.isFetchingFinishLoadingCodes ? <CircularProgress color="divider" size={20} /> : t("suppliers.home.finishLoadCodesButton");
    return (
        <Grid className={classes.root} item xs={12} spacing={3}>
            <Divider className={classes.divider} flexItem />
            <br></br>
            <InputLabel className={classes.label}>{t("suppliers.home.finishLoadCodesTitle")}</InputLabel>
            <br></br>
            <Button variant="contained" className={classes.buttons} onClick={onClickFinishEnteringCodes}>
                {finishLoadCodesButtonContent}
            </Button>
            <Tooltip title={t("suppliers.home.finishLoadCodesToolTip")}>
                <HelpIcon className={classes.toolTipIcon} />
            </Tooltip>
        </Grid>
    );
});
