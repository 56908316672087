import React from "react";
import { makeObservable, action, observable } from "mobx";
import { getUserData as getScreenDataServer } from "./server/getScreenData";
import { editUserInfoErrorCodes } from "./logic/editUserInfoErrorCodes";
import { errorCodes } from "../../services/errorCodes";
import { validateIsraeliIdStructure } from "common/validateIsraeliIdStructure/validateIsraeliIdStructure";

const ScreenEntitlementStatusStoreContext = React.createContext({});
export const ScreenEntitlementStatusDataProvider = ScreenEntitlementStatusStoreContext.Provider;
export const useScreenEntitlementStatusStore = () => React.useContext(ScreenEntitlementStatusStoreContext);

class ScreenEntitlementStatusStore {
    constructor(rootStore) {
        this.rootStore = rootStore;

        this.userIdentifier = null;
        this.userEmail = null;
        this.entitlementHistory = null;
        this.otpCode = null;
        this.otpSentAt = null;
        this.isExpired = null;
        this.isUnderage = null;
        this.phone = null;
        this.entitlementStatus = null;
        this.serverError = null;
        this.isFetching = null;
        this.errorCode = null;
        this.isRemovedSpecialCharacters = null;
        this.isEntitled = null;
        this.requestStatus = null;
        this.requestSentAt = null;
        this.requestApprovedAt = null;
        this.userFound = null;

        makeObservable(this, {
            userIdentifier: observable,
            userEmail: observable,
            entitlementHistory: observable,
            otpCode: observable,
            otpSentAt: observable,
            isExpired: observable,
            isUnderage: observable,
            phone: observable,
            entitlementStatus: observable,
            serverError: observable,
            isFetching: observable,
            errorCode: observable,
            isRemovedSpecialCharacters: observable,
            isEntitled: observable,
            requestStatus: observable,
            requestSentAt: observable,
            requestApprovedAt: observable,
            userFound: observable,
            setUserIdentifier: action.bound,
            setServerError: action.bound,
            setOtpData: action.bound,
            setEntitlementData: action.bound,
            setErrorCode: action.bound,
            setEntitlementHistory: action.bound,
            setIsFetching: action.bound,
            setUnderageDetails: action.bound,
        });
    }

    setErrorCode(errorCode) {
        this.errorCode = errorCode;
    }
    setUserIdentifier(userIdentifier) {
        this.userIdentifier = userIdentifier;
    }

    setServerError(errorNum) {
        this.serverError = errorNum;
    }

    setEntitlementHistory({ entitlementHistory }) {
        if (!entitlementHistory) {
            this.entitlementHistory = null;
            return;
        }
        this.entitlementHistory = entitlementHistory;
    }

    setOtpData({ otpData }) {
        if (!otpData) {
            this.otpCode = null;
            this.otpSentAt = null;
            this.isExpired = null;
            return;
        }
        this.otpCode = otpData.code;
        this.otpSentAt = otpData.createdAt;
        this.isExpired = otpData.isExpired;
    }

    setEntitlementData(entitlementData) {
        if (!entitlementData) {
            return;
        }
        this.isUnderage = entitlementData.isUnderage;
        this.entitlementStatus = entitlementData.entitlementStatus;
        this.phone = entitlementData.phone;
    }

    setUnderageDetails({ underageDetails }) {
        if (!underageDetails) {
            return;
        }
        this.requestStatus = underageDetails.requestStatus;
        this.requestSentAt = underageDetails.sentAt;
        this.requestApprovedAt = underageDetails.approvedAt;
    }

    setUserFound(userFound) {
        if (!userFound) {
            this.userFound = false;
            this.setErrorCode(errorCodes.ERROR_TYPE_USER_NOT_EXISTS);
            return;
        }
        this.setErrorCode(null);
        this.userFound = userFound;
    }

    submitIdentifier() {
        this.setEntitlementData(null);
        const isValidId = validateIsraeliIdStructure(this.userIdentifier);
        if (isValidId) {
            this.getUserData();
            return;
        }
        this.setErrorCode(errorCodes.ERROR_TYPE_INVALID_DATA);
        return;
    }

    async getUserData() {
        try {
            if (this.isFetching) {
                return;
            }
            this.setIsFetching(true);
            const segmentId = this.rootStore.userInfoStore.currentSegment ? this.rootStore.userInfoStore.currentSegment.segmentId : null;
            const res = await getScreenDataServer(this.userIdentifier, segmentId);
            this.setUserFound(res.userFound);
            this.setEntitlementData({ isUnderage: res.isUnderage, entitlementStatus: res.is_entitled, phone: res.phone });
            this.setOtpData({ otpData: res.otpData });
            this.setEntitlementHistory({ entitlementHistory: res.entitlementHistory });
            this.setUnderageDetails({ underageDetails: res.underageDetails });
            this.setIsFetching(false);
        } catch (e) {
            this.setErrorCode(editUserInfoErrorCodes.ERROR_TYPE_USER_NOT_EXISTS);
            this.setIsFetching(false);
        }
    }

    setIsFetching(isFetching) {
        this.isFetching = isFetching;
    }
}

export function createScreenEntitlementStatusStore(rootStore) {
    const store = new ScreenEntitlementStatusStore(rootStore);
    return store;
}
