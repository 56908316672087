import React from "react";
import { FormControlLabel } from "@mui/material";
import Grid from "@mui/material/Unstable_Grid2";
import Checkbox from "@mui/material/Checkbox";
import { observer } from "mobx-react";
import { useBillingReportSystemStore } from "../../store/index";
import { t } from "common/localization/translate";

export const IsDeliverySystem = observer(() => {
    const store = useBillingReportSystemStore();

    const handleChange = event => {
        store.isDeliverySystem = event.target.checked;
    };

    return (
        <Grid item xs={12} spacing={3}>
            <FormControlLabel control={<Checkbox checked={store.isDeliverySystem ? true : false} onChange={handleChange} />} label={t("suppliers.billingReportSystem.rejectCodes.isDelivery")} />
        </Grid>
    );
});
