import { resources as resourcesHeb } from "./resourcesHeb";
import { resources as resourcesEng } from "./resourcesEng";
import { getValidatedParams } from "./getValidatedParams";
import { getLanguage } from "./getLanguage";
import { languages } from "./languages";

export function t(key, parameters, language) {
    language = language || getLanguage();
    let resources;
    let str;

    switch (language) {
        case languages.HEBREW:
            resources = resourcesHeb;
            break;
        case languages.ENGLISH:
            resources = resourcesEng;
            break;
        default:
            resources = resourcesHeb;
            break;
    }

    str = resources[key];

    // Saftey net. Falling back to hebrew:
    if (!str) {
        str = resourcesHeb[key];
    }

    if (str && parameters) {
        const validateParameters = getValidatedParams(parameters);
        const params = {};
        for (const [key, value] of Object.entries(validateParameters)) {
            params[`{{${key}}}`] = value;
        }

        const re = new RegExp(Object.keys(params).join("|"), "gi");
        str = str.replace(re, matched => params[matched]);
    }
    return str;
}
