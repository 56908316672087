export const couponsErrorCodes = {
    ERROR_TYPE_USER_NOT_EXISTS: 1,
    ERROR_TYPE_INVALID_DATA: 2,
    ERROR_TYPE_COUPON_NOT_HIDDEN: 3,
    ERROR_TYPE_COUPON_ALREADY_HIDDEN: 4,
    ERROR_TYPE_RATE_LIMITER_IS_EXCEEDED: 5,
    ERROR_TYPE_CANCELINING_COUPON_WITH_DELIVERY_TYPE_IS_ILLEGAL: 6,
    ERROR_TYPE_CANCELINING_COUPON_ECO: 7,
    ERROR_TYPE_COUPON_ALREADY_SUBBMITED_FOR_HIDE: 8,
};
