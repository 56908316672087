import { httpCall } from "common/httpCall";
import { saveLocalData } from "./saveLocalData";
import { getErrorTextByErrorCode } from "screens/organization/login/store/logic/getErrorTextByErrorCode";

export async function onVerifyOtpForLogin({ store }) {
    store.errorText = "";
    store.setIsLoading(true);
    const { isSuccess, errorCode, extra } = await httpCall(`webDashboards/${store.dashboardType}/screenLogin/verifyOtp`, {
        otpCode: store.otpCode,
        password: store.password,
        email: store.email,
        dashboardType: store.dashboardType,
    });
    store.setIsLoading(false);

    if (isSuccess) {
        const { allowedSegments, currentSegment, currentBusiness } = extra;
        const segmentToShow = currentSegment || allowedSegments ? allowedSegments[0] : null;
        store.rootStore.userInfoStore.initProperties({
            segmentId: segmentToShow,
            businessId: currentBusiness,
            dashboardType: store.dashboardType,
        });
        saveLocalData(store.dashboardType, segmentToShow, currentBusiness);
        store.password = null;
        store.email = null;
        store.otpCode = null;
        store.allowedSegments = allowedSegments;
        store.wizardStep = "chooseSegment";
    } else {
        const errorText = getErrorTextByErrorCode(errorCode);
        store.errorText = errorText;
    }
}
