import React from "react";

export function replaceHrefTagsWithLinks(couponDescription) {
    if (!couponDescription) {
        return null;
    }

    const pat = /<a href="(.*?)">(.*?)<\/a>/g;
    const split = couponDescription.split(pat);

    const elements = split.map((s, index) => {
        if (index % 3 === 1) {
            const link = s;
            const label = split[index + 1] || link;
            return (
                <a href={link} key={index} style={styles.link} target="_blank" rel="noopener noreferrer">
                    {label}
                </a>
            );
        }
        return (
            <React.Fragment key={index}>
                {" "}
                <span style={styles.text}>{s}</span>
            </React.Fragment>
        );
    });

    return <>{elements}</>;
}

const styles = {
    text: {
        direction: "rtl",
        unicodeBidi: "embed",
    },
    link: {
        color: "#007BFF",
        textDecoration: "underline",
        cursor: "pointer",
        direction: "rtl",
        unicodeBidi: "embed",
    },
};
