import { createTheme } from "@mui/material/styles";
import { getPaletteForTheme } from "./palette";
import typography from "./typography";
import { getCurrentPath } from "utils/direction/getCurrentPath";
import { getOverrides } from "./logic/getOverrides";
import { getIsLtr } from "./getIsLtr";

const theme = (mainColor, languageCode) => {
    const isLtr = getIsLtr(languageCode);
    const { isOrganizationLoginPath } = getCurrentPath();
    const overrides = getOverrides({ isOrganizationLoginPath });

    return createTheme({
        palette: getPaletteForTheme(mainColor),
        typography,
        components: overrides,
        direction: isLtr || isOrganizationLoginPath ? "ltr" : "rtl",
    });
};

export default theme;
