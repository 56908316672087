import React from "react";
import { observer } from "mobx-react";
import { useScreenAppOpenByHoursStore } from "../store/index";
import { SingleDatePicker } from "common/ui/datePickerV2/singleDatePicker";

export const DatePicker = observer(() => {
    const store = useScreenAppOpenByHoursStore();
    const { startDate } = store;
    const onChangeStartDate = startDate => {
        const { _d: date } = startDate;
        store.setStartDate(date);
    };

    return <SingleDatePicker onChangeStartDate={onChangeStartDate} initialDate={startDate} />;
});
