import React from "react";
import { observer, useObserver } from "mobx-react";
import { t } from "common/localization/translate";
import MUIDataTable from "mui-datatables";
import { useScreenCouponsStore } from "../store/index";
import { UnSuccessCancelCoupun } from "./popUps/UnSuccessCancelCoupun";
import { useRootStore } from "commonStores/analytics/rootStoreContext";
import { tableDataOptions } from "common/ui/tableDataOptions";

export const CouponsTable = observer(() => {
    const store = useScreenCouponsStore();
    const { userInfoStore } = useRootStore();
    const { dashboardFeatures } = userInfoStore;

    return useObserver(() => {
        const isShowOrderIdForCouponsScreenSupportDashboard = dashboardFeatures && dashboardFeatures.isShowOrderIdForCouponsScreenSupportDashboard;
        const { couponsUserTableData, isFetching, errorCode, userName, setCurrentRow, currentRow } = store;

        const { columns } = getTableData(isFetching, isShowOrderIdForCouponsScreenSupportDashboard);

        const onRowsSelect = data => {
            const { dataIndex: index } = data[0];
            setCurrentRow(index);
        };

        const rowsSelected = currentRow === null ? [] : [currentRow];
        const tableOptions = { ...options, onRowsSelect, rowsSelected };

        if (errorCode || (couponsUserTableData?.length == 0 && !userName)) {
            return null;
        }

        return (
            <div>
                <MUIDataTable title={t("screenCouponsUser.table.title", { userName })} data={couponsUserTableData} columns={columns} options={tableOptions} />
                <UnSuccessCancelCoupun />
            </div>
        );
    });
});

const getTableData = (isFetching, isShowOrderIdForCouponsScreenSupportDashboard) => {
    const columns = [
        {
            name: "date",
            label: t("screenCouponsUser.table.col.date"),
            options: tableDataOptions,
        },
        {
            name: "business",
            label: t("screenCouponsUser.table.col.business"),
            options: tableDataOptions,
        },
        {
            name: "supplier",
            label: t("screenCouponsUser.table.col.supplier"),
            options: tableDataOptions,
        },
        {
            name: "couponTitle",
            label: t("screenCouponsUser.table.col.couponTitle"),
            options: tableDataOptions,
        },
        {
            name: "identifier",
            label: t("screenCouponsUser.table.col.couponCode"),
            options: tableDataOptions,
        },
        {
            name: "expireDate",
            label: t("screenCouponsUser.table.col.expireDate"),
            options: tableDataOptions,
        },
    ];

    if (isShowOrderIdForCouponsScreenSupportDashboard) {
        columns.splice(4, 1, {
            name: "couponCodeTitle",
            label: t("screenPurchaseUsersInfo.table.col.couponCodeTitle"),
            options: tableDataOptions,
        });
    }

    return { columns };
};

const options = {
    filter: false,
    download: false,
    print: false,
    viewColumns: false,
    responsive: "scrollMaxHeight",
    selectableRows: "single",
    customToolbarSelect: () => {},
    onRowsDelete: () => false,
    textLabels: {
        body: {
            noMatch: t("screenCouponsUser.table.col.noMatch"),
        },
        selectedRows: {
            text: t("screenCouponsUser.table.chosen"),
            delete: "Delete",
            deleteAria: "Delete Selected Rows",
        },
    },
};
