import React from "react";
import { observer } from "mobx-react";
import { handleInputNonNegativeValuesOnly } from "../handleInputNonNegativeValuesOnly";
import { handleLimitInputLength } from "../handleLimitInputLength";
import { InputBloodPressureForm } from "./inputBloodPressureForm";
import { t } from "common/localization/translate";
import { useHealthAndFitnessDataStore } from "../../../store/storeHealthAndFitnessDataStoreContext";

const INPUT_MAX_LENGTH = 3;

export const BloodPressureDiastolic = observer(({ stage }) => {
    const store = useHealthAndFitnessDataStore();
    const diastolicValue = store[stage].diastolic;

    const handleDiastolicChange = event => {
        const value = handleInputNonNegativeValuesOnly(event.target.value);
        const diastolicValue = handleLimitInputLength({ maxLength: INPUT_MAX_LENGTH, input: value });
        store.setBloodPressureDiastolic(diastolicValue, stage);
    };

    return <InputBloodPressureForm label={t("screenHealthAndFitnessData.bloodPressure.label")} subText={t("screenHealthAndFitnessData.bloodPressure.diastolic.subtext")} fieldName={`${stage}BloodPressureDiastolic`} onChange={handleDiastolicChange} value={diastolicValue} />;
});
