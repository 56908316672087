import { t } from "common/localization/translate";

export const verifyCouponCodesErrorTexts = {
    noErrorCodeWereEntered: t("suppliers.supplierHome.noErrorCodeWereEntered"),
    codesAreNoValid: t("suppliers.supplierHome.codesAreNotValid"),
};

export const verifyCouponCodesErrorCodes = {
    ERROR_NO_CODES_WERE_ENTERED_BY_SUPPLIER: 1,
    ERROR_NO_PRICE_FOR_COUPON: 2,
    ERROR_WHEN_UPDATE_VERIFIED_CODE_AS_IMPLEMENT_IN_DB: 3,
};
