import React from "react";
import { TextField } from "@mui/material";
import Grid from "@mui/material/Unstable_Grid2";
import { observer } from "mobx-react";
import { t } from "common/localization/translate";
import { makeStyles } from "@mui/styles";
import { useScreenUserBalanceStore } from "screens/support/balance/store";
import { GetBalanceErrorTextByErrorCode } from "screens/support/balance/store/logic/getBalanceErrorTextByErrorCode";
import { useRootStore } from "commonStores/analytics/rootStoreContext";

const useStyles = makeStyles(theme => ({
    input: {
        width: "155px",
    },
}));

export const IdNumInput = observer(props => {
    const rootStore = useRootStore();
    const store = useScreenUserBalanceStore();
    const inputProps = { maxLength: 10 };
    const placeHolder = rootStore.userInfoStore.isWithSearchByPhoneOption ? t("support.common.withPhone.placeHolder") : t("support.common.placeHolder");
    const classes = useStyles();
    const errorText = GetBalanceErrorTextByErrorCode(store.errorCode);

    const handleInputChange = event => {
        const { value } = event.target;
        store.setUserIdentifier(value);
    };
    const isError = store.errorCode ? true : false;

    return (
        <Grid item sm={4} lg={2} spacing={2}>
            <TextField className={classes.input} id="standard-basic" variant="standard" type="string" error={isError} helperText={errorText} placeholder={placeHolder} inputProps={inputProps} onChange={handleInputChange} />
        </Grid>
    );
});
