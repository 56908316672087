import React from "react";
import { Typography } from "@mui/material";
import { observer } from "mobx-react";
import { makeStyles } from "@mui/styles";
import { useScreenPurchaseFromSupportStore } from "../../../store";
import { t } from "../../../../../../common/localization/translate";

const useStyles = makeStyles(() => ({
    placeholder: {
        position: "absolute",
    },
    pickupText: {
        display: "flex",
        width: "205px",
        height: "53px",
        paddingTop: "15px",
        marginLeft: "15px",
        justifyContent: "flex-start",
        textAlign: "center",
        zIndex: -1,
    },
    selected: {
        width: "160px",
        height: "53px",
        paddingTop: "5px",
        marginLeft: "15px",
    },
}));

export const PickupPointsInputPlaceholder = observer(() => {
    const store = useScreenPurchaseFromSupportStore();
    const classes = useStyles();

    let placeholder = <Typography className={classes.pickupText}>{t("screenPurchaseFromSupport.coupons.deliveryModal.pickup.collectionPoints.placeholder")}</Typography>;
    if (store.selectedPickupPoint && store.streetAndHouseSearch) {
        const { pickupName, street, house, city } = store.selectedPickupPoint;
        placeholder = (
            <div className={classes.selected}>
                <Typography
                    sx={{
                        display: "-webkit-box",
                        WebkitBoxOrient: "vertical",
                        overflow: "hidden",
                        textOverflow: "ellipsis",
                        WebkitLineClamp: 1,
                        fontSize: "16px",
                        fontWeight: "600",
                    }}
                >
                    {pickupName}
                </Typography>
                <Typography
                    sx={{
                        display: "-webkit-box",
                        WebkitBoxOrient: "vertical",
                        overflow: "hidden",
                        textOverflow: "ellipsis",
                        WebkitLineClamp: 1,
                    }}
                >{`${street} ${house}, ${city}`}</Typography>
            </div>
        );
    }

    return <div className={classes.placeholder}>{placeholder}</div>;
});
