import { makeStyles } from "@mui/styles";
import { Typography } from "@mui/material";

const useStyles = makeStyles(theme => ({
    root: {},
    errorText: {
        color: "red",
        height: theme.spacing(5),
        marginTop: theme.spacing(1),
    },
}));

export function ErrorText({ errorText }) {
    const classes = useStyles();
    return <Typography className={classes.errorText}>{errorText}</Typography>;
}
