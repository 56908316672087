import { t } from "common/localization/translate";
import { login } from "../server/login";
import { getErrorTextByErrorCode } from "screens/organization/login/store/logic/getErrorTextByErrorCode";

export async function onSubmitEmailAndPassword({ store }) {
    const { email, password, dashboardType } = store;
    if (!email || !password) {
        store.errorText = t("screenLogin.description");
        return;
    }
    store.errorText = "";
    store.setIsLoading(true);

    const { isSuccess, extra, errorCode } = await login({ email, password }, dashboardType);
    store.setIsLoading(false);

    if (isSuccess) {
        store.lastDigitsPhone = extra.lastThreeDigits;
        store.wizardStep = "enterOtp";
        store.errorText = null;
    } else {
        const errorText = getErrorTextByErrorCode(errorCode);
        store.errorText = errorText;
    }
}
