import React from "react";
import { observer } from "mobx-react";
import { t } from "common/localization/translate";
import { useScreenConfirmHideCouponsStore } from "../store/index";
import { Button } from "@mui/material";
import Grid from "@mui/material/Unstable_Grid2";
import { makeStyles } from "@mui/styles";
import HelpIcon from "@mui/icons-material/Help";
import Tooltip from "@mui/material/Tooltip";

const useStyles = makeStyles(theme => ({
    buttons: {
        backgroundColor: theme.palette.primary.main,
        fontSize: 14,
        color: theme.palette.white,
        marginRight: 10,
        marginBottom: 10,
        "&:hover": {
            backgroundColor: theme.palette.primary.secondGraphColor,
        },
    },
    toolTipIcon: { color: theme.palette.primary.main, marginRight: 10 },
}));

export const ConfirmAndCancelButtons = observer(props => {
    const recoverTooltipTitle = (
        <div>
            {t("screenCouponUser.button.recover.1")} <br />
            {t("screenCouponUser.button.recover.2")} <br />
            {t("screenCouponUser.button.recover.3")}
        </div>
    );

    const deleteTooltipTitle = (
        <div>
            {t("screenCouponUser.button.delete.1")} <br />
            {t("screenCouponUser.button.delete.2")} <br />
            {t("screenCouponUser.button.delete.3")}
        </div>
    );

    const store = useScreenConfirmHideCouponsStore();
    const { isNowRowSelected, errorCode, setCouponName, restoreCoupon, setIsOpenDeleteCouponPopUp } = store;
    const isDisabled = !isNowRowSelected;
    const classes = useStyles();

    if (errorCode) {
        return null;
    }

    const handleClickCancelHiding = () => {
        setCouponName();
        restoreCoupon();
    };

    const handleClickConfirmHiding = () => {
        setCouponName();
        setIsOpenDeleteCouponPopUp(true);
    };

    return (
        <Grid>
            <Button disabled={isDisabled} className={classes.buttons} size="small" type="submit" variant="contained" onClick={handleClickCancelHiding}>
                {t("screenConfirmHideCouponsUser.button.cancelHiding")}
            </Button>
            <Tooltip title={recoverTooltipTitle} classes={classes}>
                <HelpIcon className={classes.toolTipIcon} />
            </Tooltip>
            <Button disabled={isDisabled} className={classes.buttons} size="small" type="submit" variant="contained" onClick={handleClickConfirmHiding}>
                {t("screenConfirmHideCouponsUser.button.confirmHiding")}
            </Button>
            <Tooltip title={deleteTooltipTitle} classes={classes}>
                <HelpIcon className={classes.toolTipIcon} />
            </Tooltip>
        </Grid>
    );
});
