import React, { Suspense, useState, useEffect } from "react";
import { renderRoutes } from "react-router-config";
import PropTypes from "prop-types";
import { makeStyles } from "@mui/styles";
import { LinearProgress } from "@mui/material";
import { NavBar, TopBar } from "./components";
import { observer } from "mobx-react";
import { LoadingSpinner } from "common/ui/loadingSpinner";
import { useRootStore } from "commonStores/analytics/rootStoreContext";
import { IdleTimerContainer } from "./idleTimerContainer";

const useStyles = makeStyles(() => ({
    root: {
        height: "100%",
        width: "100%",
        display: "flex",
        flexDirection: "column",
        overflow: "hidden",
    },
    topBar: {
        zIndex: 2,
        position: "relative",
    },
    container: {
        display: "flex",
        flex: "1 1 auto",
        overflow: "hidden",
    },
    navBar: {
        zIndex: 3,
        width: 256,
        minWidth: 256,
        flex: "0 0 auto",
    },
    content: {
        overflowY: "auto",
        flex: "1 1 auto",
    },
}));

const Dashboard = observer(props => {
    const { route, location, history } = props;
    const rootStore = useRootStore();
    const classes = useStyles();
    const [openNavBarMobile, setOpenNavBarMobile] = useState(false);
    useEffect(() => {
        rootStore.userInfoStore.getData(location.pathname, history);
    }, []);

    const handleNavBarMobileOpen = () => {
        setOpenNavBarMobile(true);
    };

    const handleNavBarMobileClose = () => {
        setOpenNavBarMobile(false);
    };

    const { userInfoStore } = useRootStore();
    const { isFetching } = userInfoStore;

    if (!userInfoStore.isLoggedIn) {
        return null;
    }

    return (
        <LoadingSpinner isFetching={isFetching}>
            <div className={classes.root}>
                <IdleTimerContainer history={history}> </IdleTimerContainer>
                <TopBar className={classes.topBar} onOpenNavBarMobile={handleNavBarMobileOpen} />
                <div className={classes.container}>
                    <NavBar className={classes.navBar} onMobileClose={handleNavBarMobileClose} openMobile={openNavBarMobile} />
                    <main className={classes.content}>
                        <Suspense fallback={<LinearProgress />}>{renderRoutes(route.routes)}</Suspense>
                    </main>
                </div>
            </div>
        </LoadingSpinner>
    );
});

Dashboard.propTypes = {
    route: PropTypes.object,
};

export default Dashboard;
