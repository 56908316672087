import React from "react";
import { makeStyles } from "@mui/styles";
import { observer } from "mobx-react";
import Grid from "@mui/material/Unstable_Grid2";
import SimpleCube from "common/ui/simpleCube";
import { t } from "common/localization/translate";
import { useScreenHomeStore } from "../store";
import { formatNumber } from "common/formatNumber";
import PaymentIcon from "@mui/icons-material/Payment";
import { segmentIds } from "screens/controlPanel/addCouponType/store/logic/segmentIds";

const useStyles = makeStyles(() => ({
    row: {
        width: "100%",
        paddingLeft: 10,
    },
}));

export const TotalUsersToDemandPayment = observer(() => {
    const store = useScreenHomeStore();
    const { date, totalUsersToDemandPayment, selectedSegmentName } = store;
    const segmentId = store.rootStore.userInfoStore.currentSegmentId;
    const textKey = segmentId == segmentIds.CLALIT_SEGMENT_ID ? "screenHome.totalNumbersCubesRow.totalMembersToDemandPayment" : "screenHome.totalNumbersCubesRow.totalMembersToDemandPaymentLeumit";
    const totalUsersToDemandPaymentFormatted = formatNumber(totalUsersToDemandPayment);
    if (!totalUsersToDemandPayment) {
        return null;
    }

    const classes = useStyles();

    return (
        <Grid item xs={12} md={6} lg className={classes.row}>
            <SimpleCube label={t(textKey, { selectedSegmentName, date })} value={totalUsersToDemandPaymentFormatted} icon={<PaymentIcon />} withoutMargin={true} />
        </Grid>
    );
});
