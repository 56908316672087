import React from "react";
import { SingleRow } from "./singleRow";
import { useScreenHomeStore } from "../../../store/index";
import { observer } from "mobx-react";
import { LeadingCoupons } from "../components/leadingCoupons";
import { makeStyles } from "@mui/styles";
import Grid from "@mui/material/Unstable_Grid2";

const useStyles = makeStyles(() => ({
    row: {
        height: "100%",
    },
}));

export const TopCoupons = observer(() => {
    const { arrayOfClicksByCouponType, purchasesPerCoupon } = useScreenHomeStore();
    const classes = useStyles();
    const rows = arrayOfClicksByCouponType
        ? arrayOfClicksByCouponType.map((item, index) => {
              const place = index + 1;
              const isWithDivider = index < arrayOfClicksByCouponType.length;
              const countToDisplay = item.value;
              const purchasedCount = purchasesPerCoupon ? purchasesPerCoupon.find(coupon => coupon.couponTypeId == item.couponTypeId) : null;
              return <SingleRow name={item.label} place={place} count={countToDisplay} isActive={item.isActive} isWithDivider={isWithDivider} key={item.id} purchasedCount={purchasedCount} />;
          })
        : null;

    return (
        <Grid item lg={12} xl={9} xs={12} className={classes.row}>
            <LeadingCoupons rows={rows} />
        </Grid>
    );
});
