import React from "react";
import { formatNumber } from "common/formatNumber";
import { t } from "common/localization/translate";
import { tableDataOptions } from "common/ui/tableDataOptions";

export function clalitTableData() {
    const columns = [
        {
            name: "day",
            label: t("screenDailyStepsAndGoals.table.col.day"),
            options: tableDataOptions,
        },
        {
            name: "dailySteps",
            label: t("screenDailyStepsAndGoals.table.col.dailySteps"),
            options: {
                ...tableDataOptions,
                customBodyRender: value => {
                    return <p style={{ textAlign: "right" }}>{formatNumber(value)}</p>;
                },
            },
        },
        {
            name: "goal",
            label: t("screenDailyStepsAndGoals.table.col.goal"),
            options: {
                ...tableDataOptions,
                customBodyRender: value => {
                    return <p style={{ textAlign: "right" }}>{formatNumber(value)}</p>;
                },
            },
        },
        {
            name: "goalMet",
            label: t("screenDailyStepsAndGoals.table.col.goalMet"),
            options: {
                ...tableDataOptions,
                customBodyRender: isGoalMet => {
                    const styles = { green: { color: "green" }, red: { color: "red" } };
                    const text = isGoalMet ? t("screenDailyStepsAndGoals.table.userDidMetGoal") : t("screenDailyStepsAndGoals.table.userDidNotMetGoal");
                    const style = isGoalMet ? styles.green : styles.red;
                    return <p style={{ ...style, textAlign: "right" }}>{text}</p>;
                },
            },
        },
        {
            name: "WeeklySteps",
            label: t("screenDailyStepsAndGoals.table.col.weeklySteps"),
            options: {
                ...tableDataOptions,
                customBodyRender: value => {
                    return <p style={{ textAlign: "right" }}>{formatNumber(value)}</p>;
                },
            },
        },
        {
            name: "weeklyGoal",
            label: t("screenDailyStepsAndGoals.table.col.weeklyGoal"),
            options: {
                ...tableDataOptions,
                customBodyRender: value => {
                    return <p style={{ textAlign: "right" }}>{formatNumber(value)}</p>;
                },
            },
        },
        {
            name: "goalMet",
            label: t("screenDailyStepsAndGoals.table.col.goalMet"),
            options: {
                ...tableDataOptions,
                customBodyRender: isweeklyGoalMet => {
                    const styles = { green: { color: "green" }, red: { color: "red" } };
                    const text = isweeklyGoalMet ? t("screenDailyStepsAndGoals.table.userDidMetGoal") : t("screenDailyStepsAndGoals.table.userDidNotMetGoal");
                    const style = isweeklyGoalMet ? styles.green : styles.red;
                    return <p style={{ ...style, textAlign: "right" }}>{text}</p>;
                },
            },
        },
    ];
    return columns;
}
