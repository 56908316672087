import React from "react";
import { makeStyles } from "@mui/styles";
import { observer } from "mobx-react";
import { SubmitButton } from "./components/submitButton";
import { IdNumInput } from "./components/idNumInput";
import { useScreenEntitlementStatusStore } from "../../store";
import { HorizontalSpace } from "screens/organization/healthAndFitnessData/components/idNumForm/components/horizontalSpace";

const useStyles = makeStyles(() => ({
    row: {
        width: "100%",
        display: "flex",
        marginLeft: 0.2,
        marginBottom: 10,
    },
}));

export const IdNumForm = observer(() => {
    const store = useScreenEntitlementStatusStore();
    const classes = useStyles();

    const onSubmit = e => {
        e.preventDefault();
        store.submitIdentifier();
    };

    return (
        <form className={classes.row} onSubmit={onSubmit}>
            <IdNumInput />
            <HorizontalSpace />
            <SubmitButton />
        </form>
    );
});
