import React from "react";
import clsx from "clsx";
import { Card, Typography } from "@mui/material";
import Grid from "@mui/material/Unstable_Grid2";
import { makeStyles } from "@mui/styles";
import { LoadingSpinner } from "common/ui/loadingSpinner";

const useStyles = makeStyles(theme => ({
    root: props => ({
        width: `${props.widthPrecentage}%`,
        padding: theme.spacing(3),
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        textAlign: "center",
        height: "100%",
        backgroundColor: props.backgroundColor || theme.palette.white,
    }),
    text: props => ({
        lineHeight: "35px",
        color: props.textColor || theme.palette.black,
    }),
    details: {
        display: "flex",
        alignItems: "center",
        flexWrap: "wrap",
    },
    label: { fontWeight: "bold" },
    row: { width: "100%" },
    value: { color: "#546e7a" },
}));

export const DataCard = props => {
    const classes = useStyles(props);
    const { className, label, subtitle, value, isFetching } = props;

    return (
        <Grid item xs={12} md className={classes.row}>
            <Card className={clsx(classes.root, className)}>
                <div>
                    <Typography variant="h3" gutterBottom className={clsx(classes.label, classes.text)}>
                        {label}
                    </Typography>
                    <br />
                    <LoadingSpinner isFetching={isFetching} height="20px">
                        <div className={classes.details}>
                            <Typography variant="h3" className={classes.value}>
                                {value}
                            </Typography>
                        </div>
                    </LoadingSpinner>
                    <Typography variant="h3" gutterBottom className={classes.text}>
                        {subtitle}
                    </Typography>
                </div>
            </Card>
        </Grid>
    );
};
