import React from "react";
import clsx from "clsx";
import PropTypes from "prop-types";
import { makeStyles } from "@mui/styles";
import { Card, Typography, Avatar } from "@mui/material";
import { LoadingSpinner } from "./loadingSpinner";

const useStyles = makeStyles(theme => ({
    root: props => ({
        width: `${props.widthPrecentage}%`,
        padding: theme.spacing(3),
        display: "flex",
        alignItems: "center",
        justifyContent: "space-between",
        height: props.isWithConstatHeight ? 110 : "100%",
        backgroundColor: props.isReversed ? theme.palette.primary.main : theme.palette.white,
    }),
    details: {
        width: "100%",
        display: "flex",
        alignItems: "center",
        flexWrap: "wrap",
        justifyContent: "start",
        [theme.breakpoints.up("lg")]: {
            justifyContent: "center",
        },
    },
    value: props => ({
        color: props.isReversed ? theme.palette.white : theme.palette.black,
    }),
    label: props => ({
        color: props.isReversed ? theme.palette.white : "#546e7a",
    }),
    avatar: props => ({
        backgroundColor: props.isReversed ? theme.palette.white : theme.palette.primary.main,
        height: 48,
        width: 48,
        marginLeft: props.withoutMargin ? theme.spacing(1) : theme.spacing(7),
    }),
}));

const SimpleCube = props => {
    const { className, label, value, icon, sideComponent, withoutMargin, isFetching, isReversed, ...rest } = props;

    const classes = useStyles(props);
    const isWhiteCircle = isReversed ? true : false;
    return (
        <Card {...rest} className={clsx(classes.root, className)}>
            <div>
                <Typography component="h3" gutterBottom variant="overline" className={classes.label}>
                    {label}
                </Typography>
                <LoadingSpinner isFetching={isFetching} isWhiteCircle={isWhiteCircle} height="20px">
                    <div className={classes.details}>
                        <Typography variant="h3" className={classes.value}>
                            {value}
                        </Typography>
                        {sideComponent}
                    </div>
                </LoadingSpinner>
            </div>
            <Avatar withoutMargin={withoutMargin} className={classes.avatar}>
                {icon}
            </Avatar>
        </Card>
    );
};

SimpleCube.propTypes = {
    className: PropTypes.string,
};

export default SimpleCube;
