import { useScreenLoginStore } from "screens/loginV2/store";
import { observer } from "mobx-react";
import { t } from "common/localization/translate";
import { GenericForm } from "common/genericForm/genericForm";

export const EnterOtp = observer(() => {
    const store = useScreenLoginStore();
    const regex = /^[0-9]*$/; // Only numbers from 0 to 9

    const onOtpCode = e => {
        if (regex.test(e.target.value) && e.target.value.length <= 6) {
            store.otpCode = e.target.value;
        }
    };
    return (
        <GenericForm
            title={t("login.otpCodeTitle", null, store.language)}
            subTitle={t(
                "login.otpDescription",
                {
                    lastDigitsPhone: store.lastDigitsPhone,
                },
                store.language
            )}
            field1Label={t("login.otpCodeTitle", null, store.language)}
            field1Value={store.otpCode}
            submitButtonLabel={t("buttons.common.next", null, store.language)}
            submitButton2Label={t("buttons.common.prev", null, store.language)}
            onSubmit={store.onVerifyOtpForLogin}
            onSubmit2={store.onGoBack}
            onField1Change={onOtpCode}
            errorText={store.errorText}
            isLoading={store.isLoading}
        />
    );
});
