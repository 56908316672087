import AuthLayout from "../layouts/Auth";
import { Redirect } from "react-router-dom";
import { Login as LoginV2 } from "screens/loginV2";
import { Home } from "screens/suppliers/home";
import { ValidationCode } from "screens/suppliers/validations/index";
import { dashboardPaths } from "screens/loginV2/store/logic/dashboardPaths";
import { BillingReportSystem } from "screens/suppliers/billingReportSystem";
import { ProjectManagerSupplier } from "screens/suppliers/projectManagerSupplier";
import { SupplierAndCouponReports } from "screens/suppliers/home/components/supplierAndCouponReports/index";
import { AuthWrapper } from "layouts/Dashboard/authWrapper";

export const suppliersRoutes = [
    {
        path: `/${dashboardPaths.SUPPLIERS}/login`,
        component: AuthLayout,
        routes: [
            {
                path: `/${dashboardPaths.SUPPLIERS}/login`,
                exact: true,
                component: LoginV2,
            },
        ],
    },
    {
        path: `/${dashboardPaths.SUPPLIERS}`,
        component: AuthWrapper,
        routes: [
            {
                path: `/${dashboardPaths.SUPPLIERS}/home`,
                exact: true,
                component: Home,
            },
            {
                path: `/${dashboardPaths.SUPPLIERS}/validationCodes`,
                exact: true,
                component: ValidationCode,
            },
            {
                path: `/${dashboardPaths.SUPPLIERS}/billingReportSystem`,
                exact: true,
                component: BillingReportSystem,
            },
            {
                path: `/${dashboardPaths.SUPPLIERS}/projectManagerSupplier`,
                exact: true,
                component: ProjectManagerSupplier,
            },
            {
                path: `/${dashboardPaths.SUPPLIERS}/supplierReports`,
                exact: true,
                component: SupplierAndCouponReports,
            },
            {
                path: `/${dashboardPaths.SUPPLIERS}/*`,
                exact: true,
                render: () => <Redirect to={{ pathname: `/${dashboardPaths.SUPPLIERS}/home` }} />,
            },
        ],
    },
];
