import React from "react";
import { makeStyles } from "@mui/styles";
import { InputLabel } from "@mui/material";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import { observer } from "mobx-react";
import Grid from "@mui/material/Unstable_Grid2";
import { t } from "common/localization/translate";

const useStyles = makeStyles(theme => ({
    formControl: {
        minWidth: 120,
    },
    selectEmpty: {
        marginTop: theme.spacing(2),
        width: 350,
        fontSize: 12,
    },
    label: {
        fontSize: 14,
        fontWeight: "bold",
    },
    menuItems: {
        width: 550,
        fontSize: 11,
    },
}));

export const CouponTypeDropDown = observer(props => {
    const { store } = props;
    const classes = useStyles();

    const handleChange = (event, item) => {
        store.couponTypeGroupId = event.target.value;
        store.couponTitle = item.props.name;
    };

    if (!store.couponTypes) {
        return null;
    }

    const menuItems = store.couponTypes.map(item => (
        <MenuItem className={classes.menuItems} value={item.groupId} name={item.couponTitle}>
            {item.couponTitle}
        </MenuItem>
    ));
    return (
        <Grid xs={12} spacing={3}>
            <InputLabel className={classes.label}>{t("suppliers.supplierHome.selectCouponType")} </InputLabel>
            <FormControl className={classes.formControl}>
                <Select value={store.couponTypeGroupId || menuItems[0]?.props?.value} onChange={handleChange} className={classes.selectEmpty} variant="standard">
                    {menuItems}
                </Select>
            </FormControl>
        </Grid>
    );
});
