import React from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import { makeStyles } from "@mui/styles";
import { useScreenPurchaseFromSupportStore } from "../../store/index";
import { observer } from "mobx-react-lite";
import { t } from "../../../../../common/localization/translate";

const useStyles = makeStyles(theme => ({
    root: {
        color: theme.palette.white,
        fontSize: 18,
        backgroundColor: theme.palette.primary.main,
        "& h6": {
            color: theme.palette.white,
            fontSize: 18,
        },
    },
    content: { marginTop: 8 },
    button: {
        backgroundColor: theme.palette.primary.main,
        fontSize: 14,
        color: theme.palette.white,
        marginLeft: 21,
        marginTop: 18,
        "&:hover": {
            backgroundColor: theme.palette.primary.secondGraphColor,
            color: theme.palette.white,
        },
        width: "150px",
    },
    buttonContainer: {
        display: "flex",
        justifyContent: "center",
    },
}));

export const UnsuccessPurchaseCouponModal = observer(() => {
    const store = useScreenPurchaseFromSupportStore();
    const { isSuccessPurchaseCoupon, purchaseCouponErrorCode, setPurchaseCouponErrorCode } = store;
    const classes = useStyles();

    const handleClose = () => {
        setPurchaseCouponErrorCode(null);
    };

    if (isSuccessPurchaseCoupon) {
        return null;
    }

    return (
        <div>
            <Dialog className={classes.dialog} open={purchaseCouponErrorCode} onClose={handleClose} aria-labelledby="responsive-dialog-title">
                <div style={{ width: 500, height: 180 }}>
                    <DialogTitle className={classes.root} id="responsive-dialog-title">
                        {t("screenPurchaseFromSupport.coupons.UnsuccessPurchaseModal.title")}
                    </DialogTitle>
                    <DialogContent className={classes.content}>
                        <DialogContentText>{t("screenPurchaseFromSupport.coupons.UnsuccessPurchaseModal.text")}</DialogContentText>
                    </DialogContent>
                    <DialogActions className={classes.buttonContainer}>
                        <Button className={classes.button} size="small" type="submit" variant="contained" onClick={handleClose}>
                            {t("screenPurchaseFromSupport.coupons.UnsuccessPurchaseModal.button")}
                        </Button>
                    </DialogActions>
                </div>
            </Dialog>
        </div>
    );
});
