import React, { useEffect } from "react";
import { observer } from "mobx-react";
import { activationTypes } from "../../../store/logic/coupontypeActivationTypes";
import { StreetAndHouseInput } from "./streetAndHouseInput";
import { useScreenPurchaseFromSupportStore } from "../../../store";
import { PickupPointsInput } from "./pickupPointsInput";

export const PickupInputs = observer(({ activationType }) => {
    if (activationType != activationTypes.PICKUP) {
        return null;
    }

    const store = useScreenPurchaseFromSupportStore();

    useEffect(() => {
        store.searchAllCollectionPoints();
    }, []);

    return (
        <>
            <StreetAndHouseInput />
            <PickupPointsInput />
        </>
    );
});
