import { t } from "common/localization/translate";
import { tableDataOptions } from "common/ui/tableDataOptions";

export function getTableHeaders({ isShowOrderIdForCouponsScreenSupportDashboard }) {
    const headers = [
        {
            name: "dateCreated",
            label: t("screenConfirmHideCoupons.table.col.dateCreated"),
            options: tableDataOptions,
        },
        {
            name: "ownerId",
            label: t("screenConfirmHideCoupons.table.col.ownerId"),
            options: tableDataOptions,
        },
        {
            name: "business",
            label: t("screenCouponsUser.table.col.business"),
            options: tableDataOptions,
        },
        {
            name: "couponTitle",
            label: t("screenCouponsUser.table.col.couponTitle"),
            options: tableDataOptions,
        },
        {
            name: "identifier",
            label: t("screenCouponsUser.table.col.couponCode"),
            options: tableDataOptions,
        },
        {
            name: "expireDate",
            label: t("screenCouponsUser.table.col.expireDate"),
            options: tableDataOptions,
        },
        {
            name: "submitterId",
            label: t("screenConfirmHideCoupons.table.col.submitterId"),
            options: tableDataOptions,
        },
        {
            name: "dateSubmitted",
            label: t("screenConfirmHideCoupons.table.col.dateSubmitted"),
            options: tableDataOptions,
        },
    ];

    if (isShowOrderIdForCouponsScreenSupportDashboard) {
        headers.splice(4, 1, { name: "couponCodeTitle", label: t("screenPurchaseUsersInfo.table.col.couponCodeTitle"), options: tableDataOptions });
    }

    return headers;
}
