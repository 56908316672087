import React from "react";
import { Link as RouterLink } from "react-router-dom";
import { observer } from "mobx-react";
import { makeStyles } from "@mui/styles";
import { useRootStore } from "commonStores/analytics/rootStoreContext";
import { isWowJuniorSegment } from "../../../../../screens/organization/home/store/logic/isWowJuniorSegment";

const useStyles = makeStyles(theme => ({
    avatar: {
        width: 60,
        height: 60,
        borderRadius: 50,
    },
    avatarWowJunior: {
        width: 130,
        height: 130,
        borderRadius: 50,
    },
}));

export const SideImage = observer(props => {
    const { userInfoStore } = useRootStore();
    const { navBarLogo, dashboardType } = userInfoStore;
    const classes = useStyles();
    const chosenClass = isWowJuniorSegment(userInfoStore.currentSegmentId) ? classes.avatarWowJunior : classes.avatar;

    if (!navBarLogo) {
        return <div className={classes.avatar} />;
    }

    return <img alt="navBarLogo" className={chosenClass} component={RouterLink} src={navBarLogo} to={`/${dashboardType}/home`} />;
});
