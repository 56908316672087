import React, { useState } from "react";
import { makeStyles } from "@mui/styles";
import { observer } from "mobx-react";
import { useRootStore } from "commonStores/analytics/rootStoreContext";
import { Page } from "components";
import Grid from "@mui/material/Unstable_Grid2";
import { t } from "common/localization/translate";
import { TitlesUserBalance } from "./components/TitlesUserBalance";
import { UserBalanceTable } from "./components/UserBalanceTable";
import { createScreenUserBalanceUsersStore, ScreenUserBalanceDataProvider } from "./store";

import { IdNumForm } from "./components/idNumForm";

const useStyles = makeStyles(theme => ({
    root: {
        padding: theme.spacing(3),
        flex: 1,
    },
    container: {
        "& > *": {
            height: "100%",
            padding: theme.spacing(0.5),
        },
    },
}));

export const UserBalance = observer(() => {
    const rootStore = useRootStore();
    const [store] = useState(() => createScreenUserBalanceUsersStore(rootStore));
    const classes = useStyles();
    const { userBalanceTableData } = store;
    return (
        <ScreenUserBalanceDataProvider value={store}>
            <Page className={classes.root} title={t("screenUserBalanceUser.title")}>
                <Grid className={classes.container} container item lg={12} xs={12} spacing={2}>
                    <TitlesUserBalance />
                    <IdNumForm />
                </Grid>
                <br />
                <UserBalanceTable UserBalanceUserTableData={userBalanceTableData} />
            </Page>
        </ScreenUserBalanceDataProvider>
    );
});
