import React from "react";
import Grid from "@mui/material/Unstable_Grid2";
import { observer } from "mobx-react";
import { t } from "common/localization/translate";
import InputLabel from "@mui/material/InputLabel";
import { makeStyles } from "@mui/styles";

const useStyles = makeStyles(() => ({
    label: {
        fontSize: 16,
        fontWeight: "bold",
    },
}));

export const ProjectManagerHomeTitle = observer(() => {
    const classes = useStyles();

    return (
        <Grid item xs={12} spacing={3}>
            <InputLabel className={classes.label}>{t("suppliers.ProjectManagerHome.MonthlyReportTitle")}</InputLabel>
        </Grid>
    );
});
