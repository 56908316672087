import React from "react";
import { t } from "common/localization/translate";
import { Typography } from "@mui/material";
import Grid from "@mui/material/Unstable_Grid2";
import { makeStyles } from "@mui/styles";

const useStyles = makeStyles(theme => ({
    container: {
        "& > *": {
            height: "100%",
            padding: theme.spacing(0.5),
        },
    },
}));
export const Titles = () => {
    const classes = useStyles();

    return (
        <Grid className={classes.container}>
            <Typography component="h2" gutterBottom variant="overline">
                {t("screenAddSteps.title")}
            </Typography>
            <Typography variant="subtitle2">{t("screenAddSteps.idNum.title")}</Typography>
        </Grid>
    );
};
