import React from "react";
import { observer } from "mobx-react";
import { t } from "common/localization/translate";
import { useScreenOrderDetailsStore } from "../store/index";
import MUIDataTable from "mui-datatables";
import { tableDataOptions } from "common/ui/tableDataOptions";

export const OrderDetailsTable = observer(() => {
    const store = useScreenOrderDetailsStore();
    const { orderDetails, isFetching, errorCode } = store;
    const { options, columns } = getTableData(isFetching);

    if (errorCode || !orderDetails) {
        return null;
    }

    return <MUIDataTable title={t("screenOrderDetails.title")} data={[orderDetails]} columns={columns} options={options} />;
});

const getTableData = () => {
    const columns = [
        {
            name: "couponName",
            label: t("screenOrderDetails.table.col.couponTitle"),
            options: tableDataOptions,
        },
        {
            name: "date",
            label: t("screenOrderDetailsUser.table.col.date"),
            options: tableDataOptions,
        },
        {
            name: "businessName",
            label: t("screenOrderDetails.table.col.business"),
            options: tableDataOptions,
        },
        {
            name: "userName",
            label: t("screenOrderDetails.table.col.userName"),
            options: tableDataOptions,
        },
        {
            name: "idNum",
            label: t("screenOrderDetails.table.col.idNum"),
            options: tableDataOptions,
        },
        {
            name: "email",
            label: t("screenOrderDetails.table.col.email"),
            options: tableDataOptions,
        },
        {
            name: "phone",
            label: t("screenOrderDetails.table.col.phone"),
            options: tableDataOptions,
        },
        {
            name: "fullAddressFormatted",
            label: t("screenOrderDetails.table.col.address"),
            options: tableDataOptions,
        },
    ];

    const options = {
        download: false,
        filter: false,
        print: false,
        viewColumns: false,
        responsive: "scrollMaxHeight",
        selectableRows: "none",
        textLabels: {
            body: {
                noMatch: t("screenUserBalanceUser.table.col.noMatch"),
            },
        },
    };
    return { options, columns };
};
