import React from "react";
import { makeStyles } from "@mui/styles";
import { observer } from "mobx-react";
import Grid from "@mui/material/Unstable_Grid2";
import { SubmitButton } from "./components/submitButton";
import { IdNumInput } from "./components/idNumInput";
import { useScreenSendNotificationStore } from "../../store/index";
import { ChooseAllUsersButton } from "../chooseAllUsersButton";
import { isMobileDevice } from "common/ui/isMobileDevice";

const useStyles = makeStyles(theme => ({
    container: {
        width: "100%",
        display: "flex",
        marginLeft: 0.5,
    },
    row: {
        width: "500px",
        display: "flex",
        marginLeft: 0.5,
    },
    justify: {
        justifyContent: "space-between",
    },
}));

export const IdNumForm = observer(() => {
    const store = useScreenSendNotificationStore();
    const classes = useStyles();
    const isMobile = isMobileDevice();
    const onSubmit = e => {
        e.preventDefault();
        store.submitIdNum();
    };

    return (
        <form className={classes.container} onSubmit={onSubmit}>
            <Grid className={[classes.row, isMobile ? null : classes.justify]} container lg={12} xs={12} spacing={1}>
                <IdNumInput />
                <SubmitButton />
                <ChooseAllUsersButton />
            </Grid>
        </form>
    );
});
