import React from "react";
import { Typography } from "@mui/material";
import Grid from "@mui/material/Unstable_Grid2";
import { observer } from "mobx-react";
import { t } from "common/localization/translate";
import { LoadingCodesInput } from "../../../home/components/supplier/loadingCodesSupplier/loadingCodesInput";
import { SubmitCodesForRejectionButton } from "./submitCodesForRejection";
import { useBillingReportSystemStore } from "../../store";
import { IsDeliverySystem } from "./isDeliverySystem";
import { RejectCodesSuccessPopUp } from "./rejectCodesSuccessPopup";

export const RejectCodes = observer(() => {
    const store = useBillingReportSystemStore();
    const { numberOfCodes } = store;
    return (
        <Grid item xs={12} spacing={3}>
            <LoadingCodesInput store={store} />
            <Typography>{numberOfCodes ? t("suppliers.billingReportSystem.numberOfCodes", { numberOfCodes }) : null}</Typography>
            <IsDeliverySystem />
            <SubmitCodesForRejectionButton />
            <RejectCodesSuccessPopUp />
        </Grid>
    );
});
