import { t } from "common/localization/translate";

export const errorCodes = {
    USER_NOT_FOUND: 1,
    NO_PERMISSION: 2,
    NO_BUSINESSES: 3,
    NOT_ALLOWED_SEGMENT: 4,
    PASSWORD_RESET_REQUIRED: 5,
    MAINTENANCE_MODE: 6,
    LIMIT_EXCEEDED: 7,
    RESET_PASSWORD_LIMIT_EXEEDED: 8,
    SEND_OTP_LIMIT_EXCEEDED: 10,
    VERIFY_OTP_LIMIT_EXCEEDED: 11,
    CODE_DOES_NOT_MATCH: 12,
};

export function getErrorTextByErrorCode(errorCode) {
    if (errorCode === errorCodes.PASSWORD_RESET_REQUIRED) {
        return t("screenLogin.errorText.resetPassword");
    }
    if (errorCode === errorCodes.MAINTENANCE_MODE) {
        return t("screenLogin.errorText.maintenence");
    }
    if (errorCode === errorCodes.LIMIT_EXCEEDED) {
        return t("screenLogin.errorText.limitExceeded");
    }
    if (errorCode === errorCodes.RESET_PASSWORD_LIMIT_EXEEDED) {
        return t("screenLogin.errorText.resetPasswordLimitExceeded");
    }
    if (errorCode === errorCodes.SEND_OTP_LIMIT_EXCEEDED) {
        return t("screenLogin.errorText.sendOtpLimitExceeded");
    }
    if (errorCode === errorCodes.CODE_DOES_NOT_MATCH) {
        return t("screenLogin.errorText.invalidCode");
    }
    if (errorCode === errorCodes.VERIFY_OTP_LIMIT_EXCEEDED) {
        return t("screenLogin.errorText.verifyOtpLimitExceeded");
    }

    return t("screenLogin.errorText");
}
