import React from "react";
import { makeObservable, action, observable } from "mobx";
import { t } from "common/localization/translate";
import { getDashboardByPathName } from "./logic/getDashboardByPathName";

import { getErrorTextByErrorCode } from "../../organization/login/store/logic/getErrorTextByErrorCode";
import { resetPasswordSendEmail } from "../../organization/login/store/server/resetPassword";
import { dashboardTypes } from "./logic/dashboardTypes";
import { onVerifyOtpForLogin } from "./logic/onVerifyOtpForLogin";
import { onSubmitEmailAndPassword } from "./logic/onSubmitEmailAndPassword";
import { onGoBack } from "./logic/onGoBack";
import { languages } from "common/localization/languages";

const ScreenLoginStoreContext = React.createContext({});
export const ScreenLoginStoreProvider = ScreenLoginStoreContext.Provider;
export const useScreenLoginStore = () => React.useContext(ScreenLoginStoreContext);

class ScreenLoginStore {
    constructor(rootStore, router) {
        this.rootStore = rootStore;

        this.initProperties(router);
        makeObservable(this, {
            email: observable,
            password: observable,
            otpCode: observable,
            lastDigitsPhone: observable,
            wizardStep: observable,
            errorText: observable,
            appType: observable,
            isResetPasswordNeeded: observable,
            isShowSuccessResetPasswordPopUp: observable,
            allowedSegments: observable,
            selectedSegment: observable,
            disableResetButton: observable,
            isLoading: observable,
            language: observable,
            setEmail: action.bound,
            setPassword: action.bound,
            setErrorText: action.bound,
            dashboardType: observable,
            initProperties: action.bound,
            disableResetPassword: action.bound,
            setIsLoading: action.bound,
            onSubmitEmailAndPassword: action.bound,
            onVerifyOtpForLogin: action.bound,
            onGoBack: action.bound,
            onSegmentSelected: action.bound,
            router: observable,
        });
    }

    initProperties(router) {
        this.email = "";
        this.password = "";
        this.errorText = "";
        this.appType = null;
        this.isResetPasswordNeeded = false;
        this.isShowSuccessResetPasswordPopUp = false;
        this.allowedSegments = null;
        this.selectedSegment = null;
        this.disableResetButton = false;
        this.isLoading = false;
        this.otpCode = "";
        this.lastDigitsPhone = null;
        this.wizardStep = "emailAndPassword";
        this.dashboardType = getDashboardByPathName(window.location.pathname);
        this.language = this.dashboardType === dashboardTypes.ORGANIZATION ? languages.ENGLISH : languages.HEBREW;
        this.router = router;
    }

    resetPassword = async () => {
        this.setErrorText(null);
        if (!this.email) {
            this.setErrorText(t("screenLogin.editPassword.errorText"));
            return;
        }

        this.disableResetPassword();
        const { errorCode } = await resetPasswordSendEmail({ email: this.email });
        if (errorCode) {
            this.setErrorText(getErrorTextByErrorCode(errorCode));
        } else {
            this.isShowSuccessResetPasswordPopUp = true;
        }
    };

    setEmail(email) {
        this.email = email;
    }

    setPassword(password) {
        this.password = password;
    }
    setIsLoading(isLoading) {
        this.isLoading = isLoading;
    }

    setErrorText(errorText) {
        this.setIsLoading(false);
        this.errorText = errorText;
    }

    disableResetPassword() {
        this.disableResetButton = true;
        setTimeout(() => {
            this.disableResetButton = false;
        }, 10000);
    }

    onVerifyOtpForLogin = async () => {
        await onVerifyOtpForLogin({ store: this });
    };

    onSubmitEmailAndPassword = async () => {
        await onSubmitEmailAndPassword({ store: this });
    };

    onGoBack = () => {
        onGoBack({ store: this });
    };

    onSegmentSelected = () => {
        if (!this.selectedSegment) {
            this.setErrorText(t("screenLogin.chooseSegment.errorText"));
            return;
        }
        this.rootStore.userInfoStore.setCurrentSegment(this.selectedSegment, this.router.history);
    };
}

export function createScreenLoginStore(rootStore, router) {
    const store = new ScreenLoginStore(rootStore, router);
    return store;
}
