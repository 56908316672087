import React from "react";
import { observer } from "mobx-react";
import { t } from "common/localization/translate";
import { useScreenEditUserInfoStore } from "../../store/index";
import { TextField } from "@mui/material";
import Grid from "@mui/material/Unstable_Grid2";
import { makeStyles } from "@mui/styles";

export const OtpInput = observer(() => {
    const store = useScreenEditUserInfoStore();
    const classes = useStyles();

    const { isShouldConfirmWithOtp, handleOtpChange, isOtpInputVisible, isOtpInputDisabled } = store;

    if (!isShouldConfirmWithOtp) {
        return null;
    }

    const handleOtpCodeChange = event => {
        const { value } = event.target;
        handleOtpChange(value);
    };

    if (!isOtpInputVisible) {
        return <Grid item sm={11} className={classes.emptySpace}></Grid>;
    }

    return (
        <Grid item md={11} className={classes.container} style={{ padding: "0 16px" }}>
            <TextField label={t("screenEditUserInfo.otpCode.label")} name="phone" onChange={handleOtpCodeChange} required value={store.otpCode} inputProps={{ maxLength: 4, textAlign: "left" }} margin="normal" autoFocus={true} disabled={isOtpInputDisabled} />
        </Grid>
    );
});

const useStyles = makeStyles(() => ({
    container: { textAlign: "end", margin: "-25px 0 -16px 0" },
    emptySpace: { height: "74" },
}));
