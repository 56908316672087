import React from "react";
import { observer } from "mobx-react";
import { useScreenSwitchBusinessStore } from "screens/business/switchBusiness/store";
import { t } from "common/localization/translate";
import TextField from "@mui/material/TextField";
import Autocomplete from "@mui/lab/Autocomplete";
import { makeStyles } from "@mui/styles/";

const useStyles = makeStyles(() => ({
    inputRoot: {
        width: 310,
    },
}));

export const BusinessIdSelector = observer(() => {
    const store = useScreenSwitchBusinessStore();
    const { businessList } = store;
    const classes = useStyles();

    const handleChange = (event, value) => {
        if (!value) {
            store.businessIdEmptyError = t("switchBusiness.BusinessIdSelect");
            return;
        }
        store.businessIdEmptyError = null;
        store.businessId = Number(value.business_id);
    };

    return (
        <div>
            <Autocomplete options={businessList} getOptionLabel={business => `${business.title} - ${business.business_id}`} onChange={handleChange} classes={classes} renderInput={params => <TextField {...params} label={t("switchBusiness.BusinessIdSelect")} variant="outlined" />} />
        </div>
    );
});
