import { Card, CardContent } from "@mui/material";
import { ChooseSegmentCard } from "screens/organization/login/components/chooseSegmentCard";
import { makeStyles } from "@mui/styles";
import { observer } from "mobx-react";
import { useScreenLoginStore } from "screens/loginV2/store";
import { dashboardTypes } from "screens/loginV2/store/logic/dashboardTypes";

const useStyles = makeStyles(theme => ({
    card: {
        width: 400,
        maxWidth: "100%",
        overflow: "unset",
        display: "flex",
        position: "relative",
        "& > *": {
            flexGrow: 1,
            flexBasis: "50%",
            width: "50%",
        },
    },
    content: {
        padding: theme.spacing(8, 4, 3, 4),
    },
}));

export const ChooseSegment = observer(() => {
    const classes = useStyles();
    const store = useScreenLoginStore();

    const onClick = () => {
        store.onSegmentSelected();
    };

    const onSegmentChanged = segment => {
        store.selectedSegment = segment;
    };

    if (store.dashboardType === dashboardTypes.SUPPLIERS) {
        store.router.history.push("/suppliers/home");
        return null;
    }

    if (store.allowedSegments.length === 1) {
        store.selectedSegment = store.allowedSegments[0];
        store.onSegmentSelected();
        return null;
    }

    return (
        <Card className={classes.card}>
            <CardContent className={classes.content}>
                <ChooseSegmentCard onSegmentChanged={onSegmentChanged} onClick={onClick} allowedSegments={store.allowedSegments} errorText={store.errorText} selectedSegment={store.selectedSegment} />
            </CardContent>
        </Card>
    );
});
