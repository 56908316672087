import { dashboardPaths } from "./dashboardPaths";
import { dashboardTypes } from "./dashboardTypes";

export function getDashboardByPathName(pathname) {
    if (pathname.includes("/" + dashboardPaths.BUSINESS + "/")) {
        return dashboardTypes.BUSINESS;
    }
    if (pathname.includes("/" + dashboardPaths.ORGANIZATION + "/")) {
        return dashboardTypes.ORGANIZATION;
    }
    if (pathname.includes("/" + dashboardPaths.SUPPORT + "/")) {
        return dashboardTypes.SUPPORT;
    }
    if (pathname.includes("/" + dashboardPaths.OPERATOR + "/")) {
        return dashboardTypes.OPERATOR;
    }
    if (pathname.includes("/" + dashboardPaths.CONTROL_PANEL + "/")) {
        return dashboardTypes.CONTROL_PANEL;
    }
    if (pathname.includes("/" + dashboardPaths.SUPPLIERS + "/")) {
        return dashboardTypes.SUPPLIERS;
    }
}
