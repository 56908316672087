import React from "react";
import { makeStyles } from "@mui/styles";
import { Avatar, Card, CardContent, CardHeader, Divider, List } from "@mui/material";
import GroupAddIcon from "@mui/icons-material/GroupAdd";
import { t } from "common/localization/translate";
import SingleRow from "./components/singleRow";
import { useScreenHomeStore } from "../../store";
import { observer } from "mobx-react";
import { formatNumber } from "common/formatNumber";

const useStyles = makeStyles(theme => ({
    root: {
        display: "flex",
        flexDirection: "column",
        marginTop: theme.spacing(1),
        height: 500,
    },
    content: {
        flexGrow: 1,
        padding: 0,
        flex: 1,
    },
    list: {
        flex: 1,
    },
    avatar: {
        backgroundColor: theme.palette.primary.main,
        height: 48,
        width: 48,
        marginLeft: theme.spacing(7),
    },
}));

const TopRefferalsTable = observer(props => {
    const classes = useStyles();
    const { referralLeaders } = useScreenHomeStore();

    const rows = referralLeaders
        ? referralLeaders.map((item, index) => {
              const place = index + 1;
              const countToDisplay = formatNumber(item.count);
              return <SingleRow name={item.name} place={place} count={countToDisplay} isWithDivider={index < referralLeaders.length} key={item.giftCodeId} />;
          })
        : null;

    if (!rows) {
        return null;
    }

    return (
        <Card className={classes.root}>
            <CardHeader
                title={t("screenHome.topReferrals.tableTitle")}
                subheader={t("screenHome.topReferrals.tableSubTitle")}
                action={
                    <Avatar className={classes.avatar} size="small">
                        <GroupAddIcon />
                    </Avatar>
                }
            />
            <Divider />
            <CardContent className={classes.content}>
                <List className={classes.list}>{rows}</List>
            </CardContent>
            <Divider />
        </Card>
    );
});

export default TopRefferalsTable;
