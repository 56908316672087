import Excel from "exceljs/dist/exceljs";
import { createSheetSum } from "./createSheetSum";
import { createSheetAppOpenRate } from "./createSheetAppOpenRate";
import { createSheetBonuses } from "./createSheetBonuses";
import { createSheetNewUserRate } from "./createSheetNewUserRate";
import { createSheetReferal } from "./createSheetReferal";
import { setSheetProps } from "./setSheetProps";
import { downloadWorksheet } from "./downloadWorksheet";
import { createSheetActiveUsers } from "./createSheetActiveUsers";
import { t } from "common/localization/translate";

export async function getHomeExcel(storeHome) {
    const { activeUsersCountGraphData, arrayOfMeausermentsForChart } = storeHome;

    const workbook = new Excel.Workbook();

    const sheetSum = workbook.addWorksheet(t("excel.sheet.summaries"));
    const sheetAppOpenRate = workbook.addWorksheet(t("excel.sheet.openingRate"));
    const sheetBonuses = arrayOfMeausermentsForChart ? workbook.addWorksheet(t("screenHome.meausermentsTotal.title")) : workbook.addWorksheet(t("excel.sheet.bonuses"));
    const sheetNewUserRate = workbook.addWorksheet(t("excel.sheet.joiningMembersRate"));
    const sheetReferal = workbook.addWorksheet(t("excel.sheet.referral"));

    setSheetProps([sheetSum, sheetAppOpenRate, sheetBonuses, sheetNewUserRate, sheetReferal]);
    createSheetSum(sheetSum, storeHome);
    createSheetAppOpenRate(sheetAppOpenRate, storeHome);
    createSheetBonuses(sheetBonuses, storeHome);
    createSheetNewUserRate(sheetNewUserRate, storeHome);
    createSheetReferal(sheetReferal, storeHome);

    if (activeUsersCountGraphData) {
        const sheetActiveUsersRate = workbook.addWorksheet(t("excel.sheet.activeUsers"));
        setSheetProps([sheetActiveUsersRate]);
        createSheetActiveUsers(sheetActiveUsersRate, storeHome);
    }

    downloadWorksheet(workbook);
}
