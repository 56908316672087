import { Typography } from "@mui/material";
import { makeStyles } from "@mui/styles";
import { getAddStepsErrorTextByErrorCode } from "../../../store/logic/getAddStepsErrorTextByErrorCode";

const useStyles = makeStyles(() => ({
    errorText: {
        color: "red",
    },
}));

export function ErrorStepsInput({ errorCode, daysLimit }) {
    const classes = useStyles();
    const errorText = getAddStepsErrorTextByErrorCode(errorCode, daysLimit);
    return <Typography className={classes.errorText}>{errorText}</Typography>;
}
