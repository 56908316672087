import React from "react";
import { observer } from "mobx-react";
import { Typography } from "@mui/material";
import Grid from "@mui/material/Unstable_Grid2";
import { makeStyles } from "@mui/styles";
import { t } from "common/localization/translate";
import { InfoText } from "../infoText";
import { StatusText } from "../statusText";
import { useScreenEntitlementStatusStore } from "../../store";

export const UnderageInfo = observer(() => {
    const classes = useStyles();
    const store = useScreenEntitlementStatusStore();

    if (!store.isUnderage) {
        return null;
    }

    return (
        <Grid>
            <Typography className={classes.title}>{t("screenEntitlementData.underageStatus")}</Typography>
            <StatusText descriptionText={t("screenEntitlementData.underageRequestStatus")} isPositive={store.requestStatus} statusText={store.requestStatus ? t("screenEntitlementData.underageRequestStatus.approved") : t("screenEntitlementData.underageRequestStatus.denied")} />
            {/* <InfoText description={t('screenEntitlementData.underageRequestApprovalDate')} value={store.requestApprovedAt} /> */}
            <InfoText description={t("screenEntitlementData.underageRequestStatusDate")} value={store.requestSentAt} />
        </Grid>
    );
});

const useStyles = makeStyles(() => ({
    title: {
        fontSize: "14px",
        display: "inline-block",
        paddingLeft: 5,
        fontWeight: 900,
        textDecoration: "underline",
    },
}));
