export function setLocalData(key, data) {
    const json = JSON.stringify(data);
    localStorage.setItem(key, json);
}

export function clearLocalData(key) {
    localStorage.removeItem(key);
}

export function clearAllLocalData() {
    localStorage.clear();
}

export function getLocalData(key) {
    const json = localStorage.getItem(key);
    return JSON.parse(json || "{}");
}

export function createLocalDataObject(segmentId, businessId) {
    return { segmentId, businessId };
}

export function removeTokenFromLocalStorage() {
    for (let i = 0; i < localStorage.length; i++) {
        const key = localStorage.key(i);
        try {
            const value = JSON.parse(localStorage.getItem(key));

            if (value && value.token) {
                delete value.token;
                localStorage.setItem(key, JSON.stringify(value));
            }
        } catch {}
    }
}
