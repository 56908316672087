import HomeIcon from "@mui/icons-material/Home";
import { t } from "common/localization/translate";
import { dashboardPaths } from "screens/loginV2/store/logic/dashboardPaths";
import TelegramIcon from "@mui/icons-material/Telegram";
import AutorenewIcon from "@mui/icons-material/Autorenew";

export default [
    {
        title: "תפריט",
        pages: [
            {
                title: t("business.sideBar.switchBusiness.title"),
                href: `/${dashboardPaths.BUSINESS}/switchBusiness`,
                icon: AutorenewIcon,
            },
            {
                title: t("sideBar.home.title"),
                href: `/${dashboardPaths.BUSINESS}/home`,
                icon: HomeIcon,
            },
            {
                title: t("business.sideBar.pricing.title"),
                href: `/${dashboardPaths.BUSINESS}/pricing`,
                icon: TelegramIcon,
            },
        ],
    },
];
