import React from "react";
import { makeStyles } from "@mui/styles";
import Typography from "@mui/material/Typography";
import { observer } from "mobx-react";
import Grid from "@mui/material/Unstable_Grid2";
import TextareaAutosize from "@mui/material/TextareaAutosize";
import { useScreenSendNotificationStore } from "../store/index";
import { t } from "common/localization/translate";
import { NotificationTextInputError } from "./notificationTextInputError";

const useStyles = makeStyles(() => ({
    title: {
        fontSize: 15,
        fontWeight: "bold",
    },
    textArea: {
        width: "60%",
    },
}));

export const NotificationText = observer(() => {
    const classes = useStyles();
    const store = useScreenSendNotificationStore();

    const handleText = e => {
        store.text = e.target.value;
        store.isEmptyNotificationText = false;
        store.isSentSelfNotification = false;
    };

    return (
        <Grid>
            <Typography className={classes.title} gutterBottom>
                {t("general.text")}
            </Typography>
            <TextareaAutosize className={classes.textArea} onChange={handleText} rowsMax={6} cols="40" rows="5" placeholder={t("general.addText")} />
            <NotificationTextInputError />
        </Grid>
    );
});
