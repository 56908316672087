import React from "react";
import { observer } from "mobx-react";
import { t } from "common/localization/translate";
import MUIDataTable from "mui-datatables";
import { useScreenAddStepsStore } from "../store";
import { AddStepsContainer } from "./addStepsContainer";
import Grid from "@mui/material/Unstable_Grid2";
import { getTableData } from "./getTableData";
import { Skeleton } from "@mui/material";

export const AddStepsTable = observer(() => {
    const store = useScreenAddStepsStore();
    const { userStepsTableData, errorCode, userName, isAddingSteps, selectedDaysPeriodLength } = store;
    const { options, columns } = getTableData({ isAddingSteps, selectedDaysPeriodLength });

    if (errorCode || (userStepsTableData.length === 0 && !userName)) {
        return null;
    }

    const placeholderRow = columns.map(() => <Skeleton variant="text" width="80%" />);
    const placeholderRows = Array.from({ length: selectedDaysPeriodLength }, () => placeholderRow);

    const tableData = isAddingSteps ? [...placeholderRows, ...userStepsTableData] : userStepsTableData;

    return (
        <Grid>
            <br />
            <AddStepsContainer />
            <br />
            <br />
            <MUIDataTable title={t("screenDailyStepsAndGoals.table.title", { userName })} data={tableData} columns={columns} options={options} />
        </Grid>
    );
});
