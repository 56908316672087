import React from "react";
import { observer } from "mobx-react";
import { ChangePeriodDropDown } from "./changePeriodDropDown";
import { DatePicker } from "./datePicker";
import Grid from "@mui/material/Unstable_Grid2";

export const PeriodButtons = observer(() => {
    return (
        <Grid justifyContent="flex-end" container spacing={2}>
            <Grid item>
                <DatePicker />
            </Grid>
            <Grid item>
                <ChangePeriodDropDown />
            </Grid>
        </Grid>
    );
});
