import React from "react";
import { observer } from "mobx-react";
import { makeStyles } from "@mui/styles";
import { useScreenEditUserInfoStore } from "../../../store";

const useStyles = makeStyles(theme => ({
    successText: {
        position: "absolute",
        marginTop: "60px",
        fontSize: "17px",
        fontFamily: "sans-serif",
        color: "black",
    },
}));

export const SuccessText = observer(props => {
    const store = useScreenEditUserInfoStore();
    const classes = useStyles();

    return <p className={classes.successText}>{store.successText}</p>;
});
