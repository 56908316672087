import React, { useEffect, useState } from "react";
import { makeStyles } from "@mui/styles";
import { observer } from "mobx-react";
import { Card, CardHeader, Divider } from "@mui/material";
import { t } from "common/localization/translate";
import { dashboardPaths } from "screens/loginV2/store/logic/dashboardPaths";
import { createScreenAppOpenByHoursStore, ScreenAppOpenByHoursStoreProvider } from "./store";
import { useRootStore } from "commonStores/analytics/rootStoreContext";
import { PeriodButtons } from "./components/periodButtons";
import { AppOpenByHoursGraph } from "./components/appOpenByHoursGraph";
import { Redirect } from "react-router-dom";
import { DownloadExcelButton } from "./components/downloadExcelButton";
import { Page } from "components";
import { Titles } from "./components/titles";

const useStyles = makeStyles(theme => ({
    root: {
        padding: theme.spacing(3),
    },
}));

export const AppOpenByHoursCountsChart = observer(() => {
    const classes = useStyles();
    const rootStore = useRootStore();
    const [store] = useState(() => createScreenAppOpenByHoursStore(rootStore));
    useEffect(() => {
        store.getScreenData(true);
    }, []);

    if (!rootStore.userInfoStore.isShowAppOpenByHours) {
        return <Redirect to={{ pathname: `/${dashboardPaths.ORGANIZATION}/home` }} />;
    }
    return (
        <ScreenAppOpenByHoursStoreProvider value={store}>
            <div>
                <Page className={classes.root} title={t("screenCouponsInInventory.table.title")}>
                    <Titles />
                    <PeriodButtons />
                    <Card>
                        <CardHeader title={t("screenHome.openAppCountsChart.title")} action={<DownloadExcelButton />} />
                        <Divider />
                        <AppOpenByHoursGraph />
                    </Card>
                </Page>
            </div>
        </ScreenAppOpenByHoursStoreProvider>
    );
});
