import find from "lodash/find";
import { getSegmentIdByPath } from "./getSegmentIdByPath";
import { languageFromServer } from "..";
import { languages } from "common/localization/languages";

export async function updateStoreByTypeOrganization(store, serverRes, pathname, history) {
    const { allowedSegments, isAllowedHideCouponTypes, isAllowedToSendNotification } = serverRes;
    store.isAllowedToSendNotification = isAllowedToSendNotification;
    store.isAllowedHideCouponTypes = isAllowedHideCouponTypes;
    store.setAllowedSegments(allowedSegments);

    const segmentIdByPath = getSegmentIdByPath(pathname);
    const currentSegmentInArray = getCurrentSegment(store.currentSegmentId, allowedSegments, segmentIdByPath);
    let currentSegmentToSet = currentSegmentInArray || store.currentSegment;
    if (!currentSegmentToSet) {
        currentSegmentToSet = allowedSegments.filter(s => s.multiSegmentOptions)[0];
    }
    store.setCurrentSegmentId(currentSegmentToSet.segmentId);
    store.setCurrentSegment(currentSegmentToSet, history);
    store.setDashboardFeatures(currentSegmentToSet.dashboardFeatures);
    const language = currentSegmentToSet.dashboardFeatures.dashboardLanguageCode || currentSegmentToSet.languageCode;
    store.setLanguageCode(language);
    languageFromServer.languageCode = language;
    const direction = [languages.SPANISH, languages.ENGLISH].includes(languageFromServer.languageCode) ? "ltr" : "rtl";
    document.body.setAttribute("dir", direction);
}

const getCurrentSegment = (segmentIdInStorage, allowedSegments, segmentIdByPath) => {
    const segmentIdToFind = segmentIdByPath || segmentIdInStorage;
    if (!segmentIdToFind) {
        return null;
    }
    if (!isMultiSegmentId(segmentIdToFind)) {
        return find(allowedSegments, item => {
            if (!isMultiSegmentId(item.segmentId)) {
                return Number(item.segmentId) === Number(segmentIdToFind);
            }
            return item.segmentId && Number(item.segmentId.mainSegmentId) === Number(segmentIdToFind);
        });
    }
    return find(allowedSegments, item => {
        return item.segmentId && Number(item.segmentId.mainSegmentId) === Number(segmentIdToFind.mainSegmentId);
    });
};

function isMultiSegmentId(segmentId) {
    return typeof segmentId === "object";
}
