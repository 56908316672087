import React from "react";
import { makeObservable, action, observable, computed } from "mobx";
import { getUserDailyStepsAndGoals } from "./server/getUserDailyStepsAndGoals";
import { getUserDailyStepsAndGoalsTableData } from "./logic/getUserDailyStepsAndGoalsTableData";
import { validatePhoneNumber } from "../../editUserInfo/store/logic/validatePhoneNumber";
import { errorCodes } from "../../services/errorCodes";
import { validateIsraeliIdStructure } from "common/validateIsraeliIdStructure/validateIsraeliIdStructure";

const ScreenDailyStepsAndGoalsStoreContext = React.createContext({});
export const ScreenDailyStepsAndGoalsProvider = ScreenDailyStepsAndGoalsStoreContext.Provider;
export const useScreenDailyStepsAndGoalsStore = () => React.useContext(ScreenDailyStepsAndGoalsStoreContext);

class ScreenDailyStepsAndGoalsStore {
    constructor(rootStore) {
        this.rootStore = rootStore;
        this.isFetching = null;
        this.userIdentifier = null;
        this.errorCode = null;
        this.userName = null;
        this.userDailyStepsAndGoals = null;
        makeObservable(this, {
            isFetching: observable,
            userIdentifier: observable,
            errorCode: observable,
            userName: observable,
            userDailyStepsAndGoals: observable,
            userDailyStepsAndGoalsTableData: computed,
            submitIdentifier: action.bound,
            getScreenData: action.bound,
        });
    }

    getScreenData = async () => {
        try {
            this.isFetching = true;
            const segmentId = this.rootStore.userInfoStore.currentSegment ? this.rootStore.userInfoStore.currentSegment.segmentId : null;

            const res = await getUserDailyStepsAndGoals(this.userIdentifier, segmentId);
            this.userDailyStepsAndGoals = res.userDailyStepsAndGoals;
            this.userName = res.userName;
            this.errorCode = res.errorCode;
            this.isFetching = false;
        } catch (e) {
            this.errorCode = errorCodes.ERROR_TYPE_USER_NOT_EXISTS;
            this.isFetching = false;
        }
    };

    submitIdentifier() {
        this.errorCode = null;
        this.userName = null;
        const isValidId = validateIsraeliIdStructure(this.userIdentifier);
        const isValidPhone = validatePhoneNumber(this.userIdentifier, true);
        if (this.rootStore.userInfoStore.isWithSearchByPhoneOption) {
            if (isValidId || isValidPhone) {
                this.getScreenData();
                return;
            }
            this.errorCode = errorCodes.ERROR_TYPE_INVALID_DATA;
            return;
        }

        if (!isValidId) {
            this.errorCode = errorCodes.ERROR_TYPE_INVALID_DATA;
            return;
        }
        if (!this.errorCode) {
            this.getScreenData();
        }
    }

    get userDailyStepsAndGoalsTableData() {
        return getUserDailyStepsAndGoalsTableData(this);
    }
}

export function createScreenDailyStepsAndGoalsStore(rootStore) {
    const store = new ScreenDailyStepsAndGoalsStore(rootStore);
    return store;
}
