import React from "react";
import { makeStyles } from "@mui/styles";
import MenuItem from "@mui/material/MenuItem";
import Typography from "@mui/material/Typography";
import Select from "@mui/material/Select";
import { observer } from "mobx-react";
import Grid from "@mui/material/Unstable_Grid2";
import { useScreenSendNotificationStore } from "../store/index";
import { getNotificationNavigationsList } from "../store/logic/notificationNavigationsList";
import { t } from "common/localization/translate";

const useStyles = makeStyles(() => ({
    title: {
        fontSize: 15,
        fontWeight: "bold",
    },
}));

export const NotificationNavigationDropDown = observer(() => {
    const classes = useStyles();
    const store = useScreenSendNotificationStore();

    const locations = getNotificationNavigationsList();
    const menuItems = locations.map(item => <MenuItem value={item.value}>{item.text}</MenuItem>);

    const handleChange = event => {
        store.navigateTo = event.target.value;
        store.isSentSelfNotification = false;
    };

    return (
        <Grid>
            <Typography className={classes.title} gutterBottom>
                {t("support.sendNotificationScreen.notificationNavigation")}
            </Typography>
            <Select value={store.navigateTo} onChange={handleChange} inputProps={{ display: "block" }} displayEmpty>
                {menuItems}
            </Select>
        </Grid>
    );
});
