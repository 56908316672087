import { t } from "common/localization/translate";
import { writeToRow } from "./writeToRow";

export function createSheetActiveUsers(sheetActiveUsersRate, storeHome) {
    const { activeUsersCountGraphData } = storeHome;

    const col1 = t("excel.sheet.date");
    const col2 = t("excel.sheet.amount");
    writeToRow(sheetActiveUsersRate, [col1, col2], 1);
    let rowNumber = 2;
    activeUsersCountGraphData.forEach(({ date, count }) => {
        writeToRow(sheetActiveUsersRate, [date, count], rowNumber);
        rowNumber++;
    });
}
