import React, { useState, useRef } from "react";
import { observer } from "mobx-react";
import { makeStyles } from "@mui/styles";
import find from "lodash/find";
import { Button, Menu, MenuItem, ListItemText } from "@mui/material";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import { useScreenHomeStore } from "../store";
import { t } from "common/localization/translate";

const useStyles = makeStyles(theme => ({
    header: {
        borderRadius: 4,
        flexBasis: 300,
        height: 36,
        padding: theme.spacing(0, 2),
        display: "flex",
        alignItems: "center",
        backgroundColor: theme.palette.primary.main,
        marginRight: 15,
        [theme.breakpoints.down("sm")]: {
            height: "100%",
            width: "100%",
        },
    },
    changeSegmentButton: {
        color: "white",
        width: "100%",
    },
    container: {
        [theme.breakpoints.down("sm")]: {
            marginBottom: theme.spacing(1),
        },
    },
}));

const getSegmentName = (id, segments) => {
    if (!id) {
        return t("screenHome.changeSegment.all");
    }
    return find(segments, item => item.id === id).name;
};

const ChangeSegmentDropDown = observer(props => {
    const store = useScreenHomeStore();
    const { userInfoStore } = store.rootStore;
    const { currentSegment: currentSegmentStore } = userInfoStore;
    const currentSegmentName = getSegmentName(store.selectedSegmentIdToShow, currentSegmentStore.multiSegmentOptions);
    const [openChangeSegment, setOpenChangeSegment] = useState(false);
    if (!currentSegmentStore || !currentSegmentStore.multiSegmentOptions) {
        return null;
    }
    const options = currentSegmentStore.multiSegmentOptions.map(item => item.name);
    options.push(t("screenHome.changeSegment.all"));
    const classes = useStyles();
    const changeSegmentRef = useRef(null);

    if (!options || options.length <= 1) {
        return null;
    }
    const optionsComponents = options.map(option => (
        <MenuItem className={classes.menuItem} key={option} onClick={() => handleChangeSegmentSelect(option)}>
            <ListItemText primary={option} />
        </MenuItem>
    ));

    const handleChangeSegmentOpen = () => {
        setOpenChangeSegment(true);
    };

    const handleChangeSegmentClose = () => {
        setOpenChangeSegment(false);
    };

    const handleChangeSegmentSelect = value => {
        const segment = find(currentSegmentStore.multiSegmentOptions, item => item.name === value);
        const selected = segment ? segment.id : null;
        store.setSelectedSegmentIdToShow(selected);
        store.getScreenData();
        setOpenChangeSegment(false);
    };

    return (
        <div className={classes.container}>
            <div className={classes.header}>
                <Button className={classes.changeSegmentButton} onClick={handleChangeSegmentOpen} ref={changeSegmentRef}>
                    {currentSegmentName}
                    <ArrowDropDownIcon />
                </Button>
            </div>
            <Menu anchorEl={changeSegmentRef.current} className={classes.menu} onClose={handleChangeSegmentClose} open={openChangeSegment}>
                {optionsComponents}
            </Menu>
        </div>
    );
});

export default ChangeSegmentDropDown;
