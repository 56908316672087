import React from "react";
import { makeStyles } from "@mui/styles";
import { observer } from "mobx-react";
import MUIDataTable from "mui-datatables";
import { useScreenMultiSegmentUsersStore } from "../store/index";
import { t } from "../../../../common/localization/translate";

const useStyles = makeStyles(theme => ({
    root: {
        padding: theme.spacing(3),
        flex: 1,
    },
    container: {
        "& > *": {
            height: "100%",
        },
    },
}));

export const MultiSegmentUsersTable = observer(() => {
    const store = useScreenMultiSegmentUsersStore();
    const classes = useStyles();
    const { multiSegmentUsersTableData, isFetching } = store;
    const { options, columns } = getTableData(isFetching);

    return <MUIDataTable className={classes.table} data={multiSegmentUsersTableData} columns={columns} options={options} />;
});

const getTableData = isFetching => {
    const columns = [
        {
            name: "multiSegmentName",
            label: t("screenMultiSegmentUsers.table.col.multiSegmentName"),
        },
        {
            name: "count",
            label: t("screenMultiSegmentUsers.table.col.count"),
        },
    ];

    const options = {
        filter: false,
        download: true,
        print: false,
        viewColumns: false,
        onDownload: (buildHead, buildBody, columns, data) => {
            return "\uFEFF" + buildHead(columns) + buildBody(data);
        },
        downloadOptions: { filename: "usersInOrganizations.csv" },
        responsive: "scrollMaxHeight",
        selectableRows: "none",
        textLabels: {
            body: {
                noMatch: isFetching ? "Loading..." : "Sorry, no matching records found",
            },
        },
    };
    return { options, columns };
};
