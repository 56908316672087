import React from "react";
import { Divider } from "@mui/material";
import Grid from "@mui/material/Unstable_Grid2";
import { makeStyles } from "@mui/styles";

const useStyles = makeStyles(() => ({
    divider: { background: "#00000025", height: 1.5 },
}));

export const InputsDivider = () => {
    const classes = useStyles();
    return (
        <Grid item xs={12}>
            <Divider className={classes.divider} />
        </Grid>
    );
};
