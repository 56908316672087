import React from "react";
import { CircularProgress, Typography, useTheme, TextField } from "@mui/material";
import Grid from "@mui/material/Unstable_Grid2";
import { observer } from "mobx-react";
import { t } from "common/localization/translate";
import { makeStyles } from "@mui/styles";
import { useScreenAddStepsStore } from "../store";
import { AddStepsImage } from "../../../../layouts/Dashboard/components/NavBar/components/addStepsImage";
import { LoadingButton } from "@mui/lab";
import { DateInformationIcon } from "./dateInformationIcon";
import { ErrorStepsInput } from "./idNumForm/components/errorStepsInput";
import { SelectDateInput } from "./selectDateInput";
import { formatNumber } from "../../../../common/formatNumber";

const useStyles = makeStyles(theme => ({
    container: {
        "& > *": {
            height: "100%",
            padding: theme.spacing(0.5),
        },
    },
    card: {
        background: "white",
        padding: theme.spacing(2),
        boxShadow: theme.shadows[3],
        borderRadius: theme.shape.borderRadius,
    },
    input: {
        width: "60px",
        textAlign: "center",
    },
    button: {
        backgroundColor: theme.palette.primary.main,
        marginRight: 10,
        marginBottom: 10,
        borderRadius: 8,
        height: "50px",
        width: "180px",
        "&:hover": {
            backgroundColor: theme.palette.primary.secondGraphColor,
        },
    },
    buttonDisabled: {
        backgroundColor: "#9396b8",
        marginRight: 10,
        marginBottom: 10,
        borderRadius: 8,
        height: "50px",
        width: "180px",
        "&:hover": {
            backgroundColor: "#9396b8",
        },
    },
    buttonText: {
        fontSize: 22,
        fontWeight: "800",
        color: theme.palette.white,
    },
    date: {
        marginTop: 2,
        fontWeight: "800",
    },
    row: {
        display: "flex",
        alignItems: "center",
        gap: theme.spacing(1),
        marginBottom: 20,
    },
    icon: {
        marginRight: 10,
        marginTop: 5,
    },
}));

export const AddStepsContainer = observer(() => {
    const classes = useStyles();
    const store = useScreenAddStepsStore();
    const { lastDateToInsertSteps, isAddingSteps, errorCodeAddSteps, daysLimit } = store;
    const inputProps = { maxLength: 7, inputMode: "numeric", pattern: "[0-9]*", style: { fontWeight: "800" }, sx: { "&::placeholder": { color: "#D9D9D9" } } };
    const theme = useTheme();

    const handleInputChange = event => {
        const rawValue = event.target.value.replace(/\D/g, "");
        store.errorCodeAddSteps = null;
        store.stepsToAdd = rawValue;
    };

    const isDisabled = !store.stepsToAdd;
    const disabledStyle = isDisabled ? classes.buttonDisabled : classes.button;

    const onClick = () => {
        if (isDisabled) {
            return;
        }
        const rawSteps = store.stepsToAdd.replace(/,/g, "");
        store.addSteps(rawSteps);
    };

    if (!lastDateToInsertSteps) {
        return (
            <Grid container className={classes.container} justifyContent="center" alignItems="center">
                <Grid item xs={12} className={classes.card}>
                    <Typography variant="subtitle1">{t("screenAddSteps.addStepsContainer.noStepsAvailable")}</Typography>
                </Grid>
            </Grid>
        );
    }

    return (
        <Grid container className={classes.container} justifyContent="center" alignItems="center">
            <Grid item xs={12} className={classes.card}>
                <div className={classes.row}>
                    <Typography variant="subtitle1">{t("screenAddSteps.addStepsContainer.date")}</Typography>
                    <Typography className={classes.date}>{lastDateToInsertSteps}</Typography>
                    <DateInformationIcon />
                    <SelectDateInput />
                </div>
                <Grid container spacing={2} alignItems="center">
                    <Grid item>
                        <AddStepsImage color={theme.palette.primary.main} className={classes.icon} />
                        <TextField value={formatNumber(store.stepsToAdd)} inputProps={inputProps} variant="standard" placeholder="000,000" className={classes.input} onChange={handleInputChange} onInput={handleInputChange} />
                    </Grid>
                </Grid>
                <Grid container justifyContent="flex-start" marginTop={2}>
                    <LoadingButton onClick={onClick} loading={isAddingSteps} loadingIndicator={<CircularProgress color="info" size={20} />} className={disabledStyle} size="small" type="submit" variant="contained">
                        <Typography className={classes.buttonText} variant="subtitle1">
                            {!isAddingSteps && t("screenAddSteps.addStepsContainer.button")}
                        </Typography>
                    </LoadingButton>
                </Grid>
                <ErrorStepsInput errorCode={errorCodeAddSteps} daysLimit={formatNumber(daysLimit)} />
            </Grid>
        </Grid>
    );
});
