import React, { useState } from "react";
import { makeStyles } from "@mui/styles";
import { observer } from "mobx-react";
import { Page } from "components";
import { useRootStore } from "commonStores/analytics/rootStoreContext";
import { t } from "common/localization/translate";
import { createScreenEntitlementStatusStore, ScreenEntitlementStatusDataProvider } from "./store";
import { segmentsPaths } from "commonStores/analytics/userInfoStore/logic/getSegmentPathById";
import { IdNumForm } from "./components/idNumForm";
import { EntitlementInfo } from "./components/entitlementInfo";
import { PageTitles } from "./components/PageTitles";

const useStyles = makeStyles(theme => ({
    root: {
        padding: theme.spacing(3),
        flex: 1,
        width: "70%",
    },
}));

export const EntitledUserData = observer(() => {
    const rootStore = useRootStore();
    const [store] = useState(() => createScreenEntitlementStatusStore(rootStore));
    const classes = useStyles();

    const { userInfoStore } = rootStore;
    const { currentSegment } = userInfoStore;
    const isClalitSegment = currentSegment && currentSegment.segmentId == segmentsPaths.CLALIT.id;
    // if (!isClalitSegment) {
    //   return null;
    // }

    return (
        <ScreenEntitlementStatusDataProvider value={store}>
            <Page className={classes.root} title={t("screenEntitlementData.entitlementSideBarTitle")}>
                <PageTitles classes={classes} />
                <IdNumForm />
                <EntitlementInfo />
            </Page>
        </ScreenEntitlementStatusDataProvider>
    );
});
