import React from "react";
import { TextField } from "@mui/material";
import { observer } from "mobx-react";
import { makeStyles } from "@mui/styles";
import { useScreenPurchaseFromSupportStore } from "../../../store";
import { t } from "../../../../../../common/localization/translate";

const useStyles = makeStyles(() => ({
    container: {
        marginRight: "16px",
    },
    input: {
        width: "95px",
        "& .MuiOutlinedInput-root": {
            borderRadius: "10px",
        },
    },
}));

export const HouseInput = observer(() => {
    const store = useScreenPurchaseFromSupportStore();
    const inputProps = { maxLength: 10 };
    const classes = useStyles();

    const handleInputChangeHouse = event => {
        store.houseError = null;
        const { value } = event.target;
        store.house = value;
    };

    const error = store.houseError;

    return (
        <div className={classes.container}>
            <TextField value={store.house} className={classes.input} id="house-input" variant="outlined" type="string" error={error} label={t("screenPurchaseFromSupport.coupons.deliveryModal.house")} helperText={error} inputProps={inputProps} onChange={handleInputChangeHouse} />
        </div>
    );
});
