import React, { useState } from "react";
import clsx from "clsx";
import { makeStyles } from "@mui/styles";
import { Button, ButtonGroup } from "@mui/material";
import Grid from "@mui/material/Unstable_Grid2";
import { MobileDatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFnsV3";
import { he } from "date-fns/locale";
import CalendarTodayIcon from "@mui/icons-material/CalendarTodayOutlined";
import { getMoment } from "../../../screens/organization/appOpenByHours/store/logic/getMoment";
import Tooltip from "@mui/material/Tooltip";
import { t } from "common/localization/translate";

const useStyles = makeStyles(theme => ({
    root: {},
    dates: {
        display: "flex",
        justifyContent: "flex-end",
        marginTop: 10,
    },
    calendarTodayIcon: {
        marginRight: theme.spacing(1),
    },
    buttonGroup: {
        [theme.breakpoints.down("sm")]: {
            width: "100%",
        },
    },
    button: {
        [theme.breakpoints.down("sm")]: {
            width: "100%",
        },
    },
    datePicker: { display: "none" },
}));

export const SingleDatePicker = props => {
    const { className, initialDate, ...rest } = props;
    const classes = useStyles();
    const moment = getMoment("he");
    const now = moment();
    const initialDateToDisplay = moment(initialDate) || now;
    const [startDate, setStartDate] = useState(initialDateToDisplay);
    const [selectEdge, setSelectEdge] = useState(null);
    const [calendarDate, setCalendarDate] = useState(now);
    const handleCalendarOpen = async () => {
        await setCalendarDate(startDate);
        setSelectEdge(true);
    };

    const handleCalendarChange = date => {
        date = moment(date);
        setCalendarDate(date);
    };

    const handleCalendarClose = () => {
        const now = moment();
        setCalendarDate(now);
        setSelectEdge(null);
    };

    const handleCalendarAccept = date => {
        date = moment(date);
        const { onChangeStartDate } = props;
        const now = moment();
        setCalendarDate(now);
        setStartDate(date);
        onChangeStartDate(date);
    };

    const open = Boolean(selectEdge);
    return (
        <div {...rest} className={clsx(classes.root, className)}>
            <Grid container justifyContent="flex-end" spacing={3}>
                <Grid className={classes.dates} item lg={6} xs={12}>
                    <ButtonGroup variant="contained" className={classes.buttonGroup}>
                        <Tooltip title={t("singelDatePicker.buttonToolTip.fromStartDate")} arrow>
                            <Button className={classes.button} onClick={() => handleCalendarOpen()}>
                                <CalendarTodayIcon className={classes.calendarTodayIcon} />
                                {startDate.format("DD/MM/YYYY")}
                            </Button>
                        </Tooltip>
                    </ButtonGroup>
                </Grid>
            </Grid>
            <LocalizationProvider dateAdapter={AdapterDateFns} libInstance={moment} adapterLocale={he}>
                <MobileDatePicker
                    maxDate={now}
                    onAccept={handleCalendarAccept}
                    onChange={handleCalendarChange}
                    onClose={handleCalendarClose}
                    disableFuture={true}
                    className={classes.datePicker}
                    open={open}
                    value={calendarDate}
                    defaultSelectedValue={calendarDate}
                    slotProps={{
                        toolbar: {
                            hidden: true,
                        },
                    }}
                />
            </LocalizationProvider>
        </div>
    );
};
