import { validateOtpCodeErrorTexts, validateOtpCodeErrorCodes } from "./validateOtpCodeError";

export const getValidateOtpCodeErrorTextByErrorText = errorCode => {
    if (!errorCode) {
        return;
    }

    switch (errorCode) {
        case validateOtpCodeErrorCodes.INVALID_CODE:
            return validateOtpCodeErrorTexts.otpCodeIsInvalid;
        case validateOtpCodeErrorCodes.RATE_LIMITER_EXCEEDED:
            return validateOtpCodeErrorTexts.otpCodeVerifyLimiterExceeded;
        default:
            return validateOtpCodeErrorTexts.otpCodeIsInvalid;
    }
};
