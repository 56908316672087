import React from "react";
import { observer } from "mobx-react";
import { CommonButton } from "common/ui/commonButton";
import { getCurrentPath } from "utils/direction/getCurrentPath";
import { useScreenSendNotificationStore } from "../store";
import { t } from "common/localization/translate";
import { makeStyles } from "@mui/styles";
import { getNotificationNavigationsList } from "../store/logic/notificationNavigationsList";

const useStyles = makeStyles(theme => ({
    button: {
        fontSize: 14,
        width: "155px",
        height: "53px",
        color: "#263238",
        marginLeft: 4,
        fontWeight: 400,
        backgroundColor: "transparent",
        textTransform: "none",
        borderRadius: "4px",
        "&:hover": {
            color: theme.palette.white,
            backgroundColor: theme.palette.primary.secondGraphColor,
        },
    },
}));
export const ChooseAllUsersButton = observer(() => {
    const store = useScreenSendNotificationStore();
    const classes = useStyles();
    const { isOrganizationPath } = getCurrentPath();
    if (!isOrganizationPath) {
        return null;
    }

    const onClick = e => {
        e.preventDefault();
        const locations = getNotificationNavigationsList();
        store.navigateTo = locations[0].value;
        store.isSendNotificationToAllUsers = true;
        store.userIdNum = "";
        store.isSentSelfNotification = false;
    };

    return (
        <CommonButton className={classes.button} onClick={onClick}>
            {t("support.sendNotificationScreen:chooseAllUsersButton")}
        </CommonButton>
    );
});
