import React from "react";
import { makeStyles } from "@mui/styles";
import { Avatar, ListItem, ListItemAvatar, ListItemText, Typography } from "@mui/material";
import { t } from "common/localization/translate";

const useStyles = makeStyles(theme => ({
    avatar: {
        fontSize: 14,
        fontWeight: theme.typography.fontWeightBold,
        color: theme.palette.white,
        backgroundColor: theme.palette.primary.dark,
    },
    listItem: {
        flex: 1,
    },
    itemText: {
        textAlign: "left",
    },
}));

const SingleRow = props => {
    const { place, isWithDivider, name, count } = props;
    const classes = useStyles();
    const referralText = count > 1 ? "screenHome.topReferrals.addedNumber" : "screenHome.topReferrals.addedNumber.singular";

    return (
        <ListItem divider={isWithDivider} key={name} className={classes.listItem}>
            <ListItemAvatar>
                <Avatar className={classes.avatar} size="small">
                    {place}
                </Avatar>
            </ListItemAvatar>
            <ListItemText className={classes.itemText} primary={name} />
            <Typography variant="subtitle2">{t(referralText, { count })}</Typography>
        </ListItem>
    );
};

export default SingleRow;
