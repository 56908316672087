export function setSheetProps({ sheets, firstColWidth, otherColsWidth, totalCols }) {
    const colWidthArrayToPass = [{ width: firstColWidth }];
    for (let i = 1; i < totalCols; i++) {
        colWidthArrayToPass.push({ width: otherColsWidth });
    }
    sheets.forEach(sheet => {
        sheet.views = [{ rightToLeft: true }];
        sheet.columns = colWidthArrayToPass;
    });
}
