import React from "react";
import { httpCall } from "common/httpCall";
import { makeObservable, action, observable } from "mobx";

const ScreenTraineesInfoStoreContext = React.createContext({});
export const ScreenTraineesInfosStoreProvider = ScreenTraineesInfoStoreContext.Provider;
export const useScreenTraineesInfoStore = () => React.useContext(ScreenTraineesInfoStoreContext);

class ScreenTraineesInfoStore {
    constructor(rootStore) {
        this.rootStore = rootStore;
        this.isFetching = null;
        this.traineesList = null;
        this.selectedSegmentIdToShow = null;
        this.isSendExcelMailPopUpVisible = null;
        this.email = null;

        makeObservable(this, {
            isFetching: observable,
            traineesList: observable,
            selectedSegmentIdToShow: observable,
            isSendExcelMailPopUpVisible: observable,
            email: observable,
            getScreenData: action.bound,
        });
    }

    getScreenData = async () => {
        try {
            this.isFetching = true;
            this.traineesList = null;
            const segmentId = this.selectedSegmentIdToShow || this.rootStore.userInfoStore.currentSegmentId;
            const { traineesList, email } = await httpCall("organization/traineesInfo/getData", { segmentId });
            this.traineesList = traineesList;
            this.email = email;
            this.isFetching = false;
        } catch (e) {
            this.isFetching = false;
        }
    };

    sendExcelMail = async () => {
        const segmentId = this.selectedSegmentIdToShow || this.rootStore.userInfoStore.currentSegmentId;
        httpCall("organization/traineesInfo/sendExcelDataByEmail", { segmentId });
        this.isSendExcelMailPopUpVisible = true;
    };

    setSelectedSegmentIdToShow(segmentId) {
        const currentSegment = this.rootStore.userInfoStore.currentSegmentId;
        currentSegment.segmentIds = segmentId ? [segmentId] : this.rootStore.userInfoStore.currentSegment.multiSegmentOptions.map(segment => segment.id);
        this.selectedSegmentIdToShow = segmentId;
    }
}

export function createScreenTraineesInfoStore(rootStore) {
    const store = new ScreenTraineesInfoStore(rootStore);
    return store;
}
