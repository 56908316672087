import React from "react";
import { makeStyles } from "@mui/styles";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import { observer } from "mobx-react";
import Grid from "@mui/material/Unstable_Grid2";
import { t } from "common/localization/translate";

const useStyles = makeStyles(theme => ({
    formControl: {
        minWidth: 120,
    },
    selectEmpty: {
        marginTop: theme.spacing(2),
    },
    label: {
        fontSize: 14,
        fontWeight: "bold",
    },
}));

export const SuppliersDropDown = observer(props => {
    const { isGetCoupons, store } = props;
    const classes = useStyles();

    const handleChange = event => {
        store.chosenSupplier = event.target.value;
        if (isGetCoupons) {
            store.getCouponsBySupplier(store.chosenSupplier);
        }
    };

    if (!store.suppliersList) {
        return null;
    }
    const menuItems = store.suppliersList.map(item => <MenuItem value={item.supplier_id}>{item.name}</MenuItem>);

    return (
        <Grid item xs={12} spacing={3}>
            <InputLabel className={classes.label}>{t("suppliers.projectManagerHome.suppliers")}</InputLabel>
            <FormControl className={classes.formControl}>
                <Select value={store.chosenSupplier} onChange={handleChange} displayEmpty className={classes.selectEmpty} variant="standard">
                    {menuItems}
                </Select>
            </FormControl>
        </Grid>
    );
});
