import React from "react";
import { makeStyles } from "@mui/styles";
import { Card, CardContent, Typography } from "@mui/material";
import LockIcon from "@mui/icons-material/Lock";
import { ButtonWithUnderline } from "./buttonWithUnderline.js";
import { ErrorText } from "./errorText";
import { Form } from "./form.js";

const useStyles = makeStyles(theme => ({
    icon: {
        backgroundColor: theme.palette.primary.main,
        color: theme.palette.white,
        borderRadius: theme.shape.borderRadius,
        padding: theme.spacing(1),
        position: "absolute",
        top: -32,
        left: theme.spacing(3),
        height: 64,
        width: 64,
        fontSize: 32,
    },
    loginForm: {
        marginTop: theme.spacing(3),
    },
    card: {
        width: 400,
        maxWidth: "100%",
        overflow: "unset",
        display: "flex",
        position: "relative",
        "& > *": {
            flexGrow: 1,
            flexBasis: "50%",
            width: "50%",
        },
    },
    content: {
        padding: theme.spacing(8, 4, 3, 4),
    },
}));

export function GenericForm(props) {
    const { title, subTitle, buttonWithUnderlineText, errorText, buttonWithUnderlineOnClick, ...rest } = props;
    const classes = useStyles();
    return (
        <Card className={classes.card}>
            <CardContent className={classes.content}>
                <LockIcon className={classes.icon} />
                <Typography gutterBottom variant="h3">
                    {title}
                </Typography>
                <Typography variant="subtitle2">{subTitle}</Typography>
                <Form className={classes.loginForm} {...rest} />
                {buttonWithUnderlineText && <ButtonWithUnderline {...props} />}
                <ErrorText errorText={errorText} />
            </CardContent>
        </Card>
    );
}
