import React from "react";
import { makeObservable, action, observable, computed } from "mobx";
import { getUserStepsAndGoals } from "./server/getUserStepsAndGoals";
import { getUserStepsTableData } from "./logic/getUserStepsTableData";
import { validatePhoneNumber } from "../../editUserInfo/store/logic/validatePhoneNumber";
import { errorCodes } from "../../services/errorCodes";
import { validateIsraeliIdStructure } from "common/validateIsraeliIdStructure/validateIsraeliIdStructure";
import { addStepsServer } from "./server/addStepsServer";
import moment from "moment-timezone";

const ScreenAddStepsStoreContext = React.createContext({});
export const ScreenAddStepsProvider = ScreenAddStepsStoreContext.Provider;
export const useScreenAddStepsStore = () => React.useContext(ScreenAddStepsStoreContext);

class ScreenAddStepsStore {
    constructor(rootStore) {
        this.rootStore = rootStore;
        this.isFetching = false;
        this.isAddingSteps = false;
        this.userIdentifier = null;
        this.errorCode = null;
        this.daysLimit = null;
        this.errorCodeAddSteps = null;
        this.stepsToAdd = "";
        this.chosenSwitchType = "day";
        this.userName = null;
        this.userDailyStepsAndGoals = null;
        this.firstDateToInsertSteps = null;
        this.lastDateToInsertSteps = null;
        this.daysBackToAddSteps = 45;
        makeObservable(this, {
            isFetching: observable,
            userIdentifier: observable,
            errorCode: observable,
            daysLimit: observable,
            errorCodeAddSteps: observable,
            stepsToAdd: observable,
            chosenSwitchType: observable,
            userName: observable,
            userDailyStepsAndGoals: observable,
            userStepsTableData: computed,
            submitIdentifier: action.bound,
            getScreenData: action.bound,
            firstDateToInsertSteps: observable,
            lastDateToInsertSteps: observable,
            isAddingSteps: observable,
            daysBackToAddSteps: observable,
        });
    }

    getScreenData = async () => {
        try {
            this.errorCodeAddSteps = null;
            this.isFetching = true;
            const segmentId = this.rootStore.userInfoStore.currentSegment?.segmentId || null;
            const res = await getUserStepsAndGoals(this.userIdentifier, segmentId);
            this.userDailyStepsAndGoals = res.userDailyStepsAndGoals;
            this.userName = res.userName;
            this.errorCode = res.errorCode;
            this.lastDateToInsertSteps = res.lastDateToInsertSteps;
            this.firstDateToInsertSteps = res.firstDateToInsertSteps;
            this.daysBackToAddSteps = res.daysBackToAddSteps;
            this.isFetching = false;
            this.isAddingSteps = false;
        } catch (e) {
            this.errorCode = errorCodes.ERROR_TYPE_USER_NOT_EXISTS;
            this.isFetching = false;
        }
    };

    submitIdentifier() {
        this.chosenSwitchType = "day";
        this.stepsToAdd = "";
        this.errorCode = null;
        this.userName = null;
        const isValidId = validateIsraeliIdStructure(this.userIdentifier);
        const isValidPhone = validatePhoneNumber(this.userIdentifier, true);
        if (this.rootStore.userInfoStore.isWithSearchByPhoneOption) {
            if (isValidId || isValidPhone) {
                this.getScreenData();
                return;
            }
            this.errorCode = errorCodes.ERROR_TYPE_INVALID_DATA;
            return;
        }

        if (!isValidId) {
            this.errorCode = errorCodes.ERROR_TYPE_INVALID_DATA;
            return;
        }
        if (!this.errorCode) {
            this.getScreenData();
        }
    }

    addSteps = async steps => {
        try {
            this.errorCodeAddSteps = null;
            this.isAddingSteps = true;
            const segmentId = this.rootStore.userInfoStore.currentSegment?.segmentId || null;
            const formattedSecondDate = this.getFormattedDate(this.firstDateToInsertSteps);
            const secondDate = !this.isPeriodDaily ? formattedSecondDate : null;
            const res = await addStepsServer({ idNum: this.userIdentifier, steps, date: this.lastDateToInsertSteps, segmentId, secondDate });
            this.errorCodeAddSteps = res.errorCode;
            if (!res.errorCode) {
                this.getScreenData();
                this.stepsToAdd = "";
            } else {
                this.daysLimit = res.daysLimit;
                this.isAddingSteps = false;
            }
        } catch (e) {
            this.isAddingSteps = false;
        }
    };

    get userStepsTableData() {
        return getUserStepsTableData(this);
    }

    get isPeriodDaily() {
        return this.chosenSwitchType === "day";
    }

    getFormattedDate(date) {
        const isAlreadyFormatted = typeof date === "string" && date.includes("/");
        const formattedDate = isAlreadyFormatted ? date : moment(date).format("DD/MM/YYYY");
        return formattedDate;
    }

    get selectedDaysPeriodLength() {
        if (!this.firstDateToInsertSteps || !this.lastDateToInsertSteps || this.isPeriodDaily) {
            return 1;
        }
        const formattedSecondDate = this.getFormattedDate(this.firstDateToInsertSteps);
        const firstDate = moment(formattedSecondDate, "DD/MM/YYYY");
        const lastDate = moment(this.lastDateToInsertSteps, "DD/MM/YYYY");
        const totalDays = firstDate.diff(lastDate, "days") + 1;
        return totalDays;
    }
}

export function createScreenAddStepsStore(rootStore) {
    const store = new ScreenAddStepsStore(rootStore);
    return store;
}
