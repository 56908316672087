export function createBloodPressureDataForSingleCop({ start, finish }) {
    const bloodPressureStart = getBloodPressureString(start);
    const bloodPressureFinish = getBloodPressureString(finish);
    return { bloodPressureStart, bloodPressureFinish };
}

const getBloodPressureString = section => {
    const { systolic, diastolic } = section;
    return systolic && diastolic ? `${systolic}/${diastolic}` : null;
};
