import React from "react";
import { Button, Typography } from "@mui/material";
import Grid from "@mui/material/Unstable_Grid2";
import { makeStyles } from "@mui/styles";
import { observer } from "mobx-react";
import { useHealthAndFitnessDataStore } from "../../../store/storeHealthAndFitnessDataStoreContext";

const sheetIcon = "https://d3mspq0y1r4vba.cloudfront.net/segment/46+-+Police/microsoft_excel_icon.png";

const useStyles = makeStyles(theme => ({
    title: {
        fontSize: "20px",
        lineHeight: "18px",
        [theme.breakpoints.down("sm")]: {
            fontSize: "22px",
        },
        display: "flex",
        alignItems: "flex-end",
    },
    row: {
        width: "100%",
        display: "flex",
        alignItems: "flex-end",
        marginTop: 10,
    },
    img: {
        alignSelf: "center",
        marginRight: 6,
    },
    export: {
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        paddingRight: theme.spacing(2),
        paddingLeft: theme.spacing(2),
    },
}));

export const ExportToExcel = observer(props => {
    const store = useHealthAndFitnessDataStore();
    const classes = useStyles();

    return (
        <Grid item xs={12} md className={classes.row}>
            <Button className={classes.export} onClick={props.onClick} disabled={store.isFetching || store.isExporting}>
                <img className={classes.img} height={26} alt="excel-icon" src={sheetIcon} />
                <Typography className={classes.title} component="h3" variant="overline" gutterBottom>
                    {props.label}
                </Typography>
            </Button>
        </Grid>
    );
});
