import moment from "moment-timezone";

export function getDisabledDates({ date, lastDateToInsertSteps }) {
    const today = moment().startOf("day");
    const minDate = moment(lastDateToInsertSteps, "DD/MM/YYYY").startOf("day");

    if (moment(date).isAfter(today, "day")) {
        return true;
    }

    if (moment(date).isBefore(minDate, "day")) {
        return true;
    }

    return false;
}
