import React from "react";
import { makeStyles } from "@mui/styles";
import CardActions from "@mui/material/CardActions";
import { CommonButton } from "common/ui/commonButton";
import HelpIcon from "@mui/icons-material/Help";
import Tooltip from "@mui/material/Tooltip";
import { observer } from "mobx-react";
import { useScreenSendNotificationStore } from "../store/index";
import { validateFieldsBeforeSendNotification } from "../store/logic/validateFieldsBeforeSendNotification";
import { t } from "common/localization/translate";

const useStyles = makeStyles(theme => ({
    toolTipIcon: {
        color: theme.palette.primary.main,
        marginRight: 10,
    },
}));

export const SendButtons = observer(() => {
    const classes = useStyles();
    const store = useScreenSendNotificationStore();

    const sendSelfNotificationToCheck = () => {
        validateFieldsBeforeSendNotification(store);
        store.sendNotificationToUser(true);
    };
    const sendNotificationToUser = () => {
        validateFieldsBeforeSendNotification(store);
        store.sendNotificationToUser();
    };

    const sendButtonText = store.isSendNotificationToAllUsers ? t("support.sendNotificationScreen.sendToAllUser") : t("support.sendNotificationScreen.sendToUser");
    const tooltipText = store.isSendNotificationToAllUsers ? t("support.sendNotificationScreen.toolTipContent:allUsers") : t("support.sendNotificationScreen.toolTipContent");
    return (
        <CardActions>
            <CommonButton onClick={sendSelfNotificationToCheck}>{t("support.sendNotificationScreen.selfSendButton")} </CommonButton>
            <CommonButton disabled={!store.isSentSelfNotification} onClick={sendNotificationToUser}>
                {sendButtonText}
            </CommonButton>
            <Tooltip title={tooltipText}>
                <HelpIcon className={classes.toolTipIcon} />
            </Tooltip>
        </CardActions>
    );
});
