import React from "react";
import { Typography } from "@mui/material";
import { tableDataOptions } from "common/ui/tableDataOptions";
import { t } from "common/localization/translate";
import { PurchaseCouponButton } from "../purchaseCouponButton";
import { coinsIconWowJunior } from "../idNumForm/userNameAndCoins";
import { CouponDescription } from "./couponDescription";

export const getTableColumns = (handleImageClick, setCurrentRow, searchText, highlightText) => [
    {
        name: "couponTitle",
        options: {
            ...tableDataOptions,
            customHeadLabelRender: () => <div>{t("screenPurchaseFromSupport.coupons.column.couponName")}</div>,
            customBodyRender: value => (
                <div
                    style={{
                        whiteSpace: "normal",
                        wordWrap: "break-word",
                        maxWidth: "200px",
                        textAlign: "right",
                    }}
                >
                    {highlightText(value, searchText)}
                </div>
            ),
        },
    },
    {
        name: "image",
        options: {
            ...tableDataOptions,
            sort: false,
            customHeadLabelRender: () => <div style={{ marginRight: 50 }}>{t("screenPurchaseFromSupport.coupons.column.image")}</div>,
            customBodyRender: (coupon, tableMeta) => {
                const rowIndex = tableMeta.rowIndex;
                return (
                    <div style={{ textAlign: "center" }}>
                        <img
                            src={coupon.imageUrl}
                            alt="Coupon"
                            style={{
                                width: "100%",
                                maxWidth: "200px",
                                height: "100px",
                                objectFit: "cover",
                                borderRadius: "15px",
                                cursor: "pointer",
                            }}
                            onClick={() => {
                                setCurrentRow(rowIndex);
                                handleImageClick(coupon);
                            }}
                        />
                    </div>
                );
            },
        },
    },
    {
        name: "coins",
        options: {
            ...tableDataOptions,
            customHeadLabelRender: () => <div style={{ marginRight: 10 }}>{t("screenPurchaseFromSupport.coupons.column.coins")}</div>,
            customBodyRender: coins => {
                return (
                    <div style={{ display: "flex", alignItems: "center" }}>
                        <img src={coinsIconWowJunior} alt="coins" style={{ height: 40, marginBottom: 5 }} />
                        <Typography>{highlightText(coins, searchText)}</Typography>
                    </div>
                );
            },
            sortCompare: order => {
                return (obj1, obj2) => {
                    const extractNumber = str => {
                        const match = str.match(/[-+]?\d+(\.\d+)?/); // sort by coins only
                        return match ? Number(match[0]) : 0;
                    };
                    const a = extractNumber(obj1.data);
                    const b = extractNumber(obj2.data);
                    if (a === b) {
                        return 0;
                    }
                    return (a < b ? -1 : 1) * (order === "asc" ? 1 : -1);
                };
            },
        },
    },
    {
        name: "description",
        options: {
            ...tableDataOptions,
            sort: false,
            customHeadLabelRender: () => <div style={{ marginRight: 20 }}>{t("screenPurchaseFromSupport.coupons.column.description")}</div>,
            customBodyRender: value => <CouponDescription description={highlightText(value, searchText)} />,
        },
    },
    {
        name: "activationType",
        options: { ...tableDataOptions, customHeadLabelRender: () => <div>{t("screenPurchaseFromSupport.coupons.column.couponType")}</div> },
        customBodyRender: value => highlightText(value, searchText),
    },
    {
        name: "duration",
        customBodyRender: value => highlightText(value, searchText),
        options: {
            ...tableDataOptions,
            customHeadLabelRender: () => <div>{t("screenPurchaseFromSupport.coupons.column.duration")}</div>,
            sortCompare: order => {
                return (obj1, obj2) => {
                    const a = parseInt(obj1.data.replace(/[^0-9.]/g, ""));
                    const b = parseInt(obj2.data.replace(/[^0-9.]/g, ""));
                    if (a === b) {
                        return 0;
                    }
                    return (a < b ? -1 : 1) * (order === "asc" ? 1 : -1);
                };
            },
        },
    },
    {
        name: "purchaseButton",
        label: " ",
        options: {
            ...tableDataOptions,
            sort: false,
            customBodyRender: value => <PurchaseCouponButton coupon={value} />,
        },
    },
];
