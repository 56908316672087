export const RUMBLE_COLORS = ["#00B398", "#17E077", "#7AE1BF", "#3ACE99", "#48EDB8", "#00FFA3", "#44E2A6", "#A4FFE1", "#BBF9DA", "#3AC996"];

export const GREY = "#696969";
export const GREEN = "#15e577";
export const BRONZE = "linear-gradient(to right bottom, #FFFFFF, #dca570)";
export const GOLD = "linear-gradient(to right bottom, #FFFFFF, #E0AA3E)";
export const SILVER = "linear-gradient(to right bottom, #FFFFFF, #D3D3D3)";
export const BLACK = "black";
export const RED = "red";
export const WHITE = "white";
