import HomeIcon from "@mui/icons-material/Home";
import TableChartIcon from "@mui/icons-material/TableChart";
import GroupIcon from "@mui/icons-material/Group";
import TimelineIcon from "@mui/icons-material/Timeline";
import PlaylistAddCheckIcon from "@mui/icons-material/PlaylistAddCheck";
import SupervisedUserCircleIcon from "@mui/icons-material/SupervisedUserCircle";
import NotificationsActiveIcon from "@mui/icons-material/NotificationsActive";
import { t } from "common/localization/translate";
import { dashboardPaths } from "screens/loginV2/store/logic/dashboardPaths";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";
import WeightsIcon from "@mui/icons-material/FitnessCenter";
import { languages } from "common/localization/languages";
import { getIsLtr } from "theme/getIsLtr";

export function navigationConfigOrganization(languageCode) {
    const isLtr = getIsLtr(languageCode);
    const language = isLtr ? languages.ENGLISH : languages.HEBREW;

    return [
        {
            title: t("sideBar.menu.title", null, language),
            pages: [
                {
                    title: t("sideBar.home.title", null, language),
                    href: `/${dashboardPaths.ORGANIZATION}/home`,
                    icon: HomeIcon,
                },
                {
                    title: t("sideBar.coupons.title", null, language),
                    href: "/dashboards",
                    icon: TableChartIcon,
                    children: [
                        {
                            title: t("screenPurchases.table.title", null, language),
                            href: `/${dashboardPaths.ORGANIZATION}/purchases`,
                        },
                        {
                            title: t("screenPurchaseUsersInfo.table.title", null, language),
                            href: `/${dashboardPaths.ORGANIZATION}/purchaseUsersInfo`,
                        },
                        {
                            title: t("screenCouponsInInventory.table.title", null, language),
                            href: `/${dashboardPaths.ORGANIZATION}/couponsInInventory`,
                        },
                    ],
                },
                {
                    title: t("sideBar.support.sendNotificationToUser", null, language),
                    href: `/${dashboardPaths.ORGANIZATION}/sendNotification`,
                    icon: NotificationsActiveIcon,
                },
                {
                    title: t("sideBar.subSegmentUsers.title", null, language),
                    href: `/${dashboardPaths.ORGANIZATION}/subSegmentUsers`,
                    icon: GroupIcon,
                },
                {
                    title: t("sideBar.userConfirmaions.title", null, language),
                    href: "/organization/confirmations",
                    icon: PlaylistAddCheckIcon,
                },
                {
                    title: t("sideBar.multiSegmentUsers.title", null, language),
                    href: `/${dashboardPaths.ORGANIZATION}/multiSegmentUsers`,
                    icon: GroupIcon,
                },
                {
                    title: t("sideBar.appOpenByHours.title", null, language),
                    href: `/${dashboardPaths.ORGANIZATION}/appOpenByHours`,
                    icon: TimelineIcon,
                },
                {
                    title: t("sideBar.healthAndFitnessData.title", null, language),
                    href: `/${dashboardPaths.ORGANIZATION}/healthAndFitnessData`,
                    icon: WeightsIcon,
                },
                {
                    title: t("sideBar.traines.title", null, language),
                    href: `/${dashboardPaths.ORGANIZATION}/traineesInfo`,
                    icon: SupervisedUserCircleIcon,
                },
                {
                    title: t("sideBar.staff.title", null, language),
                    href: `/${dashboardPaths.ORGANIZATION}/staffInfo`,
                    icon: SupervisedUserCircleIcon,
                },
                {
                    title: t("sideBar.appOpenByHours.hideCoupon", null, language),
                    href: `/${dashboardPaths.ORGANIZATION}/hideCouponTypes`,
                    icon: VisibilityOffIcon,
                },
            ],
        },
    ];
}
