import React, { useState } from "react";
import { observer } from "mobx-react";
import { DataPage } from "./dataPage";
import { FormWrapper } from "./formWrapper";
import { getErrorTextByErrorCode } from "screens/organization/healthAndFitnessData/store/logic/getErrorTextByErrorCode";
import { InputMeasurements } from "../../inputMeasurements";
import { StageWithDropdown } from "../../stageWithDropdown";
import { SubmitButtonAndErrorText } from "./submitButtonAndErrorText";
import { t } from "common/localization/translate";
import { useHealthAndFitnessDataStore } from "screens/organization/healthAndFitnessData/store/storeHealthAndFitnessDataStoreContext";

export const StartMeasurementsData = observer(() => {
    const [isShowingData, setIsShowingData] = useState(false);
    const content = isShowingData ? <InputMeasurements stage={"startMeasurements"} /> : null;
    const store = useHealthAndFitnessDataStore();
    const errorText = getErrorTextByErrorCode(store.errorCodeSaveStart);
    const isError = store.errorCodeSaveStart ? true : false;

    const handleOnClick = e => {
        e.preventDefault();
        toggleIsShowingData();
    };

    const onSubmit = e => {
        e.preventDefault();
        store.submitSectionData("start");
    };

    const toggleIsShowingData = () => {
        setIsShowingData(prevState => !prevState);
    };

    return (
        <FormWrapper onSubmit={onSubmit}>
            <DataPage>
                <StageWithDropdown isShowingData={isShowingData} title={t("screenHealthAndFitnessData.data.start")} onClick={handleOnClick} />
                {content}
                <SubmitButtonAndErrorText isVisible={isShowingData} text={t("screenHealthAndFitnessData.data.start")} isError={isError} errorText={errorText} />
            </DataPage>
        </FormWrapper>
    );
});
