import React from "react";
import { observer } from "mobx-react";
import { Typography } from "@mui/material";
import Grid from "@mui/material/Unstable_Grid2";
import { makeStyles } from "@mui/styles";

export const InfoText = observer(props => {
    const classes = useStyles();

    return (
        <Grid className={classes.container}>
            <Typography>{props.description}</Typography>
            <Typography className={classes.text}>{props.value}</Typography>
        </Grid>
    );
});

const useStyles = makeStyles(() => ({
    text: {
        fontSize: "14px",
        display: "inline-block",
        fontWeight: 900,
        paddingLeft: 5,
    },
    container: {
        paddingLeft: 5,
        display: "flex",
        flexDirection: "row",
    },
}));
