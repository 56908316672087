import React from "react";
import { Typography } from "@mui/material";
import Grid from "@mui/material/Unstable_Grid2";
import { observer } from "mobx-react";
import { t } from "common/localization/translate";
import { makeStyles } from "@mui/styles";
import { useRootStore } from "commonStores/analytics/rootStoreContext";

const useStyles = makeStyles(theme => ({
    container: {
        "& > *": {
            height: "100%",
            padding: theme.spacing(0.5),
        },
    },
}));

export const TitlesCoupons = observer(() => {
    const rootStore = useRootStore();
    const subTitle = rootStore.userInfoStore.isWithSearchByPhoneOption ? t("support.common.withPhone.subtitle") : t("support.common.subtitle");
    const classes = useStyles();
    return (
        <Grid className={classes.container}>
            <Typography component="h2" gutterBottom variant="overline">
                {t("screenCouponsUser.title")}
            </Typography>
            <Typography variant="subtitle2">{subTitle}</Typography>
        </Grid>
    );
});
