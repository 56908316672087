import React from "react";
import { observer } from "mobx-react";
import { handleInputNonNegativeValuesOnly } from "../handleInputNonNegativeValuesOnly";
import { InputForm } from "./inputForm";
import { t } from "common/localization/translate";
import { useHealthAndFitnessDataStore } from "../../../store/storeHealthAndFitnessDataStoreContext";

export const InputSquat = observer(({ stage }) => {
    const store = useHealthAndFitnessDataStore();
    const value = store[stage].squat;

    const handleChange = event => {
        const value = handleInputNonNegativeValuesOnly(event.target.value);
        store.setSquat(value, stage);
    };

    return <InputForm onHandleChange={handleChange} label={t("screenHealthAndFitnessData.squat.label")} value={value} fieldName={`${stage}Squat`} />;
});
