import React, { useState, useRef } from "react";
import { observer } from "mobx-react";
import { makeStyles } from "@mui/styles";
import { Button, Menu, MenuItem, ListItemText } from "@mui/material";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import { useScreenHomeStore } from "screens/organization/home/store";

const useStyles = makeStyles(theme => ({
    header: {
        borderRadius: 4,
        flexBasis: 300,
        height: 36,
        padding: theme.spacing(0, 2),
        display: "flex",
        alignItems: "center",
    },
    changePeriodButton: {
        color: theme.palette.primary.main,
    },
}));

const ChangePeriodDropDown = observer(props => {
    const { periodOptions, currentPeriodIndexNewUsers, setCurrentPeriodIndexNewUsers } = useScreenHomeStore();
    const options = periodOptions.map(option => option.text);
    const currentPeriodFromStore = options[currentPeriodIndexNewUsers];
    const [openChangePeriod, setOpenChangePeriod] = useState(false);
    const [currentPeriod, setCurrentPeriod] = useState(currentPeriodFromStore);
    const classes = useStyles();
    const changePeriodRef = useRef(null);

    const optionsComponents = options.map(option => (
        <MenuItem className={classes.menuItem} key={option} onClick={() => handleChangePeriodSelect(option)}>
            <ListItemText primary={option} />
        </MenuItem>
    ));

    const handleChangePeriodOpen = () => {
        setOpenChangePeriod(true);
    };

    const handleChangePeriodClose = () => {
        setOpenChangePeriod(false);
    };

    const handleChangePeriodSelect = value => {
        const index = options.indexOf(value);
        setCurrentPeriod(value);
        setCurrentPeriodIndexNewUsers(index);
        handleChangePeriodClose();
    };

    return (
        <div>
            <div className={classes.header}>
                <Button className={classes.changePeriodButton} onClick={handleChangePeriodOpen} ref={changePeriodRef}>
                    {currentPeriod}
                    <ArrowDropDownIcon />
                </Button>
            </div>
            <Menu anchorEl={changePeriodRef.current} className={classes.menu} onClose={handleChangePeriodClose} open={openChangePeriod}>
                {optionsComponents}
            </Menu>
        </div>
    );
});

export default ChangePeriodDropDown;
