import React from "react";
import HelpIcon from "@mui/icons-material/Help";
import { Button } from "@mui/material";
import { t } from "common/localization/translate";
import { makeStyles } from "@mui/styles";

const useStyles = makeStyles(() => ({
    button: {
        position: "absolute",
        paddingTop: "10px",
    },
    icon: {
        paddingRight: "3px",
    },
}));

export const SupplierSupportButton = ({ userName }) => {
    const classes = useStyles();
    const description = t("suppliers.sideBar.support:description", { userName }).replace(/(\n)/g, "%0D%0A");
    const subject = t("suppliers.sideBar.support:subject");
    const toEmail = "alon@yuvital.com";

    return (
        <Button className={classes.button} onClick={() => window.open(`mailto:${toEmail}?subject=${subject}&body=${description}`, "_blank")}>
            <HelpIcon className={classes.icon} />
            {t("suppliers.sideBar.support:title")}
        </Button>
    );
};
