//should be a string
//shouldn't contain !@#$%^&*(),.?":{}|<>_0-9
//should contain 2 or more words
//at least two words should be longer than 2chars

export function validateFullName(name) {
    if (typeof name != "string") {
        return false;
    }
    if (/[!@#$%^&*(),.?":{}|<>_0-9]/.test(name)) {
        return false;
    }
    const nameArray = name.split(" ").filter(function (word) {
        if (word != "" && word.length > 1) {
            return word;
        }
    });
    if (nameArray.length < 2) {
        return false;
    }
    return true;
}
