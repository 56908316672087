import { writeToRow } from "../../../../home/store/logic/createExcel/writeToRow";
import { t } from "common/localization/translate";
export function createSheetOpenAppByHours(sheetAppOpenByHours, storeAppOpenByHours) {
    const { appOpenByHoursCountGraphData } = storeAppOpenByHours;
    const col1 = t("screenAppOpenByHours.excelCol.Date");
    const col2 = t("screenAppOpenByHours.excelCol.count");
    writeToRow(sheetAppOpenByHours, [col1, col2], 1);
    let rowNumber = 2;
    appOpenByHoursCountGraphData.forEach(({ fullDate, count }) => {
        writeToRow(sheetAppOpenByHours, [fullDate, count], rowNumber);
        rowNumber++;
    });
}
