import React from "react";
import { makeObservable, action, observable } from "mobx";
import { validateOrderId } from "./logic/validateOrderId";
import { getOrderDetailsServer } from "./server/getOrderDetailsServer";

const ScreenOrderDetailsStoreContext = React.createContext({});
export const ScreenOrderDetailsDataProvider = ScreenOrderDetailsStoreContext.Provider;
export const useScreenOrderDetailsStore = () => React.useContext(ScreenOrderDetailsStoreContext);

class ScreenOrderDetailsStore {
    constructor(rootStore) {
        this.rootStore = rootStore;
        this.isFetching = false;
        this.userIdNum = null;
        this.userName = null;
        this.orderDetails = null;
        this.errorCode = null;
        makeObservable(this, {
            isFetching: observable,
            userIdNum: observable,
            userName: observable,
            orderDetails: observable,
            errorCode: observable,
            submitIdNum: action.bound,
            setErrorCodes: action.bound,
            setIsFetching: action.bound,
            getScreenData: action.bound,
        });
    }

    setOrderId(orderId) {
        this.orderId = orderId;
    }

    setOrderDetails(orderDetails) {
        this.orderDetails = orderDetails;
    }

    setErrorCodes(errorCode) {
        this.errorCode = errorCode;
    }

    getScreenData = async () => {
        this.setOrderDetails(null);
        this.setIsFetching(true);
        const segmentId = this.rootStore.userInfoStore.currentSegment ? this.rootStore.userInfoStore.currentSegment.segmentId : null;
        const response = await getOrderDetailsServer(this.orderId, segmentId);
        this.setErrorCodes(response.errorCode);
        this.setOrderDetails(response.extra);
        this.setIsFetching(false);
    };

    submitIdNum() {
        const errors = validateOrderId(this.orderId);
        this.setErrorCodes(errors);
        if (!errors) {
            this.getScreenData();
        }
    }

    setIsFetching(isFetching) {
        this.isFetching = isFetching;
    }
}

export function createOrderDetailsUsersStore(rootStore) {
    const store = new ScreenOrderDetailsStore(rootStore);
    return store;
}
