//email validation, string or not,
// local part: numbers, letters and the following symbols: .!#$%&'*+/=?^_`{|}~-,
// domain part: numbers/letters/hyphen/,
// top level domain part: starts with (.) followed by numbers/letters min 1 max 3 times
//email can't start or end with special symbol
//email string shouldn't have a special character followed by any other special character
export function validateEmail(email) {
    if (typeof email != "string") {
        return false;
    }

    email = email.trim();

    if (/^[a-zA-Z0-9]+[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+){1,3}$/.test(email)) {
        if (!/[.!#$%&'*+/=@?^_`{|}~-]([.!#$%&'*+/=@?^_`{|}~-])/.test(email)) {
            return true;
        }
    }
}
