import React from "react";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import DialogTitle from "@mui/material/DialogTitle";
import { makeStyles } from "@mui/styles";
import { useScreenPurchaseFromSupportStore } from "../../store/index";
import { observer } from "mobx-react-lite";
import { t } from "common/localization/translate";
import { AddressForm } from "../addressForm";
import { LoadingButton } from "@mui/lab";
import { CircularProgress, DialogContentText } from "@mui/material";
import { CommonButton } from "../../../../../common/ui/commonButton";

const useStyles = makeStyles(theme => ({
    root: {
        color: theme.palette.white,
        fontSize: 18,
        display: "flex",
        flexDirection: "row",
        backgroundColor: theme.palette.primary.main,
        "& h6": {
            color: theme.palette.white,
            fontSize: 18,
        },
    },
    row: {
        display: "flex",
        flexDirection: "row",
        alignItems: "center",
    },
    content: { marginTop: 8 },
    button: {
        fontSize: 14,
        marginLeft: 21,
        marginTop: 18,
        color: theme.palette.white,
        backgroundColor: theme.palette.primary.main,
        "&:hover": {
            color: theme.palette.white,
            backgroundColor: theme.palette.primary.secondGraphColor,
        },
        width: "150px",
    },
    buttonContainer: {
        display: "flex",
        justifyContent: "center",
        marginBottom: 50,
    },
    buttonSecondary: {
        fontSize: 14,
        marginLeft: 21,
        marginTop: 18,
        backgroundColor: theme.palette.white,
        color: theme.palette.primary.main,
        "&:hover": {
            color: theme.palette.white,
            backgroundColor: theme.palette.primary.secondGraphColor,
        },
        width: "150px",
    },
    text: {
        fontSize: 12,
        color: "black",
        textAlign: "center",
    },
}));

export const DeliveryModalContent = observer(() => {
    const store = useScreenPurchaseFromSupportStore();
    const { isPurchasing, isMinor } = store;
    const classes = useStyles();

    const onOkPress = () => {
        const isValid = store.validateDeliveryForm();
        if (isValid) {
            store.purchaseCoupon();
        }
    };

    const onClose = () => {
        store.cleanDeliveryInfo();
        store.setIsDeliveryModalVisible(false);
        store.paymentUrl = "";
        store.isWithCurrency = false;
        store.shouldShowOtp = false;
        store.otpError = false;
        store.otpApproved = false;
        store.streetAndHouseSearch = null;
        store.streetAndHouseSearch = null;
        store.selectedPickupPoint = null;
    };

    const isButtonDisabled = store.getIsButtonDisabled();
    const isWithCurrency = store.coupon.priceInCurrency;

    return (
        <div>
            {" "}
            <DialogTitle className={classes.root} id="responsive-dialog-title">
                {t("screenPurchaseFromSupport.coupons.deliveryModal.title")}
            </DialogTitle>
            <DialogContent className={classes.content}>
                <AddressForm />
                {isMinor && isWithCurrency && <DialogContentText className={classes.text}>{t("screenPurchaseFromSupport.coupons.purchaseModal.underage.sms")}</DialogContentText>}
            </DialogContent>
            <DialogActions className={classes.buttonContainer}>
                <LoadingButton loading={isPurchasing} loadingIndicator={<CircularProgress color="info" size={20} />} className={classes.button} size="small" type="submit" variant="contained" onClick={onOkPress} disabled={isButtonDisabled}>
                    {t("screenPurchaseFromSupport.coupons.deliveryModal.button")}
                </LoadingButton>
                <CommonButton className={classes.buttonSecondary} onClick={onClose} color="primary" autoFocus>
                    {t("screenPurchaseFromSupport.coupons.purchaseModal.buttons.no")}
                </CommonButton>
            </DialogActions>
        </div>
    );
});
