import React, { useState } from "react";
import { makeStyles } from "@mui/styles";
import { observer } from "mobx-react";
import { Page } from "components";
import Grid from "@mui/material/Unstable_Grid2";
import { useRootStore } from "commonStores/analytics/rootStoreContext";
import { t } from "common/localization/translate";
import { createScreenEditUserInfoStore, ScreenEditUserInfoDataProvider } from "./store";
import { UserInfoForm } from "./components/userInfoForm";
import { PageTitles } from "./components/PageTitles";
import { IdNumForm } from "./components/idNumForm";

const useStyles = makeStyles(theme => ({
    root: {
        padding: theme.spacing(3),
        flex: 1,
    },
    container: {
        "& > *": {
            height: "100%",
            padding: theme.spacing(0.5),
        },
    },
}));

export const EditUserInfo = observer(() => {
    const rootStore = useRootStore();
    const [store] = useState(() => createScreenEditUserInfoStore(rootStore));
    const classes = useStyles();

    return (
        <ScreenEditUserInfoDataProvider value={store}>
            <Page className={classes.root} title={t("screenEditUserInfo.title")}>
                <Grid className={classes.container} container item lg={12} xs={12} spacing={2}>
                    <PageTitles classes={classes} />
                    <IdNumForm />
                    <UserInfoForm />
                </Grid>
            </Page>
        </ScreenEditUserInfoDataProvider>
    );
});
