import React from "react";
import { makeObservable, action, observable, computed } from "mobx";
import { getSubmittedForHideCouponsDataServer } from "./server/getSubmittedForHideCouponsDataServer";
import { deleteCouponServer } from "./server/deleteCouponServer";
import { restoreCouponServer } from "./server/restoreCouponServer";
import { getCouponsTableData } from "./logic/getCouponsTableData";
import { errorCodes } from "../../services/errorCodes";

const ScreenConfirmHideCouponsStoreContext = React.createContext({});
export const ScreenConfirmHideCouponsDataProvider = ScreenConfirmHideCouponsStoreContext.Provider;
export const useScreenConfirmHideCouponsStore = () => React.useContext(ScreenConfirmHideCouponsStoreContext);

class ScreenConfirmHideCouponsStore {
    constructor(rootStore) {
        this.rootStore = rootStore;
        this.initProperties();
        this.isFetching = null;
        this.deleteCouponErrorCode = null;
        this.listOfCoupons = null;
        this.statusMessageHidingCoupon = null;
        this.isDeleteCouponPopUpVisible = null;
        this.isRestoreCouponPopUpVisible = null;
        this.errorCode = null;
        this.couponName = null;
        this.isSuccessCancelCoupon = null;
        makeObservable(this, {
            isFetching: observable,
            deleteCouponErrorCode: observable,
            listOfCoupons: observable,
            statusMessageHidingCoupon: observable,
            isDeleteCouponPopUpVisible: observable,
            isRestoreCouponPopUpVisible: observable,
            errorCode: observable,
            couponName: observable,
            currentRow: observable,
            isSuccessCancelCoupon: observable,
            isNowRowSelected: observable,
            couponsUserTableData: computed,
            setIsSuccessCancelCoupon: action.bound,
            setDeleteCouponErrorCode: action.bound,
            setCouponName: action.bound,
            setCurrentRow: action.bound,
            setIsOpenDeleteCouponPopUp: action.bound,
            setIsOpenRestoreCouponPopUp: action.bound,
            setStatusMessageHidingCoupon: action.bound,
            deleteCoupon: action.bound,
            setListOfCoupons: action.bound,
            setErrorCodes: action.bound,
            setIsFetching: action.bound,
            getScreenData: action.bound,
            setIsNowRowSelected: action.bound,
            initProperties: action.bound,
            restoreCoupon: action.bound,
        });
    }

    initProperties() {
        this.currentRow = null;
        this.isNowRowSelected = false;
    }

    setListOfCoupons(value) {
        this.listOfCoupons = value;
    }

    setErrorCodes(errorCode) {
        this.errorCode = errorCode;
    }

    setIsOpenDeleteCouponPopUp(isOpen) {
        this.isDeleteCouponPopUpVisible = isOpen;
    }

    setIsOpenRestoreCouponPopUp(isOpen) {
        this.isRestoreCouponPopUpVisible = isOpen;
    }

    async restoreCoupon() {
        this.setStatusMessageHidingCoupon(null);
        const { couponId, ownerId } = this.listOfCoupons[this.currentRow];
        const segmentId = this.rootStore.userInfoStore.currentSegment ? this.rootStore.userInfoStore.currentSegment.segmentId : null;
        const response = await restoreCouponServer({ ownerId, segmentId, couponId });
        if (response.isSuccess) {
            const updatedCoupons = this.listOfCoupons.filter(item => item.couponId !== couponId);
            this.setListOfCoupons(updatedCoupons);
        }
        this.setIsOpenRestoreCouponPopUp(true);
        this.setStatusMessageHidingCoupon(response);
    }

    setCurrentRow(currentRow) {
        const isDifferentRow = this.currentRow != currentRow;
        this.setIsNowRowSelected(isDifferentRow);
        this.currentRow = isDifferentRow ? currentRow : null;
    }

    setStatusMessageHidingCoupon(statusMessageHidingCoupon) {
        this.statusMessageHidingCoupon = statusMessageHidingCoupon;
    }

    setIsSuccessCancelCoupon(isSuccess) {
        this.isSuccessCancelCoupon = isSuccess;
    }

    setDeleteCouponErrorCode(errorCode) {
        this.deleteCouponErrorCode = errorCode;
    }

    async deleteCoupon() {
        this.setIsSuccessCancelCoupon(false);
        this.setDeleteCouponErrorCode(null);
        const { couponId, ownerId } = this.listOfCoupons[this.currentRow];
        const segmentId = this.rootStore.userInfoStore.currentSegment ? this.rootStore.userInfoStore.currentSegment.segmentId : null;
        const response = await deleteCouponServer({ ownerId, segmentId, couponId });
        if (response.isSuccess) {
            const updatedCoupons = this.listOfCoupons.filter(item => item.couponId !== couponId);
            this.setListOfCoupons(updatedCoupons);
        }
        this.setIsSuccessCancelCoupon(response.isSuccess);
        this.setDeleteCouponErrorCode(response.errorCode);
        this.setIsOpenDeleteCouponPopUp(false);
    }

    setCouponName() {
        const { couponName } = this.listOfCoupons[this.currentRow];
        this.couponName = couponName;
    }

    getScreenData = async () => {
        try {
            this.setListOfCoupons(null);
            this.setIsFetching(true);
            const segmentId = this.rootStore.userInfoStore.currentSegment.segmentId ? this.rootStore.userInfoStore.currentSegment.segmentId : null;
            const response = await getSubmittedForHideCouponsDataServer(segmentId);
            this.setErrorCodes(response.errorCode);
            this.setListOfCoupons(response.submittedCouponsWithDateFormat);
            this.setIsFetching(false);
        } catch (e) {
            this.setErrorCodes(errorCodes.ERROR_TYPE_USER_NOT_EXISTS);
            this.setIsFetching(false);
        }
    };

    setIsFetching(isFetching) {
        this.isFetching = isFetching;
    }

    setIsNowRowSelected(isSelected) {
        this.isNowRowSelected = isSelected;
    }

    get couponsUserTableData() {
        return getCouponsTableData(this.listOfCoupons);
    }
}

export function createScreenConfirmHideCouponsStore(rootStore) {
    const store = new ScreenConfirmHideCouponsStore(rootStore);
    return store;
}
