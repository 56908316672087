import React from "react";
import { observer } from "mobx-react";
import { handleInputNonNegativeValuesOnly } from "../handleInputNonNegativeValuesOnly";
import { InputForm } from "./inputForm";
import { t } from "common/localization/translate";
import { useHealthAndFitnessDataStore } from "../../../store/storeHealthAndFitnessDataStoreContext";

export const InputWaistCircumference = observer(({ stage }) => {
    const store = useHealthAndFitnessDataStore();
    const value = store[stage].waistCircumference;

    const handleChange = event => {
        const value = handleInputNonNegativeValuesOnly(event.target.value);
        store.setWaistCircumference(value, stage);
    };

    return <InputForm onHandleChange={handleChange} subText={t("screenHealthAndFitnessData.waistCircumference.subText")} label={t("screenHealthAndFitnessData.waistCircumference.label")} value={value} fieldName={`${stage}WaistCircumference`} />;
});
