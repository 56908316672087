import React from "react";
import { observer } from "mobx-react";
import Grid from "@mui/material/Unstable_Grid2";
import { Box, Typography, useTheme } from "@mui/material";
import { useScreenAddStepsStore } from "../../../store";
import { t } from "../../../../../../common/localization/translate";

export const SwitchPeriod = observer(() => {
    const store = useScreenAddStepsStore();
    const theme = useTheme();
    const { errorCode, userName } = store;

    if (errorCode || !userName) {
        return null;
    }

    const handleChange = newRange => {
        if (newRange !== store.chosenSwitchType) {
            store.chosenSwitchType = newRange;
        }
    };

    return (
        <Grid container justifyContent="center">
            <Box
                sx={{
                    display: "flex",
                    position: "relative",
                    width: "370px",
                    height: "45px",
                    marginBottom: "10px",
                    backgroundColor: "#E6E8E9",
                    borderRadius: "30px",
                    paddingRight: "7px",
                    alignItems: "center",
                    cursor: "pointer",
                }}
                onClick={() => handleChange(store.chosenSwitchType === "day" ? "period" : "day")}
            >
                <Box
                    sx={{
                        position: "absolute",
                        width: "50%",
                        height: "80%",
                        backgroundColor: theme.palette.primary.main,
                        borderRadius: "20px",
                        transition: "transform 0.3s",
                        transform: store.chosenSwitchType === "day" ? "translateX(0)" : "translateX(-93%)",
                    }}
                />
                <Box
                    sx={{
                        flex: 1,
                        textAlign: "center",
                        zIndex: 1,
                        cursor: "pointer",
                    }}
                >
                    <Typography
                        sx={{
                            color: store.chosenSwitchType === "day" ? "#FFFFFF" : theme.palette.primary.main,
                            fontWeight: 600,
                            marginRight: -1,
                        }}
                    >
                        {t("screenAddSteps.switchRange.buttons.daily")}
                    </Typography>
                </Box>
                <Box
                    sx={{
                        flex: 1,
                        textAlign: "center",
                        zIndex: 1,
                        cursor: "pointer",
                    }}
                >
                    <Typography
                        sx={{
                            color: store.chosenSwitchType === "day" ? theme.palette.primary.main : "#FFFFFF",
                            fontWeight: 600,
                            marginRight: -2,
                        }}
                    >
                        {t("screenAddSteps.switchRange.buttons.range")}
                    </Typography>
                </Box>
            </Box>
        </Grid>
    );
});
