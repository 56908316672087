import React, { useState, useRef } from "react";
import { observer } from "mobx-react";
import { makeStyles } from "@mui/styles";
import { Button, Menu, MenuItem, ListItemText } from "@mui/material";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import { useScreenAppOpenByHoursStore } from "../store/index";

const useStyles = makeStyles(theme => ({
    searchButton: {
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        marginBottom: 15,
        marginTop: 10,
        height: 36,
        fontWeight: "bold",
        [theme.breakpoints.down("sm")]: {
            width: "100%",
        },
    },
}));

export const ChangePeriodDropDown = observer(props => {
    const store = useScreenAppOpenByHoursStore();
    const { periodOptions, currentPeriodIndexAppOpen, setCurrentPeriodIndexAppOpen } = store;
    const menuOptions = periodOptions.map(option => option.text);
    const [openChangePeriod, setOpenChangePeriod] = useState(false);
    const [currentPeriod, setCurrentPeriod] = useState(menuOptions[currentPeriodIndexAppOpen]);
    const classes = useStyles();
    const changePeriodRef = useRef(null);

    const optionsComponents = menuOptions.map(option => (
        <MenuItem className={classes.button} key={option} onClick={() => handleChangePeriodSelect(option)}>
            <ListItemText primary={option} />
        </MenuItem>
    ));

    const handleChangePeriodOpen = () => {
        setOpenChangePeriod(true);
    };

    const handleChangePeriodClose = () => {
        setOpenChangePeriod(false);
    };

    const handleChangePeriodSelect = value => {
        const index = menuOptions.indexOf(value);
        setCurrentPeriod(value);
        setCurrentPeriodIndexAppOpen(index);
        handleChangePeriodClose();
    };

    return (
        <div>
            <Button className={classes.searchButton} onClick={handleChangePeriodOpen} ref={changePeriodRef} size="small" variant="contained">
                {currentPeriod}
                <ArrowDropDownIcon />
            </Button>

            <Menu anchorEl={changePeriodRef.current} className={classes.menu} onClose={handleChangePeriodClose} open={openChangePeriod}>
                {optionsComponents}
            </Menu>
        </div>
    );
});
