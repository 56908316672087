import React from "react";
import { makeStyles } from "@mui/styles";
import { observer } from "mobx-react";
import { Typography, Button } from "@mui/material";
import Grid from "@mui/material/Unstable_Grid2";
import { t } from "common/localization/translate";
import ChangeSegmentDropDown from "./changeSegmentDropDown";
import { useHealthAndFitnessDataStore } from "../store/storeHealthAndFitnessDataStoreContext";

const useStyles = makeStyles(theme => ({
    root: {
        padding: theme.spacing(3),
        flex: 1,
    },
    container: {
        "& > *": {
            height: "100%",
        },
    },
    toolTipIcon: {
        color: theme.palette.primary.main,
        marginLeft: 15,
        fontSize: "25px",
    },
    title: {
        fontSize: "25px",
        lineHeight: "18px",
        marginTop: 2,
    },
    rightSide: {
        flexDirection: "row",
        display: "flex",
        justifyContent: "flex-start",
        width: "100%",
    },
    leftSide: {
        flexDirection: "row",
        display: "flex",
        justifyContent: "flex-end",
        width: "100%",
        [theme.breakpoints.down("sm")]: {
            flexDirection: "column",
        },
    },
}));

export const Header = observer(() => {
    const store = useHealthAndFitnessDataStore();
    const classes = useStyles();
    return (
        <Grid container justifyContent="space-between" spacing={3}>
            <Grid item md={6} xs={12} className={classes.rightSide}>
                <Typography className={classes.title} component="h2" gutterBottom variant="overline">
                    {t("screenHealthAndFitnessData.data.title")}
                </Typography>
            </Grid>
            <Grid item md={6} xs={12} className={classes.leftSide}>
                <ChangeSegmentDropDown />
                <Button className={classes.searchButton} onClick={store.getAllDataAndExportToExcel} variant="contained">
                    {t("screenCouponsInInventory.downloadExcel")}
                </Button>
            </Grid>
        </Grid>
    );
});
