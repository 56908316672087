import React from "react";
import { makeStyles } from "@mui/styles";
import { observer } from "mobx-react";
import Grid from "@mui/material/Unstable_Grid2";
import TextField from "@mui/material/TextField";
import { useScreenSendNotificationStore } from "../store/index";
import { LinkInputError } from "./linkInputError";

const useStyles = makeStyles(() => ({
    container: {
        width: "60%",
    },
}));

export const ExternalLinkInput = observer(() => {
    const classes = useStyles();
    const store = useScreenSendNotificationStore();
    const onLinkChange = e => {
        store.link = e.target.value;
        store.isEmptyLink = false;
        store.isSentSelfNotification = false;
    };
    if (store.navigateTo != "OPEN_URL") {
        return null;
    }
    return (
        <Grid>
            <TextField className={classes.container} onChange={onLinkChange} placeholder="הזן לינק" />
            <LinkInputError />
        </Grid>
    );
});
