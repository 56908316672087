import React from "react";
import Grid from "@mui/material/Unstable_Grid2";
import { makeStyles } from "@mui/styles";
import { observer } from "mobx-react";
import { BloodPressureSlash } from "./bloodPressureSlash";
import { BloodPressureDiastolic } from "./bloodPressureDiastolic";
import { BloodPressureSystolic } from "./bloodPressureSystolic";

const useStyles = makeStyles(() => ({
    row: { flexDirection: "row", display: "flex" },
}));

export const InputBloodPressure = observer(({ stage }) => {
    const classes = useStyles();
    return (
        <Grid item md={6} xs={12} className={classes.row}>
            <BloodPressureDiastolic stage={stage} />
            <BloodPressureSlash />
            <BloodPressureSystolic stage={stage} />
        </Grid>
    );
});
