import React from "react";
import { TextField } from "@mui/material";
import Grid from "@mui/material/Unstable_Grid2";
import { observer } from "mobx-react";
import { t } from "common/localization/translate";
import { makeStyles } from "@mui/styles";
import { useScreenEditUserInfoStore } from "screens/support/editUserInfo/store";
import { GetErrorTextByErrorCode } from "screens/support/editUserInfo/store/logic/getErrorTextByErrorCode";
import { useRootStore } from "commonStores/analytics/rootStoreContext";

const useStyles = makeStyles(() => ({
    input: {
        width: "155px",
    },
}));

export const IdNumInput = observer(() => {
    const rootStore = useRootStore();
    const store = useScreenEditUserInfoStore();
    const placeHolder = rootStore.userInfoStore.isWithSearchByPhoneOption ? t("support.common.withPhone.placeHolder") : t("support.common.placeHolder");
    const inputProps = { maxLength: 10 };
    const classes = useStyles();

    const handleInputChange = event => {
        const { value } = event.target;
        store.setUserIdentifier(value);
    };
    const errorText = !store.userId && GetErrorTextByErrorCode(store.errorCode);
    const isError = store.errorCode && !store.userId ? true : false;

    return (
        <Grid item sm={4} lg={2} spacing={2}>
            <TextField className={classes.input} id="standard-basic" variant="standard" type="string" placeholder={placeHolder} error={isError} helperText={errorText} inputProps={inputProps} onChange={handleInputChange} />
        </Grid>
    );
});
