import { Typography } from "@mui/material";
import Grid from "@mui/material/Unstable_Grid2";
import { makeStyles } from "@mui/styles";

export function ButtonWithUnderline({ buttonWithUnderlineText, buttonWithUnderlineOnClick, disableResetButton }) {
    const useStyles = makeStyles(theme => ({
        submitButton: {
            marginTop: theme.spacing(2),
            textDecorationLine: "underline",
            "&:hover": {
                cursor: "pointer",
                fontWeight: "bold",
            },
        },
        disable: {
            textDecoration: "none",
            opacity: 0.5,
            "&:hover": {
                cursor: "default",
                fontWeight: "normal",
            },
        },
    }));
    const classes = useStyles();

    const resetPasswordSendEmail = event => {
        event.preventDefault();
        if (disableResetButton) {
            return;
        }
        buttonWithUnderlineOnClick();
    };

    return (
        <Grid className={[classes.submitButton, disableResetButton ? classes.disable : null]} onClick={resetPasswordSendEmail} type="submit">
            <Typography variant="subtitle2">{buttonWithUnderlineText}</Typography>
        </Grid>
    );
}
