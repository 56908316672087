import React from "react";
import clsx from "clsx";
import { Doughnut } from "react-chartjs-2";
import { makeStyles, useTheme } from "@mui/styles";
import { formatNumber } from "common/formatNumber";

const useStyles = makeStyles(() => ({
    root: {
        position: "relative",
    },
}));

const PieChart = props => {
    const { data: dataProp, className, ...rest } = props;

    const classes = useStyles();
    const theme = useTheme();

    const data = {
        datasets: [
            {
                data: [],
                backgroundColor: [],
                hoverOffset: 4,
                borderWidth: 8,
                borderColor: theme.palette.white,
                hoverBorderColor: theme.palette.white,
            },
        ],
        labels: [],
    };

    for (const item of dataProp) {
        data.datasets[0].data.push(item.value);
        data.datasets[0].backgroundColor.push(item.color);
        data.labels.push(item.label);
    }

    const options = {
        responsive: true,
        maintainAspectRatio: false,
        animation: {
            easing: "linear",
        },
        cutout: "80%",
        legend: {
            display: false,
        },
        layout: {
            padding: 0,
        },
        interaction: {
            mode: "index",
        },
        plugins: {
            tooltip: {
                enabled: true,
                mode: "index",
                intersect: false,
                caretSize: 10,
                yPadding: 20,
                xPadding: props.smallToolTip ? 2 : 20,
                borderWidth: 1,
                bodyFontSize: props.smallToolTip ? 9 : 13,
                borderColor: theme.palette.divider,
                backgroundColor: theme.palette.white,
                titleFontColor: theme.palette.text.primary,
                bodyFontColor: theme.palette.text.secondary,
                footerFontColor: theme.palette.text.secondary,
                callbacks: {
                    labelTextColor: () => theme.palette.text.secondary,
                    bodyFontColor: () => theme.palette.text.secondary,
                    label: function (tooltipItem) {
                        return `${tooltipItem.label}: ${formatNumber(tooltipItem.raw)}`;
                    },
                },
            },
        },
    };

    return (
        <div {...rest} className={clsx(classes.root, className)}>
            <Doughnut data={data} options={options} />
        </div>
    );
};

export default PieChart;
