import React from "react";
import { makeObservable, action, observable } from "mobx";
import { getBusinesses } from "./server/getBusinesses";
import { switchBusiness } from "./server/switchBusiness";
import { t } from "common/localization/translate";

const ScreenSwitchBusinessStoreContext = React.createContext({});
export const ScreenSwitchBusinessStoreProvider = ScreenSwitchBusinessStoreContext.Provider;
export const useScreenSwitchBusinessStore = () => React.useContext(ScreenSwitchBusinessStoreContext);

class ScreenSwitchBusinessStore {
    constructor(rootStore) {
        this.rootStore = rootStore;
        this.initProperties();
        makeObservable(this, {
            isFetching: observable,
            businessName: observable,
            errorText: observable,
            isFetchingBusinesses: observable,
            businessList: observable,
            businessId: observable,
            businessIdEmptyError: observable,
            switchingStatus: observable,
            errorCode: observable,
            isEmptyBusinessIdErrorPopUpOpen: observable,
            isFailedToChangeBusinessErrorPopUpOpen: observable,
            initProperties: action.bound,
        });
    }

    initProperties() {
        this.isFetchingBusinesses = true;
        this.isFetching = false;
        this.businessName = null;
        this.errorText = null;
        this.businessList = [];
        this.businessId = null;
        this.switchingStatus = null;
        this.errorCode = null;
        this.businessIdEmptyError = t("switchBusiness.BusinessIdSelect");
        this.isEmptyBusinessIdErrorPopUpOpen = false;
        this.isFailedToChangeBusinessErrorPopUpOpen = false;
        this.getBusinessesList();
    }

    async getBusinessesList() {
        const businesses = await getBusinesses();
        this.businessList = businesses;
        this.isFetchingBusinesses = false;
    }

    async switchToChosenBusiness() {
        this.isFetching = true;
        const res = await switchBusiness({ businessId: this.businessId });
        this.isFetching = false;
        this.switchingStatus = res.isSuccess;
        this.errorCode = res.errorCode;
    }
}

export function createScreenSwitchBusinessStore(rootStore) {
    const store = new ScreenSwitchBusinessStore(rootStore);
    return store;
}
