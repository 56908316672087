import React from "react";
import { observer } from "mobx-react";
import { makeStyles } from "@mui/styles";
import { Typography } from "@mui/material";
import { useRootStore } from "commonStores/analytics/rootStoreContext";
import { t } from "common/localization/translate";
import { SideImage } from "./sideImage";
import { segmentsPaths } from "../../../../../commonStores/analytics/userInfoStore/logic/getSegmentPathById";
import { isWowJuniorSegment } from "../../../../../screens/organization/home/store/logic/isWowJuniorSegment";

const useStyles = makeStyles(theme => ({
    profile: {
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        minHeight: "fit-content",
    },
    avatar: {
        width: 60,
        height: 60,
        borderRadius: 50,
    },
    name: {
        marginTop: theme.spacing(1),
    },
}));

const getNavBarTitle = userInfoStore => {
    const { currentBusiness, currentSegment } = userInfoStore;

    if (isWowJuniorSegment(currentSegment?.segmentId)) {
        return "WOW Junior";
    }

    if (currentSegment) {
        return currentSegment.segmentName;
    }

    if (currentBusiness) {
        return currentBusiness.businessName;
    }
};

export const TopNavBar = observer(props => {
    const { userInfoStore } = useRootStore();
    const { userName } = userInfoStore;
    const title = getNavBarTitle(userInfoStore);
    const { currentSegment } = userInfoStore;
    const isClalitSegment = currentSegment && currentSegment.segmentId == segmentsPaths.CLALIT.id;
    const classes = useStyles();

    return (
        <div className={classes.profile}>
            <SideImage isClalitSegment={isClalitSegment} />
            <Typography className={classes.name} variant="h4">
                {title}
            </Typography>
            <Typography variant="body2">{t("sideBar.user.welcome", { userName })}</Typography>
        </div>
    );
});
