import React from "react";
import Grid from "@mui/material/Unstable_Grid2";
import { observer } from "mobx-react";
import { useScreenHomeStore } from "../../../store";
import { t } from "common/localization/translate";
import Typography from "@mui/material/Typography";
import { SelectDate } from "../selectDate";
import { SendOtpCodeSuccessPopUp } from "../sendOtpCodeSuccessPopUp";
import { AskForOtpButton } from "./askForOtpButton";
import { ValidateOtpButton } from "./validateOtpButton";
import { OtpErrors } from "./otpErrors";

export const AskForOtpCodeToEnterOnPreviousMonth = observer(() => {
    const store = useScreenHomeStore();

    if (!store.changeMonthDecision) {
        return null;
    }
    if (store.isValidOtpCode) {
        return null;
    }

    return (
        <Grid container spacing={3}>
            <SelectDate store={store} />
            <SendOtpCodeSuccessPopUp />
            <Grid item xs={12} spacing={3}>
                <Typography>{t("suppliers.supplierHome.insertOtpCodeForInsertCodes")}</Typography>
            </Grid>
            <AskForOtpButton />
            <OtpErrors />
            <ValidateOtpButton />
        </Grid>
    );
});
