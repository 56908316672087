import { t } from "../../../../../common/localization/translate";

export function handlePickupErrorCode(store, errorCode) {
    const pickupErrorCodes = {
        ERROR_LOCATION_NOT_FOUND: 2,
    };

    switch (errorCode) {
        case pickupErrorCodes.ERROR_LOCATION_NOT_FOUND:
            store.pickupErrorMessage = t("screenPurchaseFromSupport.coupons.deliveryModal.pickup.city.error.location");
            return;
        default:
            store.pickupErrorMessage = t("screenPurchaseFromSupport.coupons.deliveryModal.pickup.city.error.general");
            return;
    }
}
