import React from "react";
import { makeStyles } from "@mui/styles";
import { Typography } from "@mui/material";
import Grid from "@mui/material/Unstable_Grid2";
import { useScreenEditUserInfoStore } from "../../store";
import { observer } from "mobx-react";

export const OtpError = observer(() => {
    const store = useScreenEditUserInfoStore();
    const classes = useStyles();

    const { isShouldConfirmWithOtp, otpErrorMessage } = store;

    if (!isShouldConfirmWithOtp) {
        return null;
    }

    if (!otpErrorMessage) {
        return <Grid item className={classes.container} xs={6}></Grid>;
    }

    return (
        <Grid item className={classes.container} xs={6}>
            <Typography className={classes.text}>{otpErrorMessage}</Typography>
        </Grid>
    );
});

const useStyles = makeStyles(() => ({
    text: {
        fontSize: "14px",
        display: "inline-block",
        color: "red",
    },
    container: {
        paddingRight: 50,
        textAlign: "right",
        alignSelf: "center",
    },
}));
