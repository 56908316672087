import React from "react";
import { observer } from "mobx-react";
import { t } from "common/localization/translate";
import { useScreenPurchaseFromSupportStore } from "../store/index";
import { Button } from "@mui/material";
import Grid from "@mui/material/Unstable_Grid2";
import { makeStyles } from "@mui/styles";
import Tooltip from "@mui/material/Tooltip";

const useStyles = makeStyles(theme => ({
    button: {
        backgroundColor: theme.palette.primary.main,
        fontSize: 14,
        color: theme.palette.white,
        marginRight: 10,
        marginBottom: 10,
        borderRadius: 25,
        padding: 5,
        "&:hover": {
            backgroundColor: theme.palette.primary.secondGraphColor,
        },
    },
    buttonDisabled: {
        backgroundColor: "#9396b8",
        fontSize: 14,
        color: theme.palette.white,
        marginRight: 10,
        marginBottom: 10,
        borderRadius: 25,
        padding: 5,
        "&:hover": {
            backgroundColor: "#9396b8",
        },
    },
}));

export const PurchaseCouponButton = observer(({ coupon }) => {
    const store = useScreenPurchaseFromSupportStore();
    const { errorCode, couponsTableData, userName } = store;
    const isDisabledNoCoins = coupon.coins > store.userCoins;
    const isDisabledOutOfStock = coupon.isOutOfStock;
    const toolTipText = isDisabledNoCoins ? t("screenPurchaseFromSupport.coupons.column.couponType.button.noCoins") : t("screenPurchaseFromSupport.coupons.column.couponType.button.outOfStock");
    const classes = useStyles();

    if (errorCode || (couponsTableData.length === 0 && !userName)) {
        return null;
    }

    const onClick = () => {
        store.setCurrentCoupon(coupon);
        store.showPurchaseModal(true);
    };

    return (
        <Grid>
            {isDisabledNoCoins || isDisabledOutOfStock ? (
                <Tooltip title={toolTipText} classes={classes}>
                    <Button className={classes.buttonDisabled} size="small" type="submit" variant="contained">
                        {t("screenPurchaseFromSupport.coupons.column.couponType.purchase")}
                    </Button>
                </Tooltip>
            ) : (
                <Button className={classes.button} size="small" type="submit" variant="contained" onClick={onClick}>
                    {t("screenPurchaseFromSupport.coupons.column.couponType.purchase")}
                </Button>
            )}
        </Grid>
    );
});
