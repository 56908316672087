import React from "react";
import { t } from "common/localization/translate";
import { useScreenEntitlementStatusStore } from "../../store";
import { observer } from "mobx-react";
import { InfoText } from "../infoText";

export const Phone = observer(() => {
    const store = useScreenEntitlementStatusStore();

    return <InfoText description={t("screenEntitlementData.phone")} value={store.phone} />;
});
