import React from "react";
import { observer } from "mobx-react";
import { t } from "common/localization/translate";
import { useScreenEditUserInfoStore } from "../../../store/index";
import { TextField } from "@mui/material";
import Grid from "@mui/material/Unstable_Grid2";

export const PhoneInput = observer(props => {
    const store = useScreenEditUserInfoStore();

    const handlePhoneChange = event => {
        const { value } = event.target;
        store.handlePhoneChange(value);
    };

    return (
        <Grid item md={4} xs={12}>
            <TextField fullWidth label={t("screenEditUserInfo.userPhone")} name="phone" onChange={handlePhoneChange} required value={store.phone} variant="outlined" />
        </Grid>
    );
});
