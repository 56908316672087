import React, { useState } from "react";
import { Typography } from "@mui/material";
import { t } from "../../../../../common/localization/translate";
import { makeStyles } from "@mui/styles";
import { replaceHrefTagsWithLinks } from "../../store/logic/replaceHrefTagsWithLinks";

export const CouponDescription = ({ description }) => {
    const [expanded, setExpanded] = useState(false);

    const toggleExpanded = () => {
        setExpanded(!expanded);
    };

    const useStyles = makeStyles(() => ({
        description: {
            whiteSpace: "pre-wrap",
            overflow: "hidden",
            textOverflow: "ellipsis",
            display: "-webkit-box",
            WebkitBoxOrient: "vertical",
            WebkitLineClamp: expanded ? "none" : 4,
            wordWrap: "break-word",
            maxWidth: "200px",
            textAlign: "left",
            cursor: "pointer",
        },
        button: {
            textDecoration: "underline",
            cursor: "pointer",
            textAlign: "left",
        },
    }));

    const classes = useStyles();

    const safeDescription = typeof description === "string" ? description : "";
    const descriptionFormatted = replaceHrefTagsWithLinks(safeDescription);

    return (
        <div>
            <Typography className={classes.description}>{descriptionFormatted}</Typography>
            {safeDescription.split("\n").length > 4 && (
                <Typography className={classes.button} onClick={toggleExpanded}>
                    {expanded ? t("screenPurchaseFromSupport.coupons.column.description.showLess") : t("screenPurchaseFromSupport.coupons.column.description.showMore")}
                </Typography>
            )}
        </div>
    );
};
