import { UserInfoStore } from "./userInfoStore";

class RootStore {
    constructor(pathName) {
        this.userInfoStore = new UserInfoStore(this, pathName);
    }
}

export function createRootStore(pathName) {
    const store = new RootStore(pathName);
    return store;
}
