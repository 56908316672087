import React from "react";
import { makeStyles } from "@mui/styles";
import { observer } from "mobx-react";
import Grid from "@mui/material/Unstable_Grid2";
import SimpleCube from "common/ui/simpleCube";
import { t } from "common/localization/translate";
import { useScreenHomeStore } from "../store";
import { formatNumber } from "common/formatNumber";
import BarChartIcon from "@mui/icons-material/BarChart";
import { Tooltip } from "@mui/material";

const useStyles = makeStyles(theme => ({
    row: {
        width: "100%",
    },
    reversedIcon: {
        color: theme.palette.primary.main,
    },
    cube: {
        height: 109.5,
    },
}));

export const CategoryPositionCube = observer(() => {
    const { categoryPosition, businessesInTheCategory, isFetching, categoryName } = useScreenHomeStore();

    const categoryPositionFormatted = formatNumber(categoryPosition);
    const businessesInTheCategoryFormatted = formatNumber(businessesInTheCategory);

    const classes = useStyles();
    return (
        <Grid item sm={12} lg={3} className={classes.row}>
            <Tooltip title={t("business.home.categoryPositionCube.tooltip")}>
                <div className={classes.cube}>
                    <SimpleCube
                        isReversed={true}
                        isFetching={isFetching}
                        isWithConstatHeight={true}
                        label={t("business.home.PositionCube.title", { categoryName })}
                        value={t("businees.home.cubes.categoryPosition", { categoryPositionFormatted, businessesInTheCategoryFormatted })}
                        icon={<BarChartIcon className={classes.reversedIcon} />}
                        withoutMargin={true}
                    />
                </div>
            </Tooltip>
        </Grid>
    );
});
