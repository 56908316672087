import React, { useState, useEffect } from "react";
import { Redirect } from "react-router-dom";
import Dashboard from "./Dashboard";
import { getIsLoggedInServer } from "./server/getIsLoggedInServer";
import { useRootStore } from "commonStores/analytics/rootStoreContext";
import { clearAllLocalData } from "commonStores/analytics/localStorage/localPersistence";

export function AuthWrapper({ route, location, history }) {
    const rootStore = useRootStore();
    const [isLoggedIn, setIsLoggedIn] = useState(false);
    const [isFetching, setIsFetching] = useState(true);

    useEffect(() => {
        checkIsLoggedIn();
    }, [location.pathname]);

    async function checkIsLoggedIn() {
        const res = await getIsLoggedInServer();
        setIsLoggedIn(res.isLoggedIn);
        rootStore.userInfoStore.isLoggedIn = res.isLoggedIn;
        if (!res.isLoggedIn) {
            clearAllLocalData();
        }
        setIsFetching(false);
    }

    if (isFetching) {
        return null;
    }

    if (isLoggedIn) {
        return <Dashboard route={route} location={location} history={history} />;
    }

    return <Redirect to={{ pathname: `${route.path}/login` }} />;
}
