import React from "react";
import { makeStyles } from "@mui/styles";
import { Page } from "components";
import { observer } from "mobx-react";
import { useScreenHomeStore } from "../store";
import { t } from "common/localization/translate";
import { SupplierCodes } from "./supplier/supplierCodes";
import { SupplierPreviousMonths } from "./supplier/supplierPreviousMonths";
import { SuccessEnterCodesPopUp } from "../components/supplier/successEnterCodesPopUp";
import { AreYouSureFinishLoadingCodesPopUp } from "../components/supplier/areYouSureFinishLoadingCodesPopUp";

const useStyles = makeStyles(theme => ({
    root: {
        padding: theme.spacing(3),
        width: "100%",
        height: "100%",
    },
}));

export const SupplierHome = observer(() => {
    const store = useScreenHomeStore();
    const classes = useStyles();
    if (!store.isSupplier) {
        return null;
    }

    return (
        <Page className={classes.root} title={t("suppliers.home.suuplierDashboardTitle")}>
            <SupplierCodes store={store} />
            <AreYouSureFinishLoadingCodesPopUp />
            <SuccessEnterCodesPopUp store={store} />
            <SupplierPreviousMonths />
        </Page>
    );
});
