import React from "react";
import { makeStyles } from "@mui/styles";
import { Avatar, Card, CardContent, CardHeader, Divider, List } from "@mui/material";
import { t } from "common/localization/translate";
import { useScreenHomeStore } from "../../../store/index";
import { observer } from "mobx-react";
import ClearAllRoundedIcon from "@mui/icons-material/ClearAllRounded";

const useStyles = makeStyles(theme => ({
    root: {
        display: "flex",
        flexDirection: "column",
        marginTop: theme.spacing(1),
        height: "100%",
    },
    content: {
        flexGrow: 1,
        padding: 0,
        flex: 1,
    },
    list: {
        flex: 1,
    },
    avatar: {
        backgroundColor: theme.palette.primary.main,
        height: 48,
        width: 48,
        marginLeft: theme.spacing(7),
    },
}));

export const LeadingCoupons = observer(props => {
    const classes = useStyles();
    const { isFetching } = useScreenHomeStore();
    const { rows } = props;

    return (
        <Card className={classes.root}>
            <CardHeader
                title={t("business.home.leadingCoupons.title")}
                subheader={t("business.home.leadingCoupons.subheader")}
                isFetching={isFetching}
                action={
                    <Avatar className={classes.avatar} size="small">
                        <ClearAllRoundedIcon />
                    </Avatar>
                }
            />
            <Divider />
            <CardContent className={classes.content}>
                <List className={classes.list}>{rows}</List>
            </CardContent>
            <Divider />
        </Card>
    );
});
