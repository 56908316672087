import { isMultiSegment } from "../../../healthAndFitnessData/store/logic/isMultiSegment";

const POLICE = 46;
const POLICE_OFFICERS_COURSE = 50;
const POLICE_50_PLUS = 52;

export const policeSegmentIds = [POLICE, POLICE_OFFICERS_COURSE, POLICE_50_PLUS];

export function isPoliceSegment(segment) {
    if (!isMultiSegment(segment)) {
        return false;
    }
    return policeSegmentIds.includes(segment.mainSegmentId);
}
