import React from "react";
import { makeObservable, observable } from "mobx";
import { getSendNotificationScreenData } from "./server/getSendNotificationScreenData";
import { validateIdNum } from "../../services/validateIdNum";
import { sendNotificationToUserServer } from "./server/sendNotificationToUserServer";
import { errorCodes } from "../../services/errorCodes";
import { getNotificationNavigationsList } from "./logic/notificationNavigationsList";
import { getCurrentPath } from "utils/direction/getCurrentPath";

const ScreenSendNotificationToUserStoreContext = React.createContext({});
export const ScreenSendNotificationToUserProvider = ScreenSendNotificationToUserStoreContext.Provider;
export const useScreenSendNotificationStore = () => React.useContext(ScreenSendNotificationToUserStoreContext);

class ScreenSendNotificationToUserStore {
    constructor(rootStore) {
        this.rootStore = rootStore;

        this.userIdNum = null;
        this.userName = null;
        this.userIdNumToSendNot = null;
        this.isFetching = null;
        this.errorCode = null;
        this.text = null;
        this.link = null;
        this.navigateTo = null;
        this.isExternalNot = null;
        this.isInternalNot = null;
        this.isSentSelfNotification = null;
        this.isEmptyNotificationText = null;
        this.isEmptyLink = null;
        this.isEmptyNotificationType = null;
        this.isSuccessSendNotification = null;
        this.isSendNotificationToAllUsers = false;
        this.isShowSendNotificationPopUpErrorMessage = false;
        this.sendNotificationPopUpErrorMessageErrorCode = null;

        makeObservable(this, {
            userIdNum: observable,
            userName: observable,
            userIdNumToSendNot: observable,
            isFetching: observable,
            errorCode: observable,
            text: observable,
            link: observable,
            navigateTo: observable,
            isExternalNot: observable,
            isInternalNot: observable,
            isSentSelfNotification: observable,
            isEmptyNotificationText: observable,
            isEmptyLink: observable,
            isEmptyNotificationType: observable,
            isSuccessSendNotification: observable,
            isSendNotificationToAllUsers: observable,
            isShowSendNotificationPopUpErrorMessage: observable,
            sendNotificationPopUpErrorMessageErrorCode: observable,
        });
    }

    getScreenData = async () => {
        try {
            this.isFetching = true;
            const segmentId = this.rootStore.userInfoStore.currentSegment ? this.rootStore.userInfoStore.currentSegment.segmentId : null;
            const { isOrganizationPath } = getCurrentPath();
            this.isSendNotificationToAllUsers = false;
            const response = await getSendNotificationScreenData({ userIdNum: this.userIdNum, segmentId, isOrganizationPath });
            const locations = getNotificationNavigationsList();
            this.navigateTo = locations[0].value;
            if (!response.isSuccess) {
                this.errorCode = response.errorCode;
            }
            this.userName = response.extra.userName;
            this.userIdNumToSendNot = response.extra.idNumber;
            this.isFetching = false;
        } catch (e) {
            this.isFetching = false;
        }
    };

    submitIdNum = () => {
        this.isSentSelfNotification = false;
        this.userName = null;
        this.errorCode = null;
        const { isOrganizationPath } = getCurrentPath();
        const isValidId = isOrganizationPath ? (this.userIdNum ? true : false) : validateIdNum(this.userIdNum);
        if (isValidId) {
            this.getScreenData();
            return;
        }
        this.errorCode = errorCodes.ERROR_TYPE_INVALID_DATA;
        return;
    };

    sendNotificationToUser = async isSelfSendingForTesting => {
        if (this.isEmptyNotificationText || this.isEmptyLink || this.isEmptyNotificationType) {
            return;
        }

        const segmentId = this.rootStore.userInfoStore.currentSegment ? this.rootStore.userInfoStore.currentSegment.segmentId : null;
        const data = await sendNotificationToUserServer({
            segmentId,
            isSelfSendingForTesting,
            text: this.text,
            navigateTo: this.navigateTo,
            link: this.link,
            idNum: this.userIdNumToSendNot,
            isExternalNot: this.isExternalNot,
            isInternalNot: this.isInternalNot,
            isSendNotificationToAllUsers: this.isSendNotificationToAllUsers,
        });
        this.isSentSelfNotification = true;
        this.isSuccessSendNotification = data.isSuccess;

        const { isOrganizationPath } = getCurrentPath();
        if (isOrganizationPath) {
            this.isShowSendNotificationPopUpErrorMessage = !data.isSuccess;
            this.sendNotificationPopUpErrorMessageErrorCode = data.errorCode;
        }

        return;
    };
}

export function createScreenSendNotificationToUserStore(rootStore) {
    const store = new ScreenSendNotificationToUserStore(rootStore);
    return store;
}
