import { t } from "common/localization/translate";

export const UserBalanceErrors = {
    onlyNumbers: t("screenUserBalance.errors.onlyNumbers"),
    tooShort: t("screenUserBalance.errors.idNumberTooShort"),
    userNotExsist: t("screenUserBalance.errors.userNotExsist"),
    userNotExsistIdOrPhone: t("screenUserBalance.errors.userNotExsist.idOrPhone"),
    invalidInput: t("screenUserBalance.errors.invalidInput"),
    limitReached: t("screenUserBalance.errors.limitReached"),
};
