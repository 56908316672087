import React from "react";
import { makeStyles } from "@mui/styles";
import { observer } from "mobx-react";
import { useScreenHomeStore } from "../../../store";
import NumberCube from "./numberCube";

const useStyles = makeStyles(() => ({
    statsContainer: {
        display: "flex",
    },
}));

export const MeasurementsCubes = observer(() => {
    const classes = useStyles();
    const { arrayOfMeausermentsForChart } = useScreenHomeStore();
    const cubes = arrayOfMeausermentsForChart.map(item => <NumberCube item={item} key={item.id} />);
    return <div className={classes.statsContainer}>{cubes}</div>;
});
