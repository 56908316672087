import React from "react";
import { Typography } from "@mui/material";
import { makeStyles } from "@mui/styles";

const useStyles = makeStyles(() => ({
    error: {
        color: "red",
        marginRight: 10,
    },
}));

export const ErrorMessage = props => {
    const classes = useStyles(props);
    const text = props.isError ? props.text : null;

    return (
        <Typography className={classes.error} component="h2" variant="overline" gutterBottom>
            {text}
        </Typography>
    );
};
