import React from "react";
import PerfectScrollbar from "react-perfect-scrollbar";
import { makeStyles } from "@mui/styles";
import { observer } from "mobx-react";
import WaveChart from "common/ui/waveChart";
import { t } from "common/localization/translate";
import { useScreenHomeStore } from "../../../store/index";
import { LoadingSpinner } from "common/ui/loadingSpinner";

const useStyles = makeStyles(theme => ({
    inner: {
        height: 375,
        minWidth: 500,
    },
    chart: {
        height: "100%",
    },
}));

export const TotalImpressionWaveChart = observer(() => {
    const classes = useStyles();
    const { amountOfImpresionsForBusinessGraphData, isFetchingImpression } = useScreenHomeStore();
    if (!amountOfImpresionsForBusinessGraphData) {
        return null;
    }

    const counts = amountOfImpresionsForBusinessGraphData.map(item => item.count);
    const labels = amountOfImpresionsForBusinessGraphData.map(item => item.date);
    const data = {
        data: counts,
        labels,
    };

    return (
        <PerfectScrollbar>
            <div className={classes.inner}>
                <LoadingSpinner isFetching={isFetchingImpression} className={classes.chart}>
                    <WaveChart className={classes.chart} data={data.data} labels={data.labels} linesData={[{ dataArray: data.data, textLabel: t("business.home.impressionGraph.linesData") }]} />
                </LoadingSpinner>
            </div>
        </PerfectScrollbar>
    );
});
