import { dashboardTypes } from "screens/loginV2/store/logic/dashboardTypes";
export function getPagesWithDashboardPath(pages, dashboardPath, dashboardType) {
    if (dashboardType == dashboardTypes.SUPPORT || dashboardType == dashboardTypes.SUPPLIERS || dashboardType == dashboardTypes.BUSINESS) {
        return pages;
    }
    const newPages = pages.map(item => {
        const newItem = { ...item };
        if (item.href.includes(dashboardPath)) {
            return item;
        }
        newItem.href = item.href + "/" + dashboardPath;
        if (item.children) {
            const newChildren = item.children.map(child => {
                if (child.href.includes(dashboardPath)) {
                    return child;
                }
                child.href = child.href + "/" + dashboardPath;
                return child;
            });
            newItem.children = newChildren;
        }
        return newItem;
    });
    return newPages;
}
