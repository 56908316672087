import { colors } from "@mui/material";

const white = "#FFFFFF";
const black = "#000000";
const YUVITAL_COLOR = "#F3C22C";
const GREY = "#bdbdbd";

export const rumbleColors = {
    mainColor: YUVITAL_COLOR,
    lightColor: YUVITAL_COLOR,
    darkColor: YUVITAL_COLOR,
    secondGraphColor: GREY,
    yuvitalColor: YUVITAL_COLOR,
};
export const getPaletteForTheme = ({ mainColor, lightColor, darkColor, secondGraphColor }) => {
    return {
        black,
        white,
        primary: {
            contrastText: white,
            dark: darkColor,
            main: mainColor,
            light: lightColor,
            secondGraphColor: secondGraphColor,
        },
        secondary: {
            contrastText: white,
            dark: colors.blue[900],
            main: colors.blue["A400"],
            light: colors.blue["A400"],
        },
        error: {
            contrastText: white,
            dark: colors.red[900],
            main: colors.red[600],
            light: colors.red[400],
        },
        text: {
            primary: colors.blueGrey[900],
            secondary: colors.blueGrey[600],
            link: colors.blue[600],
        },
        link: colors.blue[800],
        icon: colors.blueGrey[600],
        background: {
            default: "#F4F6F8",
            paper: white,
        },
        divider: colors.grey[200],
    };
};

export default getPaletteForTheme(rumbleColors);
