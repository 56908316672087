import { httpCall } from "common/httpCall";

export async function getIsLoggedInServer() {
    try {
        const res = await httpCall("dashboards/isLoggedIn");
        return res;
    } catch {
        return { isLoggedIn: false };
    }
}
