import React from "react";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import ArrowDropUpIcon from "@mui/icons-material/ArrowDropUp";
import { Typography } from "@mui/material";
import Grid from "@mui/material/Unstable_Grid2";
import { makeStyles } from "@mui/styles";
import { observer } from "mobx-react";
import { CommonButton } from "common/ui/commonButton";

const useStyles = makeStyles(() => ({
    text: {
        color: "#fff",
        marginRight: 5,
        width: "90%",
    },
    button: {
        width: "130px",
    },
}));

export const StageWithDropdown = observer(props => {
    const { isShowingData, title, onClick } = props;
    const classes = useStyles();
    const icon = isShowingData ? <ArrowDropUpIcon fontSize={"large"} /> : <ArrowDropDownIcon fontSize={"large"} />;

    return (
        <Grid item sm={4} lg={3} spacing={2}>
            <CommonButton className={classes.button} onClick={onClick}>
                <Typography className={classes.text}>{title}</Typography>
                {icon}
            </CommonButton>
        </Grid>
    );
});
