import { t } from "common/localization/translate";

export const validateOtpCodeErrorTexts = {
    otpCodeIsInvalid: t("suppliers.supplierHome.otpCodeIsInvalid"),
    otpCodeVerifyLimiterExceeded: t("suppliers.supplierHome.otpCodeVerifyLimiterExceeded"),
};

export const validateOtpCodeErrorCodes = {
    INVALID_CODE: "invalid code",
    RATE_LIMITER_EXCEEDED: "rateLimiterExceeded",
};
