import { t } from "common/localization/translate";

const periodTypes = {
    LAST_DAY: "LAST_DAY",
    LAST_THREE_DAYS: "LAST_THREE_DAYS",
    LAST_SEVEN_DAYS: "LAST_SEVEN_DAYS",
};

export function getPeriodsForAppOpenByHours() {
    return [
        {
            type: periodTypes.LAST_DAY,
            text: t("screenAppOpenByHours.dropDownOption.lastDay"),
        },
        {
            type: periodTypes.LAST_THREE_DAYS,
            text: t("screenAppOpenByHours.dropDownOption.lastThreeDays"),
        },
        {
            type: periodTypes.LAST_SEVEN_DAYS,
            text: t("screenAppOpenByHours.dropDownOption.lastWeek"),
        },
    ];
}
