import React from "react";
import { makeStyles } from "@mui/styles";
import { observer } from "mobx-react";
import Grid from "@mui/material/Unstable_Grid2";
import SimpleCube from "common/ui/simpleCube";
import { t } from "common/localization/translate";
import { useScreenHomeStore } from "../../store";
import { formatNumber } from "common/formatNumber";
import TouchAppIcon from "@mui/icons-material/TouchApp";
import { Tooltip } from "@mui/material";

const useStyles = makeStyles(theme => ({
    row: {
        width: "100%",
    },
    reversedIcon: {
        color: theme.palette.primary.main,
    },
    img: {
        height: 35,
    },
}));

export const ClicksCube = observer(() => {
    const { isFetching, clickForBusiness } = useScreenHomeStore();
    const clickForBusinessFormatted = formatNumber(clickForBusiness);
    const classes = useStyles();

    return (
        <Grid item sm={12} lg={3} className={classes.row}>
            <Tooltip title={t("business.home.clicksCube.tooltip")}>
                <div>
                    <SimpleCube label={t("business.home.clicksCube.title")} isFetching={isFetching} isWithConstatHeight={true} value={clickForBusinessFormatted} icon={<TouchAppIcon />} withoutMargin={true} />
                </div>
            </Tooltip>
        </Grid>
    );
});
