import React, { useState, useRef } from "react";
import { makeStyles } from "@mui/styles";
import { observer } from "mobx-react-lite";
import { Typography, TextField, DialogTitle, CircularProgress } from "@mui/material";
import { useScreenPurchaseFromSupportStore } from "../../store/index";
import { t } from "../../../../../common/localization/translate";

const useStyles = makeStyles(theme => ({
    root: {
        color: theme.palette.white,
        fontSize: 18,
        fontWeight: "700",
        backgroundColor: theme.palette.primary.main,
        "& h6": {
            color: theme.palette.white,
        },
        textAlign: "left",
    },
    dialog: {
        width: 500,
        minHeight: 180,
    },
    title: {
        fontWeight: "bold",
        color: "black",
        fontSize: 20,
        textAlign: "center",
        padding: 20,
    },
    inputContainer: {
        display: "flex",
        justifyContent: "center",
        gap: "10px",
        marginTop: "30px",
    },
    input: {
        width: "50px",
        height: "50px",
        textAlign: "center",
        fontSize: "20px",
        direction: "ltr",
    },
    resendLink: {
        margin: "40px",
        fontSize: "14px",
        textAlign: "center",
        color: "black",
    },
    sendAgain: {
        textDecoration: "underline",
        cursor: "pointer",
    },
    error: {
        color: "#D00000",
        paddingTop: "30px",
        textAlign: "center",
    },
    spinner: {
        alignSelf: "center",
        justifyContent: "center",
        flex: 1,
        alignItems: "center",
    },
}));

export const OtpContainer = observer(() => {
    const store = useScreenPurchaseFromSupportStore();
    const classes = useStyles();
    const [otp, setOtp] = useState(["", "", "", ""]);
    const inputRefs = useRef([]);
    const { sendOtp, isSendingOtp, errorOtpText, parentPhoneLastDigits, validateCode } = store;

    const handleChange = (index, value) => {
        store.errorOtpText = "";
        if (/^[0-9]?$/.test(value)) {
            const newOtp = [...otp];
            newOtp[index] = value;
            setOtp(newOtp);
            if (value && index < otp.length - 1) {
                inputRefs.current[index + 1]?.focus();
            } else if (index === otp.length - 1) {
                validateCode(newOtp.join(""));
            }
        }
    };

    const handleKeyDown = (index, e) => {
        if (e.key === "Backspace" && !otp[index] && index > 0) {
            inputRefs.current[index - 1]?.focus();
        }
    };

    const sendAgain = () => {
        sendOtp();
    };

    return (
        <div className={classes.dialog} dir="ltr">
            <DialogTitle className={classes.root} id="responsive-dialog-title">
                {t("screenPurchaseFromSupport.coupons.purchaseModal.otp.title")}
            </DialogTitle>
            <Typography className={classes.title}>{t("screenPurchaseFromSupport.coupons.purchaseModal.otp.smsToNumber", { parentPhoneLastDigits })}</Typography>
            <div className={classes.inputContainer} dir="ltr">
                {otp.map((digit, index) => (
                    <TextField
                        autoFocus={index === 0}
                        key={index}
                        className={classes.input}
                        variant="outlined"
                        value={digit}
                        onChange={e => handleChange(index, e.target.value)}
                        onKeyDown={e => handleKeyDown(index, e)}
                        inputRef={el => (inputRefs.current[index] = el)}
                        inputProps={{
                            maxLength: 1,
                            style: { textAlign: "center", direction: "ltr" },
                            dir: "ltr",
                        }}
                    />
                ))}
            </div>
            <Typography className={classes.error}>{errorOtpText}</Typography>
            <Typography className={classes.resendLink}>
                {isSendingOtp ? (
                    <CircularProgress size={20} className={classes.spinner} />
                ) : (
                    <div>
                        {t("screenPurchaseFromSupport.coupons.purchaseModal.otp.noSms.3")}
                        <span className={classes.sendAgain} onClick={sendAgain}>
                            {t("screenPurchaseFromSupport.coupons.purchaseModal.otp.sendAgain")}
                        </span>
                        {t("screenPurchaseFromSupport.coupons.purchaseModal.otp.noSms.2")}
                        {t("screenPurchaseFromSupport.coupons.purchaseModal.otp.noSms.1")}
                    </div>
                )}
            </Typography>
        </div>
    );
});
