import React from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import { observer } from "mobx-react-lite";
import { useScreenHideCouponTypesStore } from "../store";
import { makeStyles } from "@mui/styles";
import { t } from "common/localization/translate";

const useStyles = makeStyles(theme => ({
    title: {
        backgroundColor: theme.palette.primary.main,
        color: theme.palette.white,
        fontSize: 16,
        fontFamily: "Assistant",
        fontWeight: "bold",
    },
    buttons: {
        fontSize: 14,
        color: theme.palette.white,
        backgroundColor: theme.palette.primary.main,
        "&:hover": {
            color: theme.palette.white,
            backgroundColor: theme.palette.primary.secondGraphColor,
        },
    },
}));

export const SuccessHideCouponTypePopUp = observer(() => {
    const store = useScreenHideCouponTypesStore();
    const classes = useStyles();
    const handleClose = () => {
        store.isSuccessHideCouponType = false;
    };

    return (
        <div>
            <Dialog open={store.isSuccessHideCouponType}>
                <DialogTitle className={classes.title}>{t("organizationDashboard.hideCouponType.AreYouSurePopUpTitle")}</DialogTitle>
                <DialogContent>
                    <DialogContentText>
                        {t("organizationDashboard.hideCouponType.successHideCoupn", { couponTypeTitle: store.couponTypeTitle })}
                        <br></br>
                        {t("organizationDashboard.hideCouponType.successHideCouponNote")}
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleClose} className={classes.buttons} autoFocus>
                        {t("general.great")}
                    </Button>
                </DialogActions>
            </Dialog>
        </div>
    );
});
