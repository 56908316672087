import React from "react";
import Grid from "@mui/material/Unstable_Grid2";
import { observer } from "mobx-react";
import { useBillingReportSystemStore } from "../store/index";
import { t } from "common/localization/translate";
import DesktopDatePicker from "@mui/lab/DesktopDatePicker";
import moment from "moment";
import InputLabel from "@mui/material/InputLabel";
import { makeStyles } from "@mui/styles";

const useStyles = makeStyles(theme => ({
    label: {
        fontSize: 18,
        fontWeight: "bold",
    },
}));

export const SelectDate = observer(() => {
    const classes = useStyles();
    const store = useBillingReportSystemStore();
    const defaultDate = moment().subtract(1, "months").toDate();
    const maxDate = new Date(moment().subtract(1, "month").endOf("month").toDate());
    const minDate = new Date(moment().subtract(1, "years").startOf("month").toDate());

    const handleDateChange = selectedDate => {
        if (selectedDate > maxDate || selectedDate < minDate) {
            store.selectedMonthDate = defaultDate;
            return;
        }
        store.selectedMonthDate = selectedDate.toDate();
    };

    return (
        <Grid item xs={12}>
            <InputLabel className={classes.label} shrink>
                {t("suppliers.projectManagerHome.selectMonthAndYear")}
            </InputLabel>
            <DesktopDatePicker defaultValue={defaultDate} minDate={minDate} maxDate={maxDate} views={["year", "month"]} value={store.selectedMonthDate || defaultDate} onChange={handleDateChange} />
        </Grid>
    );
});
