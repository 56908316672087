import React from "react";
import { observer } from "mobx-react";
import Grid from "@mui/material/Unstable_Grid2";
import { NotificationNavigationDropDown } from "./notificationNavigationDropDown";
import { ExternalLinkInput } from "./externalLinkInput";
import { NotificationType } from "./notificationType";
import { NotificationText } from "./notificationText";

export const NotificationDetails = observer(() => {
    return (
        <Grid>
            <NotificationNavigationDropDown />
            <ExternalLinkInput />
            <NotificationType />
            <NotificationText />
        </Grid>
    );
});
