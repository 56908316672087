import React from "react";
import { TextField } from "@mui/material";
import Grid from "@mui/material/Unstable_Grid2";
import { observer } from "mobx-react";
import { t } from "common/localization/translate";
import { makeStyles } from "@mui/styles";
import { useScreenSendNotificationStore } from "../../../store/index";
import { getErrorTextByErrorCode } from "../../../store/logic/getErrorTextByErrorCode";
import { getCurrentPath } from "utils/direction/getCurrentPath";
import { getIsLtr } from "theme/getIsLtr";

const useStyles = makeStyles(() => ({
    input: {
        width: "155px",
    },
}));

export const IdNumInput = observer(() => {
    const store = useScreenSendNotificationStore();
    const placeHolder = t("support.common.placeHolder");
    const { isOrganizationPath } = getCurrentPath();
    const inputProps = { maxLength: isOrganizationPath ? 20 : 10 };
    const classes = useStyles();
    const errorText = getErrorTextByErrorCode(store.errorCode);
    const isLtr = getIsLtr();
    const FormHelperTextProps = isLtr ? { style: { textAlign: "left" } } : null;

    const handleInputChange = event => {
        store.errorCode = null;
        const { value } = event.target;
        store.userIdNum = value;
    };
    const isError = store.errorCode ? true : false;

    return (
        <Grid item sm={4} lg={4} spacing={2}>
            <TextField value={store.userIdNum || ""} className={classes.input} id="standard-basic" type="string" error={isError} placeholder={placeHolder} helperText={errorText} FormHelperTextProps={FormHelperTextProps} inputProps={inputProps} onChange={handleInputChange} />
        </Grid>
    );
});
