import React from "react";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import { makeStyles } from "@mui/styles";
import { observer } from "mobx-react-lite";
import { t } from "common/localization/translate";
import { useScreenPurchaseFromSupportStore } from "../../store";
import { getIsDeliveryType } from "../addressForm";

const useStyles = makeStyles(() => ({
    title: {
        fontWeight: "bold",
        fontSize: 20,
        color: "black",
        textAlign: "center",
        paddingBottom: 15,
    },
    text: {
        fontSize: 12,
        color: "black",
        textAlign: "center",
    },
}));

export const UnderageText = observer(() => {
    const store = useScreenPurchaseFromSupportStore();
    const classes = useStyles();

    const { isMinor, coupon } = store;
    const isDeliveryType = getIsDeliveryType(coupon?.activationType);
    const isWithCurrency = coupon.priceInCurrency;

    if (!(isMinor && isWithCurrency)) {
        return null;
    }

    return (
        <div>
            <div className={classes.dialog}>
                <DialogContent className={classes.content}>
                    <DialogContentText className={classes.title}>{t("screenPurchaseFromSupport.coupons.purchaseModal.underage")}</DialogContentText>
                    {!isDeliveryType && <DialogContentText className={classes.text}>{t("screenPurchaseFromSupport.coupons.purchaseModal.underage.sms")}</DialogContentText>}
                </DialogContent>
            </div>
        </div>
    );
});
