import React from "react";
import { makeStyles } from "@mui/styles";
import { observer } from "mobx-react";
import AttachMoneyIcon from "@mui/icons-material/AttachMoney";
import { Tooltip, Typography } from "@mui/material";
import Grid from "@mui/material/Unstable_Grid2";
import SimpleCube from "common/ui/simpleCube";
import { t } from "common/localization/translate";
import ConfirmationNumber from "@mui/icons-material/ConfirmationNumber";
import AccountBalanceWallet from "@mui/icons-material/AccountBalanceWallet";
import { segmentIds } from "screens/controlPanel/addCouponType/store/logic/segmentIds";
import { PercentageBudgetUsedLabel } from "./percentageBudgetUsedLabel";
import { formatNumber } from "../../../../../common/formatNumber";

const useStyles = makeStyles(theme => ({
    row: {
        width: "100%",
        minWidth: 300,
    },
    title: {
        width: "100%",
        marginLeft: 17,
        marginTop: 10,
        textDecoration: "underline",
    },
    reversedIcon: {
        color: theme.palette.primary.main,
    },
    img: {
        height: 35,
    },
    cube: {
        height: 110,
        minWidth: 300,
    },
}));

export const InfoCubes = observer(props => {
    const { isFetching, infoDataForPeriod, title, segment } = props;
    const { verified: verifiedPurchases, purchases: unverifiedPurchases, budget: totalUsersToDemandPayment, percentageBudgetUsed } = infoDataForPeriod;
    const classes = useStyles();

    return (
        <Grid container spacing={2}>
            <Typography component="h1" variant="h6" className={classes.title}>
                {title}
            </Typography>
            {verifiedPurchases ? (
                <Grid item xs={12} md className={classes.row}>
                    <Tooltip title={t("suppliers.infoCubes.validatedPurchasesToolTip")} placement="top-end">
                        <Grid item xs={12} md className={classes.cube}>
                            <SimpleCube label={t("suppliers.infoCubes.validatedPurchases")} value={`₪${formatNumber(verifiedPurchases)}`} icon={<ConfirmationNumber />} isFetching={isFetching} />
                        </Grid>
                    </Tooltip>
                </Grid>
            ) : null}
            <Grid item xs={12} md className={classes.row}>
                <Tooltip title={t("suppliers.infoCubes.unvalidatedPurchasesToolTip")} placement="top">
                    <Grid item xs={12} md className={classes.cube}>
                        <SimpleCube label={t("suppliers.infoCubes.unvalidatedPurchases")} value={unverifiedPurchases ? `₪${formatNumber(unverifiedPurchases)}` : 0} icon={<AccountBalanceWallet />} isFetching={isFetching} />
                    </Grid>
                </Tooltip>
            </Grid>
            {segment == segmentIds.CLALIT_SEGMENT_ID ? (
                <Grid item xs={12} md className={classes.row}>
                    <Tooltip title={t("suppliers.infoCubes.totalUsersToDemandPaymentToolTip")} placement="top">
                        <Grid item xs={12} md className={classes.cube}>
                            <SimpleCube
                                label={t("suppliers.infoCubes.totalUsersToDemandPayment")}
                                value={totalUsersToDemandPayment ? `₪${formatNumber(totalUsersToDemandPayment)}` : 0}
                                icon={<AttachMoneyIcon />}
                                isFetching={isFetching}
                                sideComponent={<PercentageBudgetUsedLabel isFetching={isFetching} percentageBudgetUsed={percentageBudgetUsed} />}
                            />
                        </Grid>
                    </Tooltip>
                </Grid>
            ) : null}
        </Grid>
    );
});
