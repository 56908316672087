import React from "react";
import { makeStyles } from "@mui/styles";
import { Page } from "components";
import { observer } from "mobx-react";
import { useScreenHomeStore } from "../store";
import { t } from "common/localization/translate";
import { InfoCubes } from "./supplier/infoCubes";
import moment from "moment";

const useStyles = makeStyles(theme => ({
    root: {
        padding: theme.spacing(3),
        width: "100%",
        height: "100%",
    },
    divider: {
        marginTop: 20,
        backgroundColor: theme.palette.primary.main,
    },
}));

export const ProjectManagerHome = observer(() => {
    const store = useScreenHomeStore();
    const classes = useStyles();
    const isFetching = store.isFetchingProjectManagerData;

    if (!store.isProjectManager) {
        return null;
    }

    return (
        <Page className={classes.root} title={t("suppliers.home.suuplierDashboardTitle")}>
            <InfoCubes infoDataForPeriod={store.thisMonthData ? store.thisMonthData : {}} title={t("suppliers.infoCubes.monthNumber", { monthNumber: moment().format("MM/YYYY") })} isFetching={isFetching} segment={store.segment} />
            <InfoCubes infoDataForPeriod={store.lastMonthData ? store.lastMonthData : {}} title={t("suppliers.infoCubes.monthNumber", { monthNumber: moment().subtract(1, "month").format("MM/YYYY") })} isFetching={isFetching} segment={store.segment} />
            <InfoCubes infoDataForPeriod={store.twoMonthsAgoData ? store.twoMonthsAgoData : {}} title={t("suppliers.infoCubes.monthNumber", { monthNumber: moment().subtract(2, "month").format("MM/YYYY") })} isFetching={isFetching} segment={store.segment} />
            <InfoCubes infoDataForPeriod={store.allTimeData ? store.allTimeData : {}} title={t("suppliers.infoCubes.allTime")} isFetching={isFetching} segment={store.segment} />
        </Page>
    );
});
