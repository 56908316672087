import { styleSheet } from "../styleSheet";
import { t } from "common/localization/translate";
import { writeToRow } from "screens/organization/home/store/logic/createExcel/writeToRow";
import { createBloodPressureDataForSingleCop } from "./createBloodPressureDataForSingleCop";

const END_DATA_ROW = 6;

export function createSheet(sheet, store, tableName) {
    const { startMeasurements: start, finishMeasurements: finish } = store;
    const { bloodPressureStart, bloodPressureFinish } = createBloodPressureDataForSingleCop({ start, finish });

    sheet.mergeCells("A2:I2");

    writeToRow(sheet, [tableName], 2);
    writeToRow(
        sheet,
        [
            "",
            t("screenHealthAndFitnessData.excel.bloodPressure"),
            t("screenHealthAndFitnessData.excel.weight"),
            t("screenHealthAndFitnessData.excel.fatPercentage"),
            t("screenHealthAndFitnessData.excel.waist"),
            t("screenHealthAndFitnessData.excel.star"),
            t("screenHealthAndFitnessData.excel.squat"),
            t("screenHealthAndFitnessData.excel.pushups"),
            t("screenHealthAndFitnessData.excel.rowing"),
        ],
        3
    );
    writeToRow(sheet, [t("screenHealthAndFitnessData.data.start"), bloodPressureStart, start.weight, start.fatPercentage, start.waistCircumference, start.star, start.squat, start.pushups, start.rowing], 4);
    writeToRow(sheet, [t("screenHealthAndFitnessData.data.finish"), bloodPressureFinish, finish.weight, finish.fatPercentage, finish.waistCircumference, finish.star, finish.squat, finish.pushups, finish.rowing], 6);
    styleSheet(sheet, END_DATA_ROW, "A2:I2", true);
}
