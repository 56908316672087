import React from "react";
import { makeStyles } from "@mui/styles";
import { t } from "common/localization/translate";
import { CardActions } from "@mui/material";
import Grid from "@mui/material/Unstable_Grid2";
import { useScreenEditUserInfoStore } from "screens/support/editUserInfo/store";
import { CommonButton } from "common/ui/commonButton";
import { observer } from "mobx-react";

export const OtpSendButton = observer(props => {
    const classes = useStyles();
    const store = useScreenEditUserInfoStore();

    const { isShouldConfirmWithOtp, sendOtpCode, isOtpSendButtonVisible, isOtpSendButtonDisabled } = store;

    if (!isShouldConfirmWithOtp || !isOtpSendButtonVisible) {
        return null;
    }

    const handleClick = async event => {
        event.preventDefault();
        sendOtpCode();
    };

    return (
        <Grid item md={2} xs={12}>
            <CardActions className={classes.container}>
                <CommonButton onClick={handleClick} className={classes.button} disabled={isOtpSendButtonDisabled}>
                    {t("screenEditUserInfo.otpCode.sendButton")}
                </CommonButton>
            </CardActions>
        </Grid>
    );
});

const useStyles = makeStyles({
    container: {
        padding: 0,
        height: "100%",
        justifyContent: "end",
    },
    button: {
        minWidth: "167px",
    },
});
