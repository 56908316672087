import React from "react";
import SearchIcon from "@mui/icons-material/Search";
import { CommonButton } from "common/ui/commonButton";
import { useScreenConfirmationsUseresStore } from "../../../store/index";
import Grid from "@mui/material/Unstable_Grid2";
export const ButtonConfirmations = () => {
    const { isFetching } = useScreenConfirmationsUseresStore();
    return (
        <Grid item sm={4} lg={3}>
            <CommonButton disabled={isFetching}>
                <SearchIcon />
            </CommonButton>
        </Grid>
    );
};
