import React from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import { observer } from "mobx-react-lite";
import { useScreenSendNotificationStore } from "../store";
import { makeStyles } from "@mui/styles";
import { t } from "common/localization/translate";
import { getSendNotificationPopUpErrorMessageByErrorCode } from "./getSendNotificationPopUpErrorMessageByErrorCode";

const useStyles = makeStyles(theme => ({
    title: {
        backgroundColor: theme.palette.primary.main,
        color: theme.palette.white,
        fontSize: 16,
    },
    content: {
        color: "red",
    },
    button: {
        color: theme.palette.white,
        backgroundColor: theme.palette.primary.main,
        "&:hover": {
            backgroundColor: theme.palette.primary.secondGraphColor,
        },
    },
}));

export const SendNotificationPopUpErrorMessage = observer(() => {
    const store = useScreenSendNotificationStore();
    const classes = useStyles();

    const handleClose = () => {
        store.isShowSendNotificationPopUpErrorMessage = false;
    };

    const errorMessage = getSendNotificationPopUpErrorMessageByErrorCode(store.sendNotificationPopUpErrorMessageErrorCode);
    return (
        <Dialog open={store.sendNotificationPopUpErrorMessageErrorCode && store.isShowSendNotificationPopUpErrorMessage}>
            <DialogTitle className={classes.title}>{t("support.sendNotificationScreen.successSendNotificationTitlie")}</DialogTitle>
            <DialogContent>
                <DialogContentText className={classes.content}>{errorMessage}</DialogContentText>
            </DialogContent>
            <DialogActions>
                <Button onClick={handleClose} className={classes.button} autoFocus>
                    {t("support.sendNotificationScreen.sendNotificationPopUpErrorMessageButton")}
                </Button>
            </DialogActions>
        </Dialog>
    );
});
