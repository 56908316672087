import React from "react";
import { Close } from "@mui/icons-material";

export const ImageModal = ({ open, currentImages, currentIndex, onClose, onNext, onPrev, couponTitle }) => {
    if (!open) return null;

    const sliderImageBack = "https://d3mspq0y1r4vba.cloudfront.net/junior_fit/common/back_dashboard.png";
    const shouldShowSliderButtons = currentImages.length > 1;

    const styles = {
        modal: {
            position: "fixed",
            top: 0,
            left: 0,
            width: "100%",
            height: "100%",
            backgroundColor: "rgba(0, 0, 0, 0.8)",
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
            zIndex: 1000,
        },
        title: {
            color: "white",
            fontSize: "24px",
            fontWeight: "bold",
            marginBottom: "20px",
            textAlign: "center",
        },
        slider: {
            display: "flex",
            alignItems: "center",
            gap: "10px",
        },
        sliderImage: {
            width: "600px",
            height: "300px",
            objectFit: "cover",
            borderRadius: "62px",
        },
        arrowButton: {
            background: "none",
            border: "none",
            cursor: "pointer",
        },
        closeButton: {
            position: "absolute",
            top: "20px",
            right: "20px",
            background: "none",
            border: "none",
            color: "white",
            cursor: "pointer",
            fontSize: "24px",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
        },
        indicators: {
            position: "absolute",
            bottom: "220px",
            display: "flex",
            justifyContent: "center",
            width: "100%",
        },
        circle: {
            width: "10px",
            height: "10px",
            borderRadius: "50%",
            backgroundColor: "white",
            margin: "0 5px",
            opacity: 0.5,
        },
        activeCircle: {
            opacity: 1,
        },
    };

    const handleBackgroundClick = event => {
        if (event.target === event.currentTarget) {
            onClose();
        }
    };

    return (
        <div style={styles.modal} onClick={handleBackgroundClick}>
            <div style={styles.title}>{couponTitle}</div>
            <div style={styles.slider}>
                {shouldShowSliderButtons && (
                    <button onClick={onPrev} style={styles.arrowButton}>
                        <img src={sliderImageBack} alt="sliderImageBack" style={{ width: "35px", height: "35px", transform: "scaleX(-1)" }} />
                    </button>
                )}
                <img src={currentImages[currentIndex]} alt="Coupon" style={styles.sliderImage} />
                {shouldShowSliderButtons && (
                    <button onClick={onNext} style={styles.arrowButton}>
                        <img src={sliderImageBack} alt="sliderImageBack" style={{ width: "35px", height: "35px" }} />
                    </button>
                )}
            </div>
            <div style={styles.indicators}>
                {shouldShowSliderButtons &&
                    currentImages.map((_, index) => (
                        <div
                            key={index}
                            style={{
                                ...styles.circle,
                                ...(index === currentIndex ? styles.activeCircle : {}),
                            }}
                        />
                    ))}
            </div>
            <button onClick={onClose} style={styles.closeButton}>
                <Close />
            </button>
        </div>
    );
};
