import React from "react";
import { observer } from "mobx-react";
import { useScreenPurchaseFromSupportStore } from "../../../store";
import { CircularProgress } from "@mui/material";
import { AskMailBeforePurchaseCreditModal } from "../../modals/askMailBeforePurchaseCreditModal";

export const PurchaseWithCardInputs = observer(() => {
    const store = useScreenPurchaseFromSupportStore();
    const { isFetchingPaymentData, paymentUrl, shouldAskForEmailBeforeCreditPurchase } = store;

    if (shouldAskForEmailBeforeCreditPurchase) {
        return <AskMailBeforePurchaseCreditModal />;
    }

    return (
        <div style={{ position: "relative", width: "500px", height: "750px" }}>
            {isFetchingPaymentData && (
                <div
                    style={{
                        position: "absolute",
                        top: "50%",
                        left: "50%",
                        transform: "translate(-50%, -50%)",
                        zIndex: 1,
                    }}
                >
                    <CircularProgress />
                </div>
            )}
            <>
                <iframe src={paymentUrl} sandbox="allow-scripts allow-same-origin allow-forms allow-popups" title="Web View" width="500px" height="750px" style={{ border: "none" }} />
            </>
        </div>
    );
});
