import { alpha } from "@mui/material/styles";

export function getTopGradient({ canvas, theme }) {
    const context = canvas.chart.ctx;
    const topGradient = context.createLinearGradient(0, 0, 0, 450);
    topGradient.addColorStop(0, alpha(theme.palette.primary.main, 0));
    topGradient.addColorStop(0.6, alpha(theme.palette.primary.main, 0));
    topGradient.addColorStop(0, theme.palette.primary.main);
    return {
        target: "origin",
        above: topGradient,
    };
}
