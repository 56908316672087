import React from "react";
import { observer } from "mobx-react";
import { t } from "common/localization/translate";
import { makeStyles } from "@mui/styles";
import HelpOutlineIcon from "@mui/icons-material/HelpOutline";
import Tooltip from "@mui/material/Tooltip";
import { useScreenAddStepsStore } from "../store";

const useStyles = makeStyles(() => ({
    container: {
        marginTop: 10,
    },
    tooltip: {
        fontSize: 14,
    },
    toolTipIcon: {
        color: "black",
        marginBottom: 10,
        fontSize: "16px",
        marginLeft: -5,
    },
}));

export const DateInformationIcon = observer(() => {
    const store = useScreenAddStepsStore();
    const { daysBackToAddSteps, isPeriodDaily } = store;
    const classes = useStyles();

    if (!isPeriodDaily) {
        return "-";
    }

    return (
        <div className={classes.container}>
            <Tooltip title={t("screenAddSteps.addStepsContainer.tooltip", { daysBackToAddSteps })} classes={classes}>
                <HelpOutlineIcon className={classes.toolTipIcon} />
            </Tooltip>
        </div>
    );
});
