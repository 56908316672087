import React from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import { observer } from "mobx-react-lite";
import { makeStyles } from "@mui/styles";
import { t } from "common/localization/translate";

const useStyles = makeStyles(theme => ({
    title: {
        backgroundColor: theme.palette.primary.main,
        "& h6": {
            color: theme.palette.white,
            fontSize: 16,
            fontFamily: "Assistant",
            fontWeight: "bold",
        },
    },
}));

export const SuccessEnterCodesPopUp = observer(props => {
    const { store } = props;
    const { exceptionalCodesAmount, validCodesAmount, totalCodesLoaded } = store;

    const classes = useStyles();
    const handleClose = () => {
        store.isSuccessEnterCodes = false;
    };

    const exceptionalReport = exceptionalCodesAmount > 0 ? t("suppliers.supplierHome.exceptionalsReprt") : "";

    return (
        <div>
            <Dialog open={store.isSuccessEnterCodes}>
                <DialogTitle className={classes.title}>{t("suppliers.supplierHome.verifyCodesPopUp")}</DialogTitle>
                <DialogContent>
                    <DialogContentText>
                        {" "}
                        {t("suppliers.supplierHome.successLoadCodes", { exceptionalCodesAmount, validCodesAmount, totalCodesLoaded })}
                        <br></br>
                        {exceptionalReport}
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleClose} color="primary" autoFocus>
                        {t("suppliers.supplierHome.verifyCodesOkPopUp")}
                    </Button>
                </DialogActions>
            </Dialog>
        </div>
    );
});
