import React from "react";
import { TableRow, TableCell } from "@mui/material";

export const TermsConfirmations = props => {
    const { columns, confirmationsData } = props;

    return (
        <TableRow>
            <TableCell align="right">{columns.approvalPolicy}</TableCell>
            <TableCell align="right">{confirmationsData.termsConfirmationDate}</TableCell>
        </TableRow>
    );
};
