import React from "react";
import { makeStyles } from "@mui/styles";
import Grid from "@mui/material/Unstable_Grid2";
import { EmailSentSuccessfullyPopUp } from "./EmailSentSuccessfullyPopUp";
import { PricingImage } from "./pricingModal/pricingImage";

const useStyles = makeStyles(theme => ({
    root: {
        width: 2000,
        alignSelf: "center",
        justifyContent: "center",
    },
    content: {
        marginTop: theme.spacing(5),
        padding: theme.spacing(2),
        maxWidth: 1000,
        margin: "0 auto",
    },
}));

export const PricingModal = () => {
    const classes = useStyles();
    return (
        <div className={classes.root}>
            <EmailSentSuccessfullyPopUp />
            <div className={classes.content}>
                <Grid container spacing={8}>
                    <PricingImage />
                </Grid>
            </div>
        </div>
    );
};
