import React from "react";
import { Typography } from "@mui/material";
import Grid from "@mui/material/Unstable_Grid2";
import { observer } from "mobx-react";
import { useScreenSendNotificationStore } from "../store";
import { makeStyles } from "@mui/styles";
import { t } from "common/localization/translate";

const useStyles = makeStyles(theme => ({
    errorText: {
        fontSize: 10,
        color: "red",
    },
}));

export const LinkInputError = observer(() => {
    const store = useScreenSendNotificationStore();
    const classes = useStyles();

    if (!store.isEmptyLink) {
        return null;
    }

    return (
        <Grid item xs={12}>
            <Typography className={classes.errorText}>{t("support.sendNotificationScreen.emptyLink")}</Typography>
        </Grid>
    );
});
