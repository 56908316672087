import React from "react";
import { makeStyles } from "@mui/styles";
import { observer } from "mobx-react";
import { TextField } from "@mui/material";

const useStyles = makeStyles(() => ({
    input: { width: "45%" },
}));

export const InputBloodPressureForm = observer(({ label, subText, fieldName, onChange, value }) => {
    const classes = useStyles();
    return <TextField label={label} helperText={subText} name={fieldName} onChange={onChange} value={value} className={classes.input} variant="outlined" type="number" onWheel={event => event.target.blur()} />;
});
