import React from "react";
import clsx from "clsx";
import { makeStyles } from "@mui/styles";
import { t } from "common/localization/translate";
import { Typography } from "@mui/material";
import Grid from "@mui/material/Unstable_Grid2";
import { useScreenEditUserInfoStore } from "../../store";
import { observer } from "mobx-react";
import { useRootStore } from "commonStores/analytics/rootStoreContext";
import { segmentsPaths } from "commonStores/analytics/userInfoStore/logic/getSegmentPathById";

export const EntitlementStatus = observer(props => {
    const { userInfoStore } = useRootStore();
    const { currentSegment } = userInfoStore;
    const isClalitSegment = currentSegment && currentSegment.segmentId == segmentsPaths.CLALIT.id;
    if (!isClalitSegment) {
        return null;
    }
    const classes = useStyles();
    const store = useScreenEditUserInfoStore();
    const descriptionText = t("screenEditUserInfo.entitlementStatus");
    const entitlementStatusText = store.isEntitled ? t("screenEditUserInfo.entitled") : t("screenEditUserInfo.notEntitled");
    const entitlementStatusStyle = store.isEntitled ? classes.entitledText : classes.notEntitledText;
    return (
        <Grid item className={classes.container} lg={12} md={12}>
            <Typography className={classes.text}>{descriptionText}</Typography>
            <Typography className={clsx(entitlementStatusStyle, classes.text)}>{entitlementStatusText}</Typography>
        </Grid>
    );
});

const useStyles = makeStyles(theme => ({
    text: {
        fontSize: "14px",
        display: "inline-block",
        paddingLeft: 5,
    },
    notEntitledText: {
        color: "red",
    },
    entitledText: {
        color: "green",
    },
    container: {
        paddingBottom: 0,
        paddingTop: 0,
    },
}));
