import React from "react";
import { makeStyles } from "@mui/styles";
import { observer } from "mobx-react";
import PersonIcon from "@mui/icons-material/Person";
import GroupAddIcon from "@mui/icons-material/GroupAdd";
import AttachMoneyIcon from "@mui/icons-material/AttachMoney";
import { useRootStore } from "commonStores/analytics/rootStoreContext";
import Grid from "@mui/material/Unstable_Grid2";
import SimpleCube from "common/ui/simpleCube";
import { t } from "common/localization/translate";
import { useScreenHomeStore } from "../store";
import { formatNumber } from "common/formatNumber";
import { TodaysGainedCoinsLabel } from "./todaysGainedCoinsLabel";
import { MonthlyImprovementPercentageCube } from "./monthlyImprovementPercentageCube";
import { TotalUsersToDemandPayment } from "./totalUsersToDemandPayment";
import { WeeklyImprovementPercentageCube } from "./weeklyImprovementPercentageCube";

const useStyles = makeStyles(theme => ({
    row: {
        width: "100%",
        paddingLeft: 10,
    },
    reversedIcon: {
        color: theme.palette.primary.main,
    },
    img: {
        height: 35,
    },
}));

export const GeneralTotalNumbersCubesRow = observer(() => {
    const rootStore = useRootStore();
    const { totalCurrentSegmentMembers, sumOfCoinsOfCurrentSegmentUsers, lastWeekNewUsers } = useScreenHomeStore();
    const totalCurrentSegmentMembersFormatted = formatNumber(totalCurrentSegmentMembers);
    const sumOfCoinsOfCurrentSegmentUsersFormatted = formatNumber(sumOfCoinsOfCurrentSegmentUsers);
    const lastWeekNewUsersFormatted = formatNumber(lastWeekNewUsers);

    const classes = useStyles();
    const store = useScreenHomeStore();
    const { selectedSegmentName } = store;
    const { segmentCoinImage } = rootStore.userInfoStore;
    const coinIconToShow = segmentCoinImage ? <img src={segmentCoinImage} alt="coin" className={classes.img} /> : <AttachMoneyIcon />;
    return (
        <Grid container style={{ width: "100%" }}>
            <MonthlyImprovementPercentageCube />
            <WeeklyImprovementPercentageCube />
            <Grid item xs={12} md={6} lg className={classes.row}>
                <SimpleCube
                    label={t("screenHome.totalNumbersCubesRow.membersCount", {
                        selectedSegmentName,
                    })}
                    value={totalCurrentSegmentMembersFormatted}
                    icon={<PersonIcon />}
                    withoutMargin={true}
                />
            </Grid>
            <Grid item xs={12} md={6} lg className={classes.row}>
                <SimpleCube
                    label={t("screenHome.totalNumbersCubesRow.thisWeekNewMembersCount", {
                        selectedSegmentName,
                    })}
                    value={lastWeekNewUsersFormatted}
                    icon={<GroupAddIcon />}
                    withoutMargin={true}
                />
            </Grid>
            <TotalUsersToDemandPayment />
            <Grid item xs={12} md={6} lg className={classes.row} style={{ minWidth: 260 }}>
                <SimpleCube
                    isWithoutMargin={true}
                    label={t("screenHome.totalNumbersCubesRow.totalCoinsSum", {
                        selectedSegmentName,
                    })}
                    sideComponent={<TodaysGainedCoinsLabel />}
                    value={sumOfCoinsOfCurrentSegmentUsersFormatted}
                    icon={coinIconToShow}
                    withoutMargin={true}
                />
            </Grid>
        </Grid>
    );
});
