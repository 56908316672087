import React from "react";
import { TextField } from "@mui/material";
import { observer } from "mobx-react";
import { makeStyles } from "@mui/styles";
import { useScreenPurchaseFromSupportStore } from "../../../store";
import { t } from "../../../../../../common/localization/translate";

const useStyles = makeStyles(() => ({
    container: {
        marginRight: "16px",
    },
    input: {
        width: "95px",
        "& .MuiOutlinedInput-root": {
            borderRadius: "10px",
        },
    },
}));

export const ApartmentInput = observer(() => {
    const store = useScreenPurchaseFromSupportStore();
    const inputProps = { maxLength: 10 };
    const classes = useStyles();

    const handleInputChangeApartment = event => {
        const { value } = event.target;
        store.apartment = value;
    };

    return (
        <div className={classes.container}>
            <TextField value={store.apartment} className={classes.input} id="apartment-input" variant="outlined" type="string" label={t("screenPurchaseFromSupport.coupons.deliveryModal.apartment")} inputProps={inputProps} onChange={handleInputChangeApartment} />
        </div>
    );
});
