import { httpCall } from "common/httpCall";

export async function getScreenData(segmentId, startDate, endDate) {
    const data = await httpCall("organization/couponsInInventory/getDataCouponsInInventory", {
        segmentId,
        startDate,
        endDate,
    });
    return data;
}
