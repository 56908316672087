import React from "react";
import { Typography } from "@mui/material";
import Grid from "@mui/material/Unstable_Grid2";
import { observer } from "mobx-react";
import { useScreenHomeStore } from "../../../store";
import { makeStyles } from "@mui/styles";

const useStyles = makeStyles(() => ({
    errorText: {
        fontSize: 10,
        color: "red",
    },
}));

export const LoadingCodesInputErrors = observer(() => {
    const store = useScreenHomeStore();
    const classes = useStyles();

    return (
        <Grid item xs={12}>
            <Typography className={classes.errorText}>{store.verifyCodesErrorText}</Typography>
        </Grid>
    );
});
