import React from "react";
import { makeStyles } from "@mui/styles";
import CardActions from "@mui/material/CardActions";
import Button from "@mui/material/Button";
import { useScreenPricingStore } from "../../store/index";
import { t } from "common/localization/translate";

const useStyles = makeStyles(theme => ({
    button: {
        backgroundColor: theme.palette.primary.main,
        color: theme.palette.white,
        borderRadius: 30,
        width: 250,
        height: 50,
        marginLeft: 350,
        fontWeight: "bold",
        fontSize: 18,
        fontFamily: "Assistant",
    },
}));

export const Actions = () => {
    const classes = useStyles();
    const store = useScreenPricingStore();
    const { sendEmailToSalesAboutPurchasingApackage } = store;

    const onSubmit = () => {
        sendEmailToSalesAboutPurchasingApackage();
    };

    return (
        <CardActions>
            <Button className={classes.button} onClick={onSubmit}>
                {t("businees.pricing.wantPackage")}
            </Button>
        </CardActions>
    );
};
