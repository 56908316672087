import React, { useEffect } from "react";
import { InputAdornment, TextField } from "@mui/material";
import { observer } from "mobx-react";
import { makeStyles } from "@mui/styles";
import { useScreenPurchaseFromSupportStore } from "../../../store";
import { t } from "../../../../../../common/localization/translate";
import { PickupPointsList } from "./pickupPointsList";
import { PickupPointsInputArrow } from "./pickupPointsInputArrow";
import { PickupPointsInputPlaceholder } from "./pickupPointsInputPlaceholder";

const useStyles = makeStyles(() => ({
    container: {
        marginRight: "16px",
    },
    input: {
        width: "205px",
        "& .MuiOutlinedInput-root": {
            borderRadius: "10px",
        },

        pointerEvents: "none",
    },
    clickableDiv: {
        cursor: "pointer",
    },
}));

export const PickupPointsInput = observer(() => {
    const store = useScreenPurchaseFromSupportStore();
    const classes = useStyles();

    const handleInputClick = () => {
        store.isOpenedPickupPoints = !store.isOpenedPickupPoints;
    };

    useEffect(() => {
        const sessionToken = `${new Date().getTime()}-${store.externalUserId}`;
        store.sessionTokenForSearch = sessionToken;
    }, []);

    const error = store.pickupErrorMessage;
    return (
        <div className={classes.container}>
            <div className={classes.clickableDiv} onClick={handleInputClick}>
                <PickupPointsInputPlaceholder />
                <TextField
                    value={"\u200B"} //invisible symbol
                    className={classes.input}
                    id="standard-basic"
                    type="string"
                    error={error}
                    helperText={error}
                    variant="outlined"
                    label={t("screenPurchaseFromSupport.coupons.deliveryModal.pickup.collectionPoints.label")}
                    onClick={handleInputClick}
                    InputProps={{
                        endAdornment: <InputAdornment position="end">{<PickupPointsInputArrow />}</InputAdornment>,
                    }}
                />
            </div>
            <PickupPointsList />
        </div>
    );
});
