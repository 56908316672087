import React from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import { makeStyles } from "@mui/styles";
import { useScreenConfirmHideCouponsStore } from "../../store/index";
import { observer } from "mobx-react-lite";
import { t } from "common/localization/translate";

const useStyles = makeStyles(theme => ({
    root: {
        color: theme.palette.white,
        fontSize: 14,
        backgroundColor: theme.palette.primary.main,
        "& h6": {
            color: theme.palette.white,
            fontSize: 14,
        },
    },
    content: { marginTop: 8 },
    dialogDiv: {
        width: 600,
        height: 250,
    },
    buttons: {
        backgroundColor: theme.palette.primary.main,
        fontSize: 14,
        color: theme.palette.white,
        marginLeft: 21,
        marginTop: 18,
        "&:hover": {
            backgroundColor: theme.palette.primary.secondGraphColor,
            color: theme.palette.white,
        },
    },
    buttonsContainer: {
        display: "flex",
        justifyContent: "center",
    },
}));

export const SuccessCancelCouponPopUp = observer(() => {
    const store = useScreenConfirmHideCouponsStore();
    const { isSuccessCancelCoupon, setIsSuccessCancelCoupon } = store;
    const classes = useStyles();
    const handleClose = () => {
        setIsSuccessCancelCoupon(false);
    };

    return (
        <div>
            <Dialog className={classes.dialog} open={isSuccessCancelCoupon} aria-labelledby="responsive-dialog-title">
                <div className={classes.dialogDiv}>
                    <DialogTitle className={classes.root} id="responsive-dialog-title">
                        {t("screenCouponsUser.cancelCoupon")}
                    </DialogTitle>
                    <DialogContent className={classes.content}>
                        <DialogContentText>
                            {t("screenCouponsUser.popUp.cancelCouponSuccess")}
                            <br />
                            {t("screenCouponsUser.popUp.cancelCouponSuccessMessege")}
                        </DialogContentText>
                    </DialogContent>
                    <DialogActions className={classes.buttonsContainer}>
                        <Button className={classes.buttons} size="small" type="submit" variant="contained" onClick={handleClose}>
                            {t("screenCouponsUser.popUp.closeButton")}
                        </Button>
                    </DialogActions>
                </div>
            </Dialog>
        </div>
    );
});
