import React from "react";
import { t } from "common/localization/translate";
import { TableRow, TableCell, Box } from "@mui/material";
import { makeStyles } from "@mui/styles";
const useStyles = makeStyles(theme => ({
    iconV: {
        fontSize: "21px",
        display: "inline",
    },
}));

export const MailingConfirmationsWithDates = props => {
    const { columns, item } = props;
    const classes = useStyles();
    return (
        <TableRow>
            <TableCell align="right">{columns.approvalMailingDate}</TableCell>
            <TableCell align="right">
                {item.approval === "true" ? <Box className={classes.iconV}>✔</Box> : "❌"}
                &nbsp; {t("screenConfirmationUser.MaillingConfirmations.startDate")} - {item.dateMailingChange}{" "}
            </TableCell>
        </TableRow>
    );
};
