import React, { useState } from "react";
import { makeStyles } from "@mui/styles";
import Grid from "@mui/material/Unstable_Grid2";
import { Page } from "components";
import { observer } from "mobx-react";
import { useRootStore } from "commonStores/analytics/rootStoreContext";
import { createScreenPricingStore, ScreenPricingStoreProvider } from "./store";
import { t } from "common/localization/translate";
import { PricingModal } from "./components/PricingModal";

const useStyles = makeStyles(theme => ({
    root: {
        padding: theme.spacing(3),
        width: "100%",
        height: "100%",
    },
    container: {
        "& > *": {
            height: "100%",
            padding: theme.spacing(3),
        },
        row: {
            height: "100%",
        },
    },
}));
export const Pricing = observer(() => {
    const rootStore = useRootStore();
    const [store] = useState(() => createScreenPricingStore(rootStore));
    const classes = useStyles();

    return (
        <ScreenPricingStoreProvider value={store}>
            <Page className={classes.root} title={t("business.home.title")}>
                <Grid justifyContent="center" className={classes.container} container spacing={3}>
                    <PricingModal />
                </Grid>
            </Page>
        </ScreenPricingStoreProvider>
    );
});
