import React from "react";
import PerfectScrollbar from "react-perfect-scrollbar";
import { makeStyles } from "@mui/styles";
import { observer } from "mobx-react";
import { Card, CardHeader, CardContent, Divider } from "@mui/material";
import WaveChart from "common/ui/waveChart";
import { t } from "common/localization/translate";
import ChangePeriodDropDown from "./components/changePeriodDropDown";
import { useScreenHomeStore } from "../../store";
import { LoadingSpinner } from "common/ui/loadingSpinner";

const useStyles = makeStyles(theme => ({
    root: {
        marginTop: theme.spacing(1),
        height: 500,
    },
    header: { flexDirection: "row", display: "flex" },
    buttons: {
        display: "flex",
        justifyContent: "center",
        "& > *": {
            marginLeft: theme.spacing(1),
        },
    },
    inner: {
        height: 375,
        minWidth: 500,
    },
    chart: {
        height: "100%",
    },
    avatar: {
        backgroundColor: theme.palette.primary.main,
        height: 48,
        width: 48,
        display: "flex",
        justifyContent: "center",
    },
}));

const ActiveUsersChart = observer(() => {
    const classes = useStyles();
    const { activeUsersCountGraphData, isFetchingForSmallSpinner: isFetching } = useScreenHomeStore();
    if (!activeUsersCountGraphData) {
        return null;
    }
    const counts = activeUsersCountGraphData.map(item => item.count);
    const labels = activeUsersCountGraphData.map(item => item.date);
    const data = {
        data: counts,
        labels,
    };

    return (
        <Card className={classes.root}>
            <CardHeader className={classes.header} action={<ChangePeriodDropDown />} title={t("screenHome.activeUsers.title")} />
            <Divider />
            <CardContent>
                <PerfectScrollbar>
                    <div className={classes.inner}>
                        <LoadingSpinner isFetching={isFetching} className={classes.chart}>
                            <WaveChart className={classes.chart} data={data.data} labels={data.labels} linesData={[{ dataArray: data.data, textLabel: t("screenHome.activeUsersChart.labelValueType") }]} />
                        </LoadingSpinner>
                    </div>
                </PerfectScrollbar>
            </CardContent>
        </Card>
    );
});

export default ActiveUsersChart;
