import React from "react";
import { makeObservable, observable } from "mobx";
import { getHomeScreenDataServer } from "./server/getHomeScreenDataServer";
import { sendCouponCodes } from "./server/sendCouponCodes";
import { sendMailToGetOtpCodeServer } from "./server/sendMailToGetOtpCode";
import { sendMonthlyReportToProjectManagerServer } from "./server/sendMonthlyReportToProjectManagerServer";
import { updateDateCompletionLoadingCodes } from "./server/updateDateCompletionLoadingCodes";
import { validateOtpCodeServer } from "./server/validateOtpCodeServer";
import { getVerifyCouponCodesErrorText } from "./logic/getVerifyCouponCodesErrorText";
import { t } from "common/localization/translate";
import moment from "moment";
import { getSendOtpCodeToProjectManagerErrorText } from "./logic/getSendOtpCodeToProjectManagerErrorText";
import { getValidateOtpCodeErrorTextByErrorText } from "./logic/getValidateOtpCodeErrorTextByErrorText";
import { sendMonthlySummaryCouponsReportToProjectManagerServer } from "./server/sendMonthlySummaryCouponsReportToProjectManagerServer";
import { getProjectManagerHomeScreenData } from "./server/getProjectManagerHomeScreenData";

const ScreenHomeStoreContext = React.createContext({});
export const ScreenHomeStoreProvider = ScreenHomeStoreContext.Provider;
export const useScreenHomeStore = () => React.useContext(ScreenHomeStoreContext);

class ScreenHomeStore {
    constructor(rootStore) {
        this.rootStore = rootStore;
        this.initProperties();
        makeObservable(this, {
            isFetching: observable,
            isFetchingProjectManagerData: observable,
            codes: observable,
            month: observable,
            refund: observable,
            isValidOtpCode: observable,
            couponTypeId: observable,
            selectedDate: observable,
            changeMonthPopUp: observable,
            changeMonthDecision: observable,
            isSuccessEnterCodes: observable,
            couponTypes: observable,
            couponTypeGroupId: observable,
            couponTitle: observable,
            chosenCouponType: observable,
            chosenSupplier: observable,
            isSupplier: observable,
            isFinishEnteringCodes: observable,
            isSuccessSendOtpCodeToProjectManager: observable,
            isSuccessSentMonthlyReport: observable,
            isProjectManager: observable,
            segment: observable,
            otpCode: observable,
            validateOtpCodeText: observable,
            sendOtpToProjectManagerErrorText: observable,
            verifyCodesErrorText: observable,
            totalCodesLoaded: observable,
            validCodesAmount: observable,
            exceptionalCodesAmount: observable,
            isFetchingLoadingCodes: observable,
            isFetchingFinishLoadingCodes: observable,
            isFetchingManagerReport: observable,
            projectManagerEmail: observable,
            otpPolicyPopUp: observable,
            errorReportFaildText: observable,
            isAreYouSureFinishLoadCodesPopUp: observable,
            selectedMonthCouponsSummaryReport: observable,
            errorCouponsSummaryReportFaildText: observable,
            isFetchingCouponsSummaryManagerReport: observable,
            isSuccessSentSummaryCouponsMonthlyReport: observable,
            totalUsersToDemandPayment: observable,
            unvalidatedPurchases: observable,
            validatedPurchases: observable,
            thisMonthData: observable,
            lastMonthData: observable,
            twoMonthsAgoData: observable,
            projectManagerSupplierData: observable,
            allTimeData: observable,
        });
    }

    initProperties() {
        this.isFetching = false;
        this.isFetchingProjectManagerData = false;
        this.codes = null;
        this.month = null;
        this.refund = 0;
        this.isValidOtpCode = false;
        this.couponTypeId = null;
        this.selectedDate = null;
        this.changeMonthPopUp = false;
        this.changeMonthDecision = false;
        this.isSuccessEnterCodes = false;
        this.couponTypes = [];
        this.couponTypeGroupId = null;
        this.couponTitle = null;
        this.chosenCouponType = null;
        this.chosenSupplier = null;
        this.isSupplier = false;
        this.isFinishEnteringCodes = false;
        this.isSuccessSendOtpCodeToProjectManager = false;
        this.isSuccessSentMonthlyReport = false;
        this.isProjectManager = false;
        this.segment = null;
        this.otpCode = null;
        this.validateOtpCodeText = null;
        this.sendOtpToProjectManagerErrorText = null;
        this.verifyCodesErrorText = null;
        this.totalCodesLoaded = 0;
        this.validCodesAmount = 0;
        this.exceptionalCodesAmount = 0;
        this.isFetchingLoadingCodes = false;
        this.isFetchingFinishLoadingCodes = false;
        this.isFetchingManagerReport = false;
        this.projectManagerEmail = null;
        this.otpPolicyPopUp = false;
        this.errorReportFaildText = null;
        this.isAreYouSureFinishLoadCodesPopUp = false;
        this.selectedMonthCouponsSummaryReport = null;
        this.errorCouponsSummaryReportFaildText = null;
        this.isFetchingCouponsSummaryManagerReport = false;
        this.isSuccessSentSummaryCouponsMonthlyReport = false;
        this.totalUsersToDemandPayment = 0;
        this.unvalidatedPurchases = [];
        this.validatedPurchases = [];
        this.thisMonthData = null;
        this.lastMonthData = null;
        this.twoMonthsAgoData = null;
        this.projectManagerSupplierData = null;
        this.allTimeData = null;
    }

    getScreenData = async () => {
        const data = await getHomeScreenDataServer();
        if (data.extra.isProjectManager) {
            this.isFetchingProjectManagerData = true;
            this.suppliersList = data.extra.suppliers;
            this.chosenSupplier = data.extra.suppliers[0].supplier_id;
            this.isProjectManager = data.extra.isProjectManager;
            this.segment = data.extra.isProjectManager;
            this.projectManagerSupplierData = await getProjectManagerHomeScreenData(this.segment);
            this.setStoreFromProjectManagerSupplierData();
            return;
        }
        this.couponTypes = data.extra.couponTypes;
        this.chosenCouponType = data.extra.couponTypes[0].couponTypeId;
        this.couponTypeGroupId = data.extra.couponTypes[0].groupId;
        this.couponTitle = data.extra.couponTypes[0].couponTitle;
        this.isSupplier = true;
    };

    sendMailToGetOtpCode = async () => {
        this.isValidOtpCode = false;
        this.month = this.month || moment().subtract(1, "months").toDate();
        const data = await sendMailToGetOtpCodeServer(this.month);
        if (data.isSuccess) {
            this.isSuccessSendOtpCodeToProjectManager = true;
        }

        this.sendOtpToProjectManagerErrorText = getSendOtpCodeToProjectManagerErrorText(data.errorCode);
    };

    sendMonthlyReportToProjectManager = async () => {
        try {
            this.errorReportFaildText = null;
            this.isFetchingManagerReport = true;
            this.month = this.month || moment().subtract(1, "months").toDate();
            const data = await sendMonthlyReportToProjectManagerServer(this.month, this.chosenSupplier);
            this.isSuccessSentMonthlyReport = data.isSuccess;
            this.projectManagerEmail = data.extra.projectManagerEmail;
            this.isFetchingManagerReport = false;
        } catch (error) {
            this.errorReportFaildText = t("suppliers.projectManagerHome.generalErrorSendReport");
            this.isFetchingManagerReport = false;
        }
    };

    sendMonthlySummaryCouponsSuppliersReportToProjectManager = async () => {
        try {
            this.errorCouponsSummaryReportFaildText = null;
            this.isFetchingCouponsSummaryManagerReport = true;
            this.selectedMonthCouponsSummaryReport = this.selectedMonthCouponsSummaryReport || moment().subtract(1, "months").toDate();
            const data = await sendMonthlySummaryCouponsReportToProjectManagerServer(this.selectedMonthCouponsSummaryReport);
            this.isSuccessSentSummaryCouponsMonthlyReport = data.isSuccess;
            this.projectManagerEmail = data.extra.projectManagerEmail;
            this.isFetchingCouponsSummaryManagerReport = false;
        } catch (error) {
            this.errorCouponsSummaryReportFaildText = t("suppliers.projectManagerHome.generalErrorSendReport");
            this.isFetchingCouponsSummaryManagerReport = false;
        }
    };

    finishEnteringCodes = async () => {
        this.isFetchingFinishLoadingCodes = true;
        const data = await updateDateCompletionLoadingCodes();
        if (data.isSuccess) {
            this.isFinishEnteringCodes = data.isSuccess;
        }
        this.isFetchingFinishLoadingCodes = false;
    };

    validateOtpCode = async () => {
        this.validateOtpCodeText = null;
        this.month = this.month || moment().subtract(1, "months").toDate();
        const data = await validateOtpCodeServer(this.otpCode, this.month);
        if (data.isSuccess) {
            this.isValidOtpCode = data.isSuccess;
            this.otpPolicyPopUp = true;
        }

        this.validateOtpCodeText = getValidateOtpCodeErrorTextByErrorText(data.errorCode);
    };

    sendCouponCodes = async () => {
        this.isFetchingLoadingCodes = true;
        this.verifyCodesErrorText = null;
        this.month = this.month || moment().subtract(1, "months").toDate();
        this.refund = this.refund || 0;
        const data = await sendCouponCodes({ codes: this.codes, date: this.month, refund: this.refund, couponTypeGroupId: this.couponTypeGroupId, couponTitle: this.couponTitle });
        if (data.isSuccess) {
            this.totalCodesLoaded = data.extra.totalCodesLoaded;
            this.validCodesAmount = data.extra.validCodesAmount || 0;
            this.exceptionalCodesAmount = data.extra.exceptionalCodesAmount || 0;
            this.isSuccessEnterCodes = data.isSuccess;
        }
        this.codes = null;
        document.getElementById("codes").value = null;
        this.verifyCodesErrorText = getVerifyCouponCodesErrorText(data.errorCode);
        this.isFetchingLoadingCodes = false;
    };

    setStoreFromProjectManagerSupplierData() {
        this.thisMonthData = this.projectManagerSupplierData.thisMonth;
        this.lastMonthData = this.projectManagerSupplierData.lastMonth;
        this.twoMonthsAgoData = this.projectManagerSupplierData.twoMonthsAgo;
        this.allTimeData = this.projectManagerSupplierData.allTime;
        this.isFetchingProjectManagerData = false;
    }
}

export function createScreenHomeStore(rootStore) {
    const store = new ScreenHomeStore(rootStore);
    return store;
}
