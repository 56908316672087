import { t } from "common/localization/translate";
import { writeToRow } from "../../../../organization/home/store/logic/createExcel/writeToRow";

export function createSheetUserBalance({ sheetUserBalance, userBalanceTableData }) {
    const col1 = t("screenUserBalance.table.col.date");
    const col2 = t("screenUserBalance.table.col.business");
    const col3 = t("screenUserBalance.table.col.cost");
    const col4 = t("screenUserBalance.table.col.total");

    writeToRow(sheetUserBalance, [col1, col2, col3, col4], 1);
    let rowNumber = 2;
    userBalanceTableData.forEach(row => {
        writeToRow(sheetUserBalance, row, rowNumber);
        rowNumber++;
    });
}
