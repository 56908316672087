import findKey from "lodash/findKey";

export const segmentsPaths = {
    OVDIM_BARI: {
        id: 17,
        name: "עובדים בריא",
        path: "ovdimbari",
    },
    ATID_BARI: {
        id: 18,
        name: "עתיד בריא",
        path: "atidbari",
    },
    CLALIT: {
        id: 24,
        name: "כללית",
        path: "clalit",
    },
    MEUHEDET: {
        id: 63,
        name: "מאוחדת",
        path: "meuhedet",
    },
    MEUHEDET_KIDS: {
        id: 71,
        name: "מאוחדת ילדום",
        path: "meuhedetkids",
    },
    ELECTRA_FM: {
        id: 28,
        name: "FM אלקטרה",
        path: "electra",
    },
    ELECTRA: {
        id: 32,
        name: "אלקטרה",
        path: "electraorg",
    },
    MAOF: {
        id: 36,
        name: `מעו"ף לעמית`,
        path: "maof",
    },
    NOF_HAGALIL: {
        id: 43,
        name: `נוף הגליל`,
        path: "nofhagalil",
    },
    POLICE: {
        id: 46,
        name: "משטרת ישראל",
        path: "police",
    },
    FINANCE_MINISTRY: {
        id: 56,
        name: "משרד הכלכלה",
        path: "ministryOfFinance",
    },
    AMI_TECHNOLOGIES: {
        id: 54,
        name: "AMI Technologies",
        path: "ami",
    },
    SAVION: {
        id: 58,
        name: "Savion",
        path: "savion",
    },
    INTEL: {
        id: 59,
        name: "Intel",
        path: "intel",
    },
    SHEBA: {
        id: 62,
        name: "שיבא",
        path: "sheba",
    },
    YUVITAL: {
        id: 66,
        name: "יוביטל",
        path: "yuvital",
    },
    EL_ROBLE: {
        id: 69,
        name: "elRoble",
        path: "elroble",
    },
    YUVITAL_HEALTH_DEMO: {
        id: 70,
        name: "yuvitalHealthDemo",
        path: "yuvitalHealthDemo",
    },
    LEUMIT: {
        id: 64,
        name: "לאומית",
        path: "leumit",
    },
    SHEBA_LONGEVITY: {
        id: 80,
        name: "Sheba Longevity",
        path: "shebalongevity",
    },
    WOW_JUNIOR_A: {
        id: 81,
        name: "WOW גיוניור",
        path: "wowjunior",
    },
    WOW_JUNIOR_B: {
        id: 82,
        name: "WOW גיוניור",
        path: "wowjunior",
    },
    WOW_JUNIOR_C: {
        id: 83,
        name: "WOW גיוניור",
        path: "wowjunior",
    },
};

export function getSegmentPathById(id) {
    if (!id) {
        return;
    }
    const segmentId = typeof id === "object" ? id.mainSegmentId : id;
    const segmentKey = findKey(segmentsPaths, item => item.id == segmentId);
    return segmentKey ? segmentsPaths[segmentKey].path : segmentsPaths.OVDIM_BARI.path; // default ovdim bari
}
