import { t } from "../../../../../common/localization/translate";

export function handleOtpErrorCode(store, errorCode) {
    switch (errorCode) {
        case otpCodeErrorCodes.RATE_LIMITER_EXCEEDED:
            store.errorOtpText = t("screenPurchaseFromSupport.coupons.purchaseModal.otp.rateLimiterExceeded");
            return;
        case otpCodeErrorCodes.INVALID_CODE:
            store.errorOtpText = t("screenPurchaseFromSupport.coupons.purchaseModal.otp.invalidCode");
            return;
        case otpCodeErrorCodes.INVALID_PHONE_NUMBER:
            store.errorOtpText = t("screenPurchaseFromSupport.coupons.purchaseModal.otp.invalidPhone");
            return;
        default:
            store.errorOtpText = t("screenPurchaseFromSupport.coupons.purchaseModal.otp.generalError");
            return;
    }
}

const otpCodeErrorCodes = {
    RATE_LIMITER_EXCEEDED: "rateLimiterExceeded",
    INVALID_CODE: "invalid code",
    INVALID_PHONE_NUMBER: "invalidPhoneNumber",
};
