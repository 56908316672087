import { GenericForm } from "common/genericForm/genericForm";
import { t } from "common/localization/translate";
import { observer } from "mobx-react";
import { useScreenLoginStore } from "screens/loginV2/store";

export const EmailAndPassword = observer(() => {
    const store = useScreenLoginStore();

    const onEmailChange = e => {
        store.email = e.target.value;
    };

    const onPasswordChange = e => {
        store.password = e.target.value;
    };

    return (
        <GenericForm
            title={t("login.title", null, store.language)}
            subTitle={t("login.enterDetails", null, store.language)}
            field1Label={t("login.email", null, store.language)}
            field2Label={t("login.password", null, store.language)}
            onField1Change={onEmailChange}
            onField2Change={onPasswordChange}
            field1Value={store.email}
            field2Value={store.password}
            submitButtonLabel={t("login.button", null, store.language)}
            errorText={store.errorText}
            onSubmit={store.onSubmitEmailAndPassword}
            buttonWithUnderlineText={t("login.mailSuccessResetPassword.title", null, store.language)}
            buttonWithUnderlineOnClick={store.resetPassword}
            disableResetButton={store.disableResetButton}
            isLoading={store.isLoading}
        />
    );
});
