export function validateStreet(street) {
    if (!street) {
        return false;
    }
    if (typeof street !== "string" || street.length < 2) {
        return false;
    }
    if (!street.trim()) {
        return false;
    }
    return true;
}
