import React from "react";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import { makeStyles } from "@mui/styles";
import { useScreenConfirmHideCouponsStore } from "../../store/index";
import { observer } from "mobx-react-lite";
import { t } from "common/localization/translate";
import { CommonButton } from "common/ui/commonButton";

const useStyles = makeStyles(theme => ({
    root: {
        color: theme.palette.white,
        fontSize: 14,
        backgroundColor: theme.palette.primary.main,
        "& h6": {
            color: theme.palette.white,
            fontSize: 14,
        },
    },
    content: { marginTop: 8 },
    buttonPrimary: {
        fontSize: 14,
        marginLeft: 21,
        marginTop: 18,
        color: theme.palette.white,
        backgroundColor: theme.palette.primary.main,
        "&:hover": {
            color: theme.palette.white,
            backgroundColor: theme.palette.primary.secondGraphColor,
        },
    },
    buttonSecondary: {
        fontSize: 14,
        marginLeft: 21,
        marginTop: 18,
        backgroundColor: theme.palette.white,
        color: theme.palette.primary.main,
        "&:hover": {
            color: theme.palette.white,
            backgroundColor: theme.palette.primary.secondGraphColor,
        },
    },
    dialog: {
        width: 500,
        minHeight: 180,
    },
    buttonsContainer: {
        display: "flex",
        justifyContent: "center",
    },
}));

export const ConfirmHideCouponPopUp = observer(() => {
    const store = useScreenConfirmHideCouponsStore();
    const { isDeleteCouponPopUpVisible, couponName, setIsOpenDeleteCouponPopUp, deleteCoupon } = store;
    const classes = useStyles();

    const handleClickClose = () => {
        setIsOpenDeleteCouponPopUp(false);
    };

    const deleteSelectedCoupon = () => {
        deleteCoupon();
    };

    return (
        <div>
            <Dialog open={isDeleteCouponPopUpVisible} onClose={handleClickClose} aria-labelledby="responsive-dialog-title">
                <div className={classes.dialog}>
                    <DialogTitle className={classes.root} id="responsive-dialog-title">
                        {t("screenCouponsUser.cancelCoupon")}
                    </DialogTitle>
                    <DialogContent className={classes.content}>
                        <DialogContentText>{t("screenCouponsUser.messegeBeforeCancelCoupon", { couponName })}</DialogContentText>
                    </DialogContent>
                    <DialogActions className={classes.buttonsContainer}>
                        <CommonButton className={classes.buttonPrimary} onClick={deleteSelectedCoupon}>
                            {t("screenCouponsUser.approveCancelation")}
                        </CommonButton>
                        <CommonButton className={classes.buttonSecondary} onClick={handleClickClose} color="primary" autoFocus>
                            {t("screenCouponsUser.rejectCancelation")}
                        </CommonButton>
                    </DialogActions>
                </div>
            </Dialog>
        </div>
    );
});
