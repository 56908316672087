import { httpCall } from "common/httpCall";

export async function purchaseCouponServer({ userIdNum, segmentId, couponTypeId, extraForPurchase, additionalEmail, extraAfterPurchase }) {
    const data = await httpCall("support/coupons/purchaseFromSupport", {
        userIdNum,
        segmentId,
        couponTypeId,
        extraForPurchase,
        additionalEmail,
        extraAfterPurchase,
    });
    return data;
}
