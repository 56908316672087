import React from "react";
import { Typography } from "@mui/material";
import Grid from "@mui/material/Unstable_Grid2";
import HelpIcon from "@mui/icons-material/Help";
import Tooltip from "@mui/material/Tooltip";
import { t } from "common/localization/translate";
import { makeStyles } from "@mui/styles";

const useStyles = makeStyles(theme => ({
    toolTipIcon: {
        color: theme.palette.primary.main,
        marginLeft: 15,
        fontSize: "25px",
    },
    toolTipGrid: {
        marginRight: "auto",
    },
    headerStyle: {
        display: "flex",
        flexDirection: "row",
    },
    title: {
        fontSize: "25px",
        lineHeight: "18px",
        marginTop: 2,
        [theme.breakpoints.down("sm")]: {
            fontSize: "22px",
        },
    },
}));

export const Titles = () => {
    const classes = useStyles();
    return (
        <Grid container lg={12} sm={12} className={classes.headerStyle}>
            <Typography className={classes.title} component="h2" gutterBottom variant="overline">
                {t("screenHome.hideCoupon.title")}
            </Typography>
            <Grid className={classes.toolTipGrid}>
                <Tooltip title={t("organizationDashboard.hideCoupon.tooltip")}>
                    <HelpIcon className={classes.toolTipIcon} />
                </Tooltip>
            </Grid>
        </Grid>
    );
};
