import React, { useState } from "react";
import { observer } from "mobx-react";
import { t } from "common/localization/translate";
import { useScreenAddStepsStore } from "../store";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFnsV3";
import moment from "moment";
import { he } from "date-fns/locale";
import { KeyboardArrowDownOutlined, KeyboardArrowUpOutlined, Close } from "@mui/icons-material";
import { makeStyles } from "@mui/styles";
import { getDisabledDates } from "../store/logic/getDisabledDates";

const useStyles = makeStyles(() => ({
    popper: {
        marginTop: "10px !important",
        marginBottom: "10px !important",
        "& .MuiPaper-root": {
            borderRadius: "16px",
            paddingBottom: "10px",
            position: "relative",
        },
    },
    closeButton: {
        position: "absolute",
        top: "20px",
        left: "20px",
    },
}));

export const SelectDateInput = observer(() => {
    const store = useScreenAddStepsStore();
    const { firstDateToInsertSteps, isPeriodDaily, lastDateToInsertSteps } = store;
    const [open, setOpen] = useState(false);
    const classes = useStyles();

    if (isPeriodDaily || !firstDateToInsertSteps) {
        return null;
    }

    const parsedDate = moment(firstDateToInsertSteps, "DD/MM/YYYY").toDate();

    return (
        <LocalizationProvider dateAdapter={AdapterDateFns} libInstance={moment} adapterLocale={he}>
            <DatePicker
                label={t("screenAddSteps.addStepsContainer.selectDate")}
                value={parsedDate}
                onChange={date => (store.firstDateToInsertSteps = date)}
                open={open}
                onOpen={() => setOpen(true)}
                onClose={() => setOpen(false)}
                format="dd/MM/yyyy"
                views={["day"]}
                shouldDisableDate={date => getDisabledDates({ date, lastDateToInsertSteps })}
                slots={{
                    openPickerIcon: open ? KeyboardArrowUpOutlined : KeyboardArrowDownOutlined,
                    actionBar: () => <Close className={classes.closeButton} onClick={() => setOpen(false)} />,
                }}
                slotProps={{
                    popper: {
                        className: classes.popper,
                    },
                    textField: {
                        variant: "filled",
                        onClick: () => setOpen(true),
                        InputProps: {
                            disableUnderline: true,
                            sx: {
                                border: "2px solid white",
                                borderRadius: "50px",
                                backgroundColor: "white",
                                height: 40,
                                boxShadow: "0px 4px 10px rgba(0, 0, 0, 0.2)",
                                paddingTop: "0px",
                                cursor: "pointer",
                                "& .MuiInputBase-input": {
                                    paddingTop: "0px",
                                    paddingBottom: "0px",
                                    fontWeight: "bold",
                                    cursor: "pointer",
                                },
                            },
                        },
                    },
                }}
            />
        </LocalizationProvider>
    );
});
