import { sendOtpCodeToProjectManagerErrorCodes, sendOtpCodeToProjectManagerErrorTexts } from "./sendOtpCodeToProjectManagerErrors";

export const getSendOtpCodeToProjectManagerErrorText = errorCode => {
    if (!errorCode) {
        return;
    }

    switch (errorCode) {
        case sendOtpCodeToProjectManagerErrorCodes.MAIL_SEND_FAILED:
            return sendOtpCodeToProjectManagerErrorTexts.mailSendFailed;
        case sendOtpCodeToProjectManagerErrorCodes.RATE_LIMITER_EXCEEDED:
            return sendOtpCodeToProjectManagerErrorTexts.sendOtpCodeLimiterExceeded;
        default:
            return sendOtpCodeToProjectManagerErrorTexts.mailSendFailed;
    }
};
