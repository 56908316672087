import React from "react";
import { makeStyles } from "@mui/styles";
import { Typography } from "@mui/material";

const useStyles = makeStyles(theme => ({
    statsItem: {
        flexGrow: 1,
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        padding: theme.spacing(3, 1),
        "&:not(:last-of-type)": {
            borderRight: `1px solid ${theme.palette.divider}`,
        },
    },
    label: {
        height: 20,
    },
    text: {
        height: 20,
    },
}));

const NumberCube = props => {
    const classes = useStyles();
    const { label, valueTitle } = props.item;

    return (
        <div className={classes.statsItem}>
            <Typography className={classes.label} align="center" component="h4">
                {valueTitle}
            </Typography>

            <Typography className={classes.text} align="center" variant="h6">
                {label}
            </Typography>
        </div>
    );
};

export default NumberCube;
