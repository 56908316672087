import React from "react";
import { makeStyles } from "@mui/styles";
import { Button } from "@mui/material";
import InputIcon from "@mui/icons-material/Input";
import useRouter from "utils/useRouter";
import { useRootStore } from "commonStores/analytics/rootStoreContext";
import { t } from "common/localization/translate";
import { getSegmentPathById } from "commonStores/analytics/userInfoStore/logic/getSegmentPathById";
import { setDirection } from "utils/direction/setDirection";
import { logoutServer } from "layouts/Dashboard/server/logoutServer";
import { dashboardPaths } from "screens/loginV2/store/logic/dashboardPaths";

const useStyles = makeStyles(theme => ({
    logoutButton: {
        marginLeft: theme.spacing(8),
        fontSize: "12px",
    },
    logoutIcon: {
        marginRight: theme.spacing(1),
        fontSize: "17px",
    },
}));

const MobileLogoutButton = () => {
    const rootStore = useRootStore();
    const classes = useStyles();
    const { history } = useRouter();

    const handleLogout = async () => {
        const { dashboardType, clearUserInfoStore, currentSegmentId } = rootStore.userInfoStore;
        if (!currentSegmentId || dashboardType == dashboardPaths.SUPPORT) {
            clearUserInfoStore();
            await logoutServer();
            history.push(`/${dashboardType}/login`);
            return;
        }
        const segmentPath = getSegmentPathById(currentSegmentId);
        clearUserInfoStore();
        setDirection();
        await logoutServer();
        history.push(`/${dashboardType}/login/${segmentPath}`);
    };

    return (
        <Button className={classes.logoutButton} color="inherit" onClick={handleLogout}>
            <InputIcon className={classes.logoutIcon} />
            {t("topBar.logout.title")}
        </Button>
    );
};

export default MobileLogoutButton;
