import React, { useState } from "react";
import { makeStyles } from "@mui/styles";
import { observer } from "mobx-react";
import { useRootStore } from "commonStores/analytics/rootStoreContext";
import { Page } from "components";
import { t } from "common/localization/translate";
import { IdNumForm } from "./components/idNumForm/idNumForm";
import { createScreenAddStepsStore, ScreenAddStepsProvider } from "./store";
import { AddStepsTable } from "./components/addStepsTable";
import { Titles } from "./components/titles";
import { Redirect } from "react-router-dom";
import { dashboardPaths } from "screens/loginV2/store/logic/dashboardPaths";
import Grid from "@mui/material/Unstable_Grid2";

const useStyles = makeStyles(theme => ({
    root: {
        padding: theme.spacing(3),
        flex: 1,
    },
    container: {
        "& > *": {
            height: "100%",
            padding: theme.spacing(0.5),
        },
    },
}));

export const AddSteps = observer(() => {
    const rootStore = useRootStore();
    const [store] = useState(() => createScreenAddStepsStore(rootStore));
    const classes = useStyles();

    if (!rootStore.userInfoStore.isCanAddSteps) {
        return <Redirect to={{ pathname: `/${dashboardPaths.SUPPORT}/home` }} />;
    }

    return (
        <ScreenAddStepsProvider value={store}>
            <Page className={classes.root} title={t("screenAddSteps.title")}>
                <Grid className={classes.container} container item lg={12} xs={12} spacing={2}>
                    <Titles classes={classes} />
                    <IdNumForm />
                </Grid>
                <AddStepsTable />
            </Page>
        </ScreenAddStepsProvider>
    );
});
