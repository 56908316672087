import React from "react";
import { makeStyles } from "@mui/styles";
import Grid from "@mui/material/Unstable_Grid2";
import { observer } from "mobx-react";
import { useScreenHomeStore } from "../../../store";
import { SelectDateProjectManagerSummaryCouponsReport } from "./selectDateProjectManagerSummaryCouponsReport";
import { SummaryMonthlyReportSuppliersCouponsTitle } from "./summaryMonthlyReportSuppliersCouponsTitle";
import { SummaryMonthlyReportSuppliersCouponsButton } from "./summaryMonthlyReportSuppliersCouponsButton";
import { ErrorTextSentReportFaild } from "./errorTextSentReportFaild";

const useStyles = makeStyles(theme => ({
    root: {
        marginTop: 20,
    },
    divider: {
        backgroundColor: theme.palette.primary.main,
    },
}));

export const SummaryMonthlyReportSuppliersCoupons = observer(() => {
    const store = useScreenHomeStore();
    const classes = useStyles();

    if (!store.isProjectManager) {
        return null;
    }

    return (
        <Grid container className={classes.root} spacing={3}>
            <SummaryMonthlyReportSuppliersCouponsTitle />
            <SelectDateProjectManagerSummaryCouponsReport />
            <ErrorTextSentReportFaild />
            <SummaryMonthlyReportSuppliersCouponsButton />
        </Grid>
    );
});
