export const errorCodes = {
    ERROR_TYPE_USER_NOT_EXISTS: 1,
    ERROR_TYPE_INVALID_DATA: 2,
    ERROR_TYPE_ID_TOO_SHORT: 3,
    ERROR_TYPE_NOT_ONLY_NUMBERS: 4,
    ERROR_TYPE_RATE_LIMITER_IS_EXCEEDED: 5,
    ERROR_TYPE_USER_USED_SMARTPHONE: 6,
    ERROR_TYPE_USER_NOT_REGISTERED: 7,
    ERROR_TYPE_DAILY_STEPS_LIMIT_REACHED: 8,
    ERROR_TYPE_WEEKLY_STEPS_LIMIT_REACHED: 9,
    ERROR_TYPE_MONTHLY_STEPS_LIMIT_REACHED: 10,
};
