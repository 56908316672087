import axios from "axios";
import { getIsShouldRetryRequest } from "./getIsShouldRetryRequest";
import { refreshJwtToken } from "./refreshJwtToken";
import { setParsedOriginalRequestData } from "./setParsedOriginalRequestData";
import { sendRetryRequestLog } from "./sendRetryRequestLog";

const tokenRefreshStatus = { isRefreshing: false, isSuccessfullyRefreshed: false };

export async function handleResponseErrorInterceptor(error) {
    const originalRequest = error.config;
    const isShouldRetry = getIsShouldRetryRequest({ error, originalRequest });

    if (isShouldRetry) {
        originalRequest.retry = true;

        const { isSuccess } = await refreshJwtToken({ originalRequest, tokenRefreshStatus });

        if (isSuccess) {
            setParsedOriginalRequestData(originalRequest);
            tokenRefreshStatus.isSuccessfullyRefreshed = true;
            tokenRefreshStatus.isRefreshing = false;
            sendRetryRequestLog({ originalRequest });
            return axios.request(originalRequest);
        }

        tokenRefreshStatus.isRefreshing = false;
    }

    return Promise.reject(error);
}
