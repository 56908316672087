import React from "react";
import { makeStyles } from "@mui/styles";
import { Button } from "@mui/material";
import Grid from "@mui/material/Unstable_Grid2";
import { observer } from "mobx-react";
import { useScreenHomeStore } from "../../../store";
import { t } from "common/localization/translate";
import CircularProgress from "@mui/material/CircularProgress";

const useStyles = makeStyles(theme => ({
    buttons: {
        backgroundColor: theme.palette.primary.main,
        fontSize: 15,
        width: 200,
        height: 40,
        color: theme.palette.white,
        marginRight: 5,
        marginTop: 5,
        "&:hover": {
            backgroundColor: theme.palette.primary.white,
        },
    },
}));

export const SummaryMonthlyReportSuppliersCouponsButton = observer(() => {
    const store = useScreenHomeStore();
    const classes = useStyles();

    const onClick = () => {
        store.sendMonthlySummaryCouponsSuppliersReportToProjectManager();
    };

    const getReportButtonContent = store.isFetchingCouponsSummaryManagerReport ? <CircularProgress color="primary" size={20} /> : t("suppliers.projectManagerHome.getMonthlyCouponsSummaryReportButton");
    return (
        <Grid item xs={12} spacing={3}>
            <Button variant="contained" className={classes.buttons} onClick={onClick}>
                {getReportButtonContent}
            </Button>
        </Grid>
    );
});
