import React from "react";
import { makeStyles } from "@mui/styles";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import { t } from "common/localization/translate";
import Select from "@mui/material/Select";
import { useScreenValidationCodeStore } from "../store";
import { observer } from "mobx-react";
import Grid from "@mui/material/Unstable_Grid2";

const useStyles = makeStyles(theme => ({
    formControl: {
        minWidth: 120,
    },
    selectEmpty: {
        marginTop: theme.spacing(2),
    },
    label: {
        fontSize: 14,
        fontWeight: "bold",
    },
}));

export const ValidationsList = observer(() => {
    const store = useScreenValidationCodeStore();
    const classes = useStyles();

    const handleChange = (event, item) => {
        store.chosenValidation = event.target.value;
        store.chosenListCodesToPerformValidation = item.props.name;
    };

    if (!store.validationsList) {
        return null;
    }

    const menuItems = store.validationsList.map(item => (
        <MenuItem value={item.methodName} name={item.listCodesToPerformValidation}>
            {item.explanationText}
        </MenuItem>
    ));
    return (
        <Grid item xs={12} spacing={3}>
            <InputLabel className={classes.label}>{t("suppliers.projectManager.validationsList")}</InputLabel>
            <FormControl className={classes.formControl}>
                <Select value={store.chosenValidation} onChange={handleChange} displayEmpty className={classes.selectEmpty} variant="standard">
                    {menuItems}
                </Select>
            </FormControl>
        </Grid>
    );
});
