import React, { useState } from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import Checkbox from "@mui/material/Checkbox";
import TextField from "@mui/material/TextField";
import { makeStyles } from "@mui/styles";
import { useScreenPurchaseFromSupportStore } from "../../store/index";
import { observer } from "mobx-react-lite";
import { t } from "common/localization/translate";
import { validateEmail } from "../../store/logic/validateEmail";
import { activationTypes } from "../../store/logic/coupontypeActivationTypes";

const useStyles = makeStyles(theme => ({
    root: {
        color: theme.palette.white,
        fontSize: 18,
        backgroundColor: theme.palette.primary.main,
        "& h6": {
            color: theme.palette.white,
            fontSize: 18,
        },
    },
    row: {
        display: "flex",
        flexDirection: "row",
        alignItems: "center",
    },
    checkboxRow: {
        display: "flex",
        flexDirection: "row",
        alignItems: "center",
        marginLeft: "-12px",
    },
    bold: { fontWeight: "bold", paddingLeft: 5 },
    mail: { paddingLeft: 5 },
    content: { marginTop: 8 },
    dialogDiv: {
        width: 600,
        height: "auto",
    },
    button: {
        backgroundColor: theme.palette.primary.main,
        fontSize: 14,
        color: theme.palette.white,
        marginLeft: 21,
        marginTop: 18,
        "&:hover": {
            backgroundColor: theme.palette.primary.secondGraphColor,
            color: theme.palette.white,
        },
        width: "150px",
    },
    buttonContainer: {
        display: "flex",
        justifyContent: "center",
    },
    textField: {
        marginTop: 10,
        marginLeft: 10,
        height: "40px",
        width: "155px",
    },
}));

export const SuccessPurchaseCouponModal = observer(() => {
    const store = useScreenPurchaseFromSupportStore();
    const { isSuccessPurchaseCoupon, setIsSuccessPurchaseCoupon, afterPurchaseData, coupon } = store;

    const { activationCode, userMail } = afterPurchaseData;
    const classes = useStyles();

    const [isChecked, setIsChecked] = useState(false);
    const [error, setError] = useState(null);
    const [email, setEmail] = useState("");

    const handleCheckboxChange = event => {
        setIsChecked(event.target.checked);
        setError(false);
        setEmail("");
    };

    const handleInputChange = event => {
        setEmail(event.target.value);
    };

    const handleClick = () => {
        const isValid = validateEmail(email);
        if (isChecked) {
            if (!isValid) {
                if (email) {
                    setError(t("screenPurchaseFromSupport.coupons.successPurchaseModal.code.mail.error.notValid"));
                } else {
                    setError(t("screenPurchaseFromSupport.coupons.successPurchaseModal.code.mail.error.noMail"));
                }
            } else {
                setEmail("");
                setError("");
                setIsChecked(false);
                setIsSuccessPurchaseCoupon(false);
                store.sendAdditionalMail(email);
            }
        } else {
            setIsSuccessPurchaseCoupon(false);
            setEmail("");
            setError("");
            setIsChecked(false);
        }
    };

    const onClose = () => {
        setIsSuccessPurchaseCoupon(false);
        setEmail("");
        setError("");
        setIsChecked(false);
    };

    let subTitle = null;

    if (coupon.activationType == activationTypes.CODE) {
        subTitle = (
            <DialogContentText className={classes.row}>
                {t("screenPurchaseFromSupport.coupons.successPurchaseModal.code.code")}
                <DialogContentText className={classes.bold}>{activationCode}</DialogContentText>
                <br />
                <br />
            </DialogContentText>
        );
    }

    if (coupon.activationType == activationTypes.CODE_AND_LINK) {
        subTitle = (
            <DialogContentText className={classes.row}>
                {t("screenPurchaseFromSupport.coupons.successPurchaseModal.codeAndLink.code")}
                <DialogContentText className={classes.bold}>{activationCode}</DialogContentText>
                <br />
                <br />
            </DialogContentText>
        );
    }

    const buttonText = email ? t("screenPurchaseFromSupport.coupons.successPurchaseModal.code.button.withEmail") : t("screenPurchaseFromSupport.coupons.successPurchaseModal.code.button");

    return (
        <div>
            <Dialog className={classes.dialog} open={isSuccessPurchaseCoupon} onClose={onClose} aria-labelledby="responsive-dialog-title">
                <div className={classes.dialogDiv}>
                    <DialogTitle className={classes.root} id="responsive-dialog-title">
                        {t("screenPurchaseFromSupport.coupons.successPurchaseModal.title")}
                    </DialogTitle>
                    <DialogContent className={classes.content}>
                        {subTitle}
                        <DialogContentText className={classes.row}>
                            {t("screenPurchaseFromSupport.coupons.successPurchaseModal.code.mail")}
                            <DialogContentText className={classes.mail}>{userMail}</DialogContentText>
                        </DialogContentText>
                        <div className={classes.checkboxRow}>
                            <Checkbox checked={isChecked} onChange={handleCheckboxChange} color="primary" />
                            <DialogContentText>{t("screenPurchaseFromSupport.coupons.successPurchaseModal.code.checkbox")}</DialogContentText>
                            {isChecked && (
                                <TextField
                                    autoFocus={isChecked}
                                    className={classes.textField}
                                    label={t("screenPurchaseFromSupport.coupons.successPurchaseModal.textInput.label")}
                                    variant="standard"
                                    type="email"
                                    value={email}
                                    onChange={handleInputChange}
                                    onInput={handleInputChange}
                                    error={error}
                                    helperText={error}
                                />
                            )}
                        </div>
                    </DialogContent>
                    <DialogActions className={classes.buttonContainer}>
                        <Button className={classes.button} size="small" type="submit" variant="contained" onClick={handleClick}>
                            {buttonText}
                        </Button>
                    </DialogActions>
                </div>
            </Dialog>
        </div>
    );
});
