import React, { useState } from "react";
import { makeStyles } from "@mui/styles";
import { Page } from "components";
import useRouter from "utils/useRouter";
import { observer } from "mobx-react";
import { useRootStore } from "commonStores/analytics/rootStoreContext";
import { createScreenLoginStore, ScreenLoginStoreProvider } from "./store";
import { EmailAndPassword } from "./components/wizardSteps/emailAndPassword";
import { ChooseSegment } from "./components/wizardSteps/chooseSegment";
import { EmailSentSuccessfullyPopUp } from "./components/mailSentSuccessfullyPopUp";
import { EnterOtp } from "./components/wizardSteps/enterOtp";

const useStyles = makeStyles(theme => ({
    root: {
        height: "100%",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        padding: theme.spacing(6, 2),
        backgroundColor: theme.palette.white,
    },
}));
export const Login = observer(() => {
    const rootStore = useRootStore();
    const router = useRouter();
    const [store] = useState(() => createScreenLoginStore(rootStore, router));
    const classes = useStyles();

    return (
        <ScreenLoginStoreProvider value={store}>
            <Page className={classes.root} title="Login">
                {store.wizardStep === "emailAndPassword" && <EmailAndPassword />}
                {store.wizardStep === "chooseSegment" && <ChooseSegment />}
                {store.wizardStep === "enterOtp" && <EnterOtp />}
                <EmailSentSuccessfullyPopUp />
            </Page>
        </ScreenLoginStoreProvider>
    );
});
