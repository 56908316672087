import { t } from "../../../../../common/localization/translate";
import { errorCodes } from "../../../services/errorCodes";

export const getAddStepsErrorTextByErrorCode = (errorCode, daysLimit) => {
    if (!errorCode) {
        return;
    }
    switch (errorCode) {
        case errorCodes.ERROR_TYPE_USER_NOT_EXISTS:
            return t("screenAddSteps.idNum.error.userNotExist");
        case errorCodes.ERROR_TYPE_INVALID_DATA:
            return t("screenDailyStepsAndGoals.errors.invalidInput");
        case errorCodes.ERROR_TYPE_ID_TOO_SHORT:
            return t("screenDailyStepsAndGoals.errors.idNumberTooShort");
        case errorCodes.ERROR_TYPE_NOT_ONLY_NUMBERS:
            return t("screenDailyStepsAndGoals.errors.onlyNumbers");
        case errorCodes.ERROR_TYPE_RATE_LIMITER_IS_EXCEEDED:
            return t("screenDailyStepsAndGoals.errors.limitReached");
        case errorCodes.ERROR_TYPE_USER_USED_SMARTPHONE:
            return t("screenAddSteps.idNum.error.userUsedSmartPhone");
        case errorCodes.ERROR_TYPE_USER_NOT_REGISTERED:
            return t("screenAddSteps.idNum.error.notRegistered");
        case errorCodes.ERROR_TYPE_DAILY_STEPS_LIMIT_REACHED:
            return t("screenAddSteps.idNum.error.dailyStepsLimitReached", { daysLimit });
        case errorCodes.ERROR_TYPE_WEEKLY_STEPS_LIMIT_REACHED:
            return t("screenAddSteps.idNum.error.weeklyStepsLimitReached", { daysLimit });
        case errorCodes.ERROR_TYPE_MONTHLY_STEPS_LIMIT_REACHED:
            return t("screenAddSteps.idNum.error.monthlyStepsLimitReached", { daysLimit });
        default:
            return t("screenAddSteps.idNum.error.generalError");
    }
};
