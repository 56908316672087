import axios from "axios";
import { getBaseUrl } from "./getBaseUrl";
import { addBreadcrumb } from "./sentrySendException/addBreadcrumb";
import { sendExceptionToSentryAndReThrow } from "./sentrySendException/sendExceptionToSentryAndReThrow";
import { logoutServer } from "layouts/Dashboard/server/logoutServer";
import { handleResponseErrorInterceptor } from "./tokenRefreshLogic/handleResponseErrorInterceptor";

export async function httpCall(url, data) {
    const baseUrl = getBaseUrl();
    const urlWithBase = `${baseUrl}${url}`;

    axios.interceptors.response.use(undefined, handleResponseErrorInterceptor);

    try {
        const res = await axios({
            url: urlWithBase,
            method: "POST",
            data,
            withCredentials: true,
        });

        return res.data;
    } catch (e) {
        if (e.response?.status === 401 && e.response?.data?.loginSessionExpired) {
            await logoutServer();
            window.location.reload();
            throw new Error("Login session expired");
        }

        if (e.response?.status === 401) {
            await logoutServer();
            window.location.reload();
            throw new Error("Token expired");
        }

        if (e.response?.status === 403) {
            await logoutServer(true);
            window.location.reload();
            throw new Error("Token mismatched");
        }

        addBreadcrumb({ level: "error", data: { url } });
        sendExceptionToSentryAndReThrow({ error: e, extra: { url } });
    }
}
