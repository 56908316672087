import React from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import { makeStyles } from "@mui/styles";
import { observer } from "mobx-react-lite";
import { t } from "common/localization/translate";
import { useHealthAndFitnessDataStore } from "screens/organization/healthAndFitnessData/store/storeHealthAndFitnessDataStoreContext";

const useStyles = makeStyles(theme => ({
    title: {
        backgroundColor: theme.palette.primary.main,
        "& h6": {
            color: theme.palette.white,
            fontSize: 16,
            fontFamily: "Assistant",
            fontWeight: "bold",
        },
    },
    content: {
        fontFamily: "Assistant",
        fontSize: 14,
    },
    button: {
        color: theme.palette.white,
        backgroundColor: theme.palette.primary.main,
        "&:hover": {
            backgroundColor: theme.palette.primary.secondGraphColor,
        },
    },
}));

export const SuccessSaveDataPopUp = observer(() => {
    const store = useHealthAndFitnessDataStore();
    const classes = useStyles();

    const handleClose = () => {
        store.isSuccessSaveData = false;
    };

    return (
        <div>
            <Dialog open={store.isSuccessSaveData}>
                <DialogTitle className={classes.title}>{t("screenHealthAndFitnessData.popup.title")}</DialogTitle>
                <DialogContent>
                    <DialogContentText>{t("screenHealthAndFitnessData.popup.content")}</DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleClose} className={classes.button} autoFocus>
                        {t("screenHealthAndFitnessData.popup.button")}
                    </Button>
                </DialogActions>
            </Dialog>
        </div>
    );
});
