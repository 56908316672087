import { errorCodes } from "screens/support/services/errorCodes";
import { t } from "common/localization/translate";
import { useRootStore } from "commonStores/analytics/rootStoreContext";

export function GetErrorTextByErrorCode(errorCode) {
    const rootStore = useRootStore();
    if (!errorCode) {
        return;
    }
    switch (errorCode) {
        case errorCodes.ERROR_TYPE_USER_NOT_EXISTS:
            return rootStore.userInfoStore.isWithSearchByPhoneOption ? t("screenCouponsUser.errors.userNotExsist.idOrPhone") : t("screenCouponsUser.errors.userNotExsist");
        case errorCodes.ERROR_TYPE_INVALID_DATA:
            return t("screenCouponsUser.errors.invalidInput");
        case errorCodes.ERROR_TYPE_ID_TOO_SHORT:
            return t("screenCouponsUser.errors.idNumberTooShort");
        case errorCodes.ERROR_TYPE_NOT_ONLY_NUMBERS:
            return t("screenCouponsUser.errors.onlyNumbers");
        case errorCodes.ERROR_TYPE_RATE_LIMITER_IS_EXCEEDED:
            return t("screenCouponsUser.errors.limitReached");
        default:
            return t("screenCouponsUser.errors.userNotExsist");
    }
}
