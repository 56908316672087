import React from "react";
import { observer } from "mobx-react";
import MUIDataTable from "mui-datatables";
import { t } from "common/localization/translate";
import { useScreenTraineesInfoStore } from "../store";
import { getTraineesInfoTableData } from "../store/logic/getTraineesInfoTableData";
import { getTraineesInfoTableColumns } from "../store/logic/getTraineesInfoTableColumns";

export const TraineesTable = observer(() => {
    const store = useScreenTraineesInfoStore();
    const { currentSegment } = store.rootStore.userInfoStore || {};
    const segmentIds = currentSegment && currentSegment.segmentId && currentSegment.segmentId.segmentIds;
    const { isFetching } = store;
    const { title, options, columns } = getTableData(isFetching);
    const data = getTraineesInfoTableData(store);

    return <MUIDataTable title={title} data={data} columns={columns} options={options} />;
});

const getTableData = isFetching => {
    const columns = getTraineesInfoTableColumns();
    const options = {
        filter: false,
        download: false,
        print: false,
        viewColumns: false,
        responsive: "scrollMaxHeight",
        selectableRows: "none",
        textLabels: {
            body: {
                noMatch: isFetching ? "Loading..." : "Sorry, no matching records found",
            },
        },
    };
    const title = t("screenTrainesInfo.table.title");
    return { title, options, columns };
};
