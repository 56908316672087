import React, { useEffect, useState } from "react";
import { makeStyles } from "@mui/styles";
import { Page } from "components";
import { observer } from "mobx-react";
import { createScreenHomeStore, ScreenHomeStoreProvider } from "../../store";
import { t } from "common/localization/translate";
import { SummaryMonthlyReportSuppliersCoupons } from "../projectManager/monthlySummaryCouponsReport/summaryMonthlyReportSuppliersCoupons";
import { SummaryMonthlyReportBySupplier } from "../projectManager/monthlySummaryCouponsReport/summaryMonthlyReportBySupplier";
import { SuccessSendMonthlySummaryCouponsReportPopUp } from "../projectManager/monthlySummaryCouponsReport/successSendMonthlySummaryCouponsReportPopUp";
import Divider from "@mui/material/Divider";
import { useRootStore } from "../../../../../commonStores/analytics/rootStoreContext";

const useStyles = makeStyles(theme => ({
    root: {
        padding: theme.spacing(3),
        width: "100%",
        height: "100%",
    },
    divider: {
        marginTop: 20,
        borderTop: `thin solid ${theme.palette.primary.main}`,
    },
}));

export const SupplierAndCouponReports = observer(() => {
    const rootStore = useRootStore();
    const [store] = useState(() => createScreenHomeStore(rootStore));
    const classes = useStyles();
    useEffect(() => {
        store.getScreenData();
    }, []);

    if (!store.isProjectManager) {
        return null;
    }

    return (
        <ScreenHomeStoreProvider value={store}>
            <Page className={classes.root} title={t("suppliers.home.suuplierDashboardTitle")}>
                <SummaryMonthlyReportBySupplier />
                <Divider className={classes.divider} />
                <SummaryMonthlyReportSuppliersCoupons />
                <SuccessSendMonthlySummaryCouponsReportPopUp />
            </Page>
        </ScreenHomeStoreProvider>
    );
});
