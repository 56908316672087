import React from "react";
import { makeStyles } from "@mui/styles";
import { observer } from "mobx-react";
import Grid from "@mui/material/Unstable_Grid2";
import SimpleCube from "common/ui/simpleCube";
import { t } from "common/localization/translate";
import { useScreenHomeStore } from "../../store";
import { formatNumber } from "common/formatNumber";
import InstagramIcon from "@mui/icons-material/Instagram";

const useStyles = makeStyles(theme => ({
    row: {
        width: "100%",
    },
}));

export const InstagramCube = observer(() => {
    const { isFetching, clicksOnInstagramBusiness } = useScreenHomeStore();
    const clicksOnInstagramBusinessFormatted = formatNumber(clicksOnInstagramBusiness);
    const classes = useStyles();

    return (
        <Grid item sm={12} lg={3} className={classes.row}>
            <div>
                <SimpleCube isWithoutMargin={true} isFetching={isFetching} label={t("business.home.cubes.instagram")} isWithConstatHeight={true} icon={<InstagramIcon />} value={clicksOnInstagramBusinessFormatted} withoutMargin={true} />
            </div>
        </Grid>
    );
});
