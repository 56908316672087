import React from "react";
import { observer } from "mobx-react";
import { handleInputNonNegativeValuesOnly } from "../handleInputNonNegativeValuesOnly";
import { handleLimitInputLength } from "../handleLimitInputLength";
import { InputBloodPressureForm } from "./inputBloodPressureForm";
import { t } from "common/localization/translate";
import { useHealthAndFitnessDataStore } from "../../../store/storeHealthAndFitnessDataStoreContext";

const INPUT_MAX_LENGTH = 3;

export const BloodPressureSystolic = observer(({ stage }) => {
    const store = useHealthAndFitnessDataStore();
    const systolicValue = store[stage].systolic;

    const handleSystolicChange = event => {
        const value = handleInputNonNegativeValuesOnly(event.target.value);
        const systolicValue = handleLimitInputLength({ maxLength: INPUT_MAX_LENGTH, input: value });
        store.setBloodPressureSystolic(systolicValue, stage);
    };

    return <InputBloodPressureForm label={t("screenHealthAndFitnessData.bloodPressure.label")} subText={t("screenHealthAndFitnessData.bloodPressure.systolic.subtext")} fieldName={`${stage}BloodPressureSystolic`} onChange={handleSystolicChange} value={systolicValue} />;
});
