import React, { useState } from "react";
import { makeStyles } from "@mui/styles";
import { observer } from "mobx-react";
import { useRootStore } from "commonStores/analytics/rootStoreContext";
import { Page } from "components";
import Grid from "@mui/material/Unstable_Grid2";
import { TitlesCoupons } from "./components/titlesCoupons";
import { createScreenPurchaseFromSupportStore, ScreenPurchaseFromSupportProvider } from "./store";
import { PurchaseCouponModal } from "./components/modals/purchaseCouponModal";
import { SuccessPurchaseCouponModal } from "./components/modals/succesPurchaseCouponModal";
import { IdNumForm } from "./components/idNumForm";
import { UnsuccessPurchaseCouponModal } from "./components/modals/unsuccessPurchaseCouponModal";
import { AllCouponsTable } from "./components/allCouponsTable/allCouponsTable";
import { DeliveryModal } from "./components/modals/deliveryModal";
import { Toaster } from "react-hot-toast";

const useStyles = makeStyles(theme => ({
    root: {
        padding: theme.spacing(3),
        flex: 1,
    },
    container: {
        "& > *": {
            height: "100%",
            padding: theme.spacing(0.5),
        },
    },
}));

export const PurchaseFromSupport = observer(() => {
    const rootStore = useRootStore();
    const [store] = useState(() => createScreenPurchaseFromSupportStore(rootStore));
    const classes = useStyles();

    return (
        <ScreenPurchaseFromSupportProvider value={store}>
            <Page className={classes.root} title={"Purchase"}>
                <Grid className={classes.container} container item lg={12} xs={12} spacing={2}>
                    <TitlesCoupons />
                    <IdNumForm />
                </Grid>
                <br />
                <AllCouponsTable />
                <PurchaseCouponModal />
                <DeliveryModal />
                <UnsuccessPurchaseCouponModal />
                <SuccessPurchaseCouponModal />
                <Toaster position="bottom-center" reverseOrder={false} />
            </Page>
        </ScreenPurchaseFromSupportProvider>
    );
});
