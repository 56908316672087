import { dashboardTypes } from "screens/loginV2/store/logic/dashboardTypes";

const storageKeys = {
    BUSINESS: "businessDashboard",
    ORGANIZATION: "organzationDashboardV2",
    OPERATOR: "operatoprDashboard",
    SUPPORT: "supportDashboard",
    SUPPLIERS: "suppliersDashboard",
};

export function getStorageKeyByDashobardType(dashboardType) {
    switch (dashboardType) {
        case dashboardTypes.BUSINESS:
            return storageKeys.BUSINESS;
        case dashboardTypes.ORGANIZATION:
            return storageKeys.ORGANIZATION;
        case dashboardTypes.OPERATOR:
            return storageKeys.OPERATOR;
        case dashboardTypes.SUPPORT:
            return storageKeys.SUPPORT;
        case dashboardTypes.SUPPLIERS:
            return storageKeys.SUPPLIERS;
        default:
            return storageKeys.ORGANIZATION;
    }
}
