import React from "react";
import { makeStyles } from "@mui/styles";
import { observer } from "mobx-react";
import { CardContent } from "@mui/material";
import Grid from "@mui/material/Unstable_Grid2";
import { UserNameInput } from "./components/userNameInput";
import { EmailInput } from "./components/emailInput";
import { SaveButton } from "./components/saveButton";
import { PhoneInput } from "./components/phonInput";
import { EntitlementStatus } from "../entitlementStatus/entitlementStatus";
import { OtpInput } from "../otpForm/otpInput";
import { OtpError } from "../otpForm/otpError";
import { OtpSendButton } from "../otpForm/otpSendButton";

const useStyles = makeStyles(theme => ({
    noPadding: {
        padding: 0,
        width: "100%",
    },
}));

export const UserDetailsInputs = observer(props => {
    const classes = useStyles();

    return (
        <CardContent className={classes.noPadding}>
            <Grid container spacing={4}>
                <UserNameInput />
                <EmailInput />
                <PhoneInput />
                <EntitlementStatus />
                <OtpInput />
                <SaveButton />
                <OtpError />
                <OtpSendButton />
            </Grid>
        </CardContent>
    );
});
