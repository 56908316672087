import React from "react";
import { TextField } from "@mui/material";
import Grid from "@mui/material/Unstable_Grid2";
import { observer } from "mobx-react";
import { t } from "common/localization/translate";
import { makeStyles } from "@mui/styles";
import { useScreenOrderDetailsStore } from "../../../store/index";
import { getOrderDetailsTextByErrorCode } from "../../../store/logic/getOrderDetailsErrorTextByErrorCode";

const useStyles = makeStyles(theme => ({
    input: {
        width: "155px",
    },
}));

export const OrderIdInput = observer(props => {
    const store = useScreenOrderDetailsStore();
    const inputProps = { maxLength: 10 };
    const classes = useStyles();
    const errorText = getOrderDetailsTextByErrorCode(store.errorCode);

    const handleInputChange = event => {
        const { value } = event.target;
        store.setOrderId(value);
    };
    const isError = store.errorCode ? true : false;

    return (
        <Grid item sm={4} lg={2} spacing={2}>
            <TextField className={classes.input} id="standard-basic" variant="standard" type="string" placeholder={t("screenOrderDetails.orderId")} error={isError} helperText={errorText} inputProps={inputProps} onChange={handleInputChange} />
        </Grid>
    );
});
