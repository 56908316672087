import React from "react";
import { makeStyles } from "@mui/styles";
import { observer } from "mobx-react";
import { colors, Tooltip } from "@mui/material";
import Label from "common/ui/label";
import { t } from "common/localization/translate";
import { useScreenHomeStore } from "../store";
import { formatNumber } from "common/formatNumber";
import CircularProgress from "@mui/material/CircularProgress";

const useStyles = makeStyles(theme => ({
    label: {
        marginLeft: theme.spacing(1),
        paddingTop: theme.spacing(1.5),
        paddingBottom: theme.spacing(1.5),
        paddingRight: theme.spacing(3),
        paddingLeft: theme.spacing(3),
    },
    colorPrimary: {
        color: "white",
        height: 10,
    },
}));

export const TodaysGainedCoinsLabel = observer(() => {
    const classes = useStyles();
    const { todayGainedCoins, isFetchingTodayGainedCoins } = useScreenHomeStore();
    const count = formatNumber(todayGainedCoins);
    const middleComponent = isFetchingTodayGainedCoins ? (
        <CircularProgress color="primary" size={10} classes={{ colorPrimary: classes.colorPrimary }} />
    ) : (
        t("screenHome.totalNumbersCubesRow.totalCoinsSum.todaysSum.value", {
            count,
        })
    );

    return (
        <Tooltip title={t("screenHome.totalNumbersCubesRow.totalCoinsSum.todaysSum.tooltip")} placement="bottom">
            <div>
                <Label className={classes.label} color={colors.green[600]} variant="contained">
                    {middleComponent}
                </Label>
            </div>
        </Tooltip>
    );
});
