import React from "react";
import Dialog from "@mui/material/Dialog";
import { useScreenPurchaseFromSupportStore } from "../../store/index";
import { observer } from "mobx-react-lite";
import { PurchaseCouponModalContent } from "./purchaseCouponModalContent";
import { PurchaseWithCardInputs } from "../addressForm/components/purchaseWithCardInputs";
import { OtpContainer } from "./otpContainer";
import { Close } from "@mui/icons-material";

export const PurchaseCouponModal = observer(() => {
    const store = useScreenPurchaseFromSupportStore();
    const { isShowPurchaseModal, isWithCurrency, shouldShowOtp, shouldAskForEmailBeforeCreditPurchase } = store;

    const handleClickClose = () => {
        store.showPurchaseModal(false);
        store.paymentUrl = "";
        store.isWithCurrency = false;
        store.shouldShowOtp = false;
        store.otpError = false;
        store.otpApproved = false;
        store.shouldAskForEmailBeforeCreditPurchase = true;
        shouldShowCloseButton = false;
        containerStyle = null;
        if (isWithCurrency) {
            store.getScreenData();
        }
    };

    let content = <PurchaseCouponModalContent />;
    let containerStyle, shouldShowCloseButton;

    if (isWithCurrency) {
        if (!shouldAskForEmailBeforeCreditPurchase) {
            containerStyle = { maxHeight: "80vh", overflowY: "auto", padding: 20 };
            shouldShowCloseButton = true;
        }
        content = <PurchaseWithCardInputs />;
    }

    if (shouldShowOtp) {
        containerStyle = null;
        shouldShowCloseButton = false;
        content = <OtpContainer />;
    }

    return (
        <Dialog
            open={isShowPurchaseModal}
            onClose={handleClickClose}
            aria-labelledby="responsive-dialog-title"
            maxWidth="sm"
            PaperProps={{
                style: { position: "relative", overflow: "visible" },
            }}
        >
            {shouldShowCloseButton && (
                <button
                    onClick={handleClickClose}
                    style={{
                        position: "absolute",
                        top: 10,
                        right: 10,
                        background: "white",
                        border: "none",
                        cursor: "pointer",
                        zIndex: 1000,
                        padding: 8,
                        borderRadius: "50%",
                        boxShadow: "0px 2px 10px rgba(0,0,0,0.1)",
                    }}
                >
                    <Close style={{ fontSize: 24 }} />
                </button>
            )}
            <div style={containerStyle}>{content}</div>
        </Dialog>
    );
});
