import React from "react";
import { TextField } from "@mui/material";
import { observer } from "mobx-react";
import { makeStyles } from "@mui/styles";
import { useScreenPurchaseFromSupportStore } from "../../../store";
import { t } from "../../../../../../common/localization/translate";
import { CityAndStreetsList } from "./cityAndStreetsList";

const useStyles = makeStyles(() => ({
    container: {
        marginRight: "16px",
    },
    input: {
        width: "205px",
        "& .MuiOutlinedInput-root": {
            borderRadius: "10px",
        },
    },
}));

export const CityAndStreetInput = observer(() => {
    const store = useScreenPurchaseFromSupportStore();
    const classes = useStyles();

    const handleInputChange = event => {
        store.city = null;
        store.street = null;
        store.isOpenedStreetsList = true;
        store.errorMessageStreet = null;
        const { value } = event.target;
        store.searchStreetValue = value;
        store.getAndSetStreetSearchResults(value);
    };

    const handleBlur = () => {
        setTimeout(() => {
            store.isOpenedStreetsList = false;
        }, 500);
    };

    const error = store.errorMessageStreet;

    return (
        <div className={classes.container}>
            <TextField
                value={store.searchStreetValue}
                className={classes.input}
                id="standard-basic"
                type="string"
                variant="outlined"
                error={error}
                label={t("screenPurchaseFromSupport.coupons.deliveryModal.streetAndCity")}
                helperText={error}
                onChange={handleInputChange}
                onInput={handleInputChange}
                onBlur={handleBlur}
            />
            <CityAndStreetsList />
        </div>
    );
});
