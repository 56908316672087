import React from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import { makeStyles } from "@mui/styles";
import Slide from "@mui/material/Slide";
import { useScreenPricingStore } from "../store/index";
import { observer } from "mobx-react-lite";
import { t } from "common/localization/translate";
import { GREEN } from "../../../../common/businessDashboardColors";

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});
const useStyles = makeStyles(theme => ({
    title: {
        backgroundColor: theme.palette.primary.main,
        "& h6": {
            color: theme.palette.white,
            fontSize: 16,
            fontFamily: "Assistant",
            fontWeight: "bold",
        },
    },
    buttons: {
        backgroundColor: theme.palette.primary.main,
        fontSize: 14,
        color: theme.palette.white,
        fontFamily: "Assistant",
        "&:hover": {
            backgroundColor: GREEN,
        },
    },
    content: {
        fontFamily: "Assistant",
        fontSize: 14,
    },
}));

export const EmailSentSuccessfullyPopUp = observer(() => {
    const store = useScreenPricingStore();
    const { isSendEmailToSales, setIsSendEmailToSales } = store;
    const classes = useStyles();

    const handleClose = () => {
        setIsSendEmailToSales(false);
    };

    return (
        <div>
            <Dialog open={isSendEmailToSales} TransitionComponent={Transition} keepMounted onClose={handleClose} aria-labelledby="alert-dialog-slide-title" aria-describedby="alert-dialog-slide-description">
                <DialogTitle className={classes.title} id="alert-dialog-slide-title">
                    {" "}
                    {t("business.pricing.popUp.title")}
                </DialogTitle>
                <DialogContent>
                    <DialogContentText className={classes.content} id="alert-dialog-slide-description">
                        {t("business.pricing.popUp.content")}
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button className={classes.buttons} onClick={handleClose}>
                        {t("business.pricing.popUp.closeButton")}
                    </Button>
                </DialogActions>
            </Dialog>
        </div>
    );
});
