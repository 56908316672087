/* eslint-disable no-unused-vars */
import React from "react";
import useRouter from "utils/useRouter";
import clsx from "clsx";
import PropTypes from "prop-types";
import { makeStyles } from "@mui/styles";
import { AppBar, IconButton, Toolbar, Hidden } from "@mui/material";
import MenuIcon from "@mui/icons-material/Menu";
import ChangeSegmentDropDown from "./components/changeSegmentDropDown";
import LogoutButton from "./components/logoutButton";
import { useRootStore } from "commonStores/analytics/rootStoreContext";
import { getHomeUrl } from "./getHomeUrl";
import { GetCustomTopBarLogoStyles } from "./getCustomTopBarLogoStyles";
import { CenterUvtalLogo } from "./components/centerUvtalLogo";
import { getCustomTopBarColor } from "./getCustomTopBarColor";

const useStyles = makeStyles(theme => ({
    root: {
        boxShadow: "none",
    },
    flexGrow: {
        flexGrow: 1,
    },
    uvtalCenterLogo: {
        height: 35,
    },
}));

const TopBar = props => {
    const { onOpenNavBarMobile, className, ...rest } = props;
    const { userInfoStore } = useRootStore();
    const { dashboardType, isShowCustomTopBarLogo, currentSegmentId } = userInfoStore;
    const router = useRouter();
    const homeUrl = getHomeUrl(dashboardType, router);
    const classes = useStyles();
    const { uri: logoSrc, style: logoStyle } = GetCustomTopBarLogoStyles(currentSegmentId, isShowCustomTopBarLogo);
    const topBarColor = getCustomTopBarColor(currentSegmentId);

    return (
        <AppBar {...rest} className={clsx(classes.root, className)} color={topBarColor}>
            <Toolbar>
                <Hidden mdDown>
                    <LogoutButton />
                </Hidden>
                <div className={classes.flexGrow} />
                <CenterUvtalLogo />
                <div className={classes.flexGrow} />
                <ChangeSegmentDropDown />
                {logoSrc && <img alt="Logo" src={logoSrc} className={logoStyle} />}
                <Hidden lgUp>
                    <IconButton color="inherit" onClick={onOpenNavBarMobile}>
                        <MenuIcon />
                    </IconButton>
                </Hidden>
            </Toolbar>
        </AppBar>
    );
};

TopBar.propTypes = {
    className: PropTypes.string,
    onOpenNavBarMobile: PropTypes.func,
};

export default TopBar;
