export const validateIdNum = idNum => {
    if (!idNum) {
        return false;
    }
    const userIdNum = Number(idNum.replace(/\s/g, "")).toString();
    const onlyNumbers = /^\d+$/.test(userIdNum);
    if (!onlyNumbers) {
        return false;
    }
    if (userIdNum.length < 6) {
        return false;
    }
    return true;
};
