import React from "react";
import { makeStyles } from "@mui/styles";
import { Button } from "@mui/material";
import Grid from "@mui/material/Unstable_Grid2";
import { observer } from "mobx-react";
import { useScreenHideCouponTypesStore } from "../store";
import { t } from "common/localization/translate";
import CircularProgress from "@mui/material/CircularProgress";

const useStyles = makeStyles(theme => ({
    buttons: {
        marginTop: 5,
        backgroundColor: theme.palette.primary.main,
        fontSize: 14,
        color: theme.palette.white,
        height: 35,
        width: 150,
        marginRight: 5,
        "&:hover": {
            backgroundColor: theme.palette.primary.secondGraphColor,
        },
    },
}));
export const HideCouponTypeButton = observer(() => {
    const classes = useStyles();
    const store = useScreenHideCouponTypesStore();

    const onClick = () => {
        store.isAreYouSureHideCouponTypePopUp = true;
    };

    const buttonContent = store.isFetchingHidingCoupon ? <CircularProgress color="white" size={20} /> : t("organizationDashboard.hideCouponType.hideCouponTypeButton");

    return (
        <Grid item xs={12} spacing={3}>
            <Button variant="contained" className={classes.buttons} onClick={onClick}>
                {buttonContent}
            </Button>
        </Grid>
    );
});
