import React from "react";
import { observer } from "mobx-react";
import { CityAndStreetInput } from "./cityAndStreetInput";
import { ApartmentInput } from "./apartmentInput";
import { HouseInput } from "./houseInput";
import { activationTypes } from "../../../store/logic/coupontypeActivationTypes";

export const DeliveryInputs = observer(({ activationType }) => {
    if (activationType != activationTypes.DELIVERY && activationType != activationTypes.COLLECTING_POINT) {
        return null;
    }

    return (
        <>
            <CityAndStreetInput />
            <HouseInput />
            <ApartmentInput />
        </>
    );
});
