import React from "react";
import { Link as RouterLink } from "react-router-dom";
import clsx from "clsx";
import PropTypes from "prop-types";
import { makeStyles } from "@mui/styles";
import { AppBar, Toolbar } from "@mui/material";

const useStyles = makeStyles(() => ({
    root: {
        boxShadow: "none",
        backgroundColor: "#F3C22C",
    },
    img: {
        height: 45,
        marginTop: 10,
        width: 220,
    },
}));

const Topbar = props => {
    const { className, ...rest } = props;

    const classes = useStyles();

    return (
        <AppBar {...rest} className={clsx(classes.root, className)} color="primary">
            <Toolbar>
                <RouterLink to="/organization/login">
                    <img alt="Logo" src="/images/organization/yuvital_logo_2.png" className={classes.img} />
                </RouterLink>
            </Toolbar>
        </AppBar>
    );
};

Topbar.propTypes = {
    className: PropTypes.string,
};

export default Topbar;
