import { isMultiSegment } from "../../../healthAndFitnessData/store/logic/isMultiSegment";

const WOW_JUNIOR_A = 81;
const WOW_JUNIOR_B = 82;
const WOW_JUNIOR_C = 83;

export const wowJuniorSegmentIds = [WOW_JUNIOR_A, WOW_JUNIOR_B, WOW_JUNIOR_C];

export function isWowJuniorSegment(segment) {
    if (!segment) {
        return false;
    }
    if (!isMultiSegment(segment)) {
        return wowJuniorSegmentIds.includes(Number(segment));
    }
    return wowJuniorSegmentIds.includes(Number(segment?.mainSegmentId));
}
