import { t } from "common/localization/translate";
import { tableDataOptions } from "common/ui/tableDataOptions";

export function getTraineesInfoTableColumns() {
    const columnsDefault = [
        {
            name: "userName",
            label: t("screenTrainesInfo.table.col.userName"),
            options: tableDataOptions,
        },
        {
            name: "identifier",
            label: t("screenTrainesInfo.table.col.identifier"),
            options: tableDataOptions,
        },
        {
            name: "phone",
            label: t("screenTrainesInfo.table.col.phone"),
            options: tableDataOptions,
        },
        {
            name: "email",
            label: t("screenTrainesInfo.table.col.email"),
            options: tableDataOptions,
        },
        {
            name: "registrationDate",
            label: t("screenTrainesInfo.table.col.registrationDate"),
            options: tableDataOptions,
        },
        {
            name: "lastOpenDate",
            label: t("screenTrainesInfo.table.col.lastOpenDate"),
            options: tableDataOptions,
        },
        {
            name: "stepsAvg",
            label: t("screenTrainesInfo.table.col.stepsAvg"),
            options: tableDataOptions,
        },
        {
            name: "appOpens",
            label: t("screenTrainesInfo.table.col.appOpens"),
            options: tableDataOptions,
        },
        {
            name: "articleReading",
            label: t("screenTrainesInfo.table.col.articleReading"),
            options: tableDataOptions,
        },
        {
            name: "videosConsumption",
            label: t("screenTrainesInfo.table.col.videosConsumption"),
            options: tableDataOptions,
        },
        {
            name: "lastContentConsumption",
            label: t("screenTrainesInfo.table.col.lastContentConsumption"),
            options: tableDataOptions,
        },
    ];

    return columnsDefault;
}
