import React from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import { makeStyles } from "@mui/styles";
import { useScreenStaffInfoStore } from "../store";
import { observer } from "mobx-react-lite";
import { t } from "common/localization/translate";

const useStyles = makeStyles(theme => ({
    root: {
        backgroundColor: theme.palette.primary.main,
        "& h6": {
            color: theme.palette.white,
            fontSize: 14,
        },
    },
    dialogDiv: {
        width: 600,
        height: 175,
        [theme.breakpoints.down("sm")]: {
            width: "100%",
            height: "auto",
        },
    },
    buttons: {
        backgroundColor: theme.palette.primary.main,
        fontSize: 14,
        color: theme.palette.white,
        marginLeft: 21,
        marginTop: 18,
        "&:hover": {
            backgroundColor: theme.palette.primary.secondGraphColor,
            color: theme.palette.white,
        },
    },
    buttonsContainer: {
        display: "flex",
        justifyContent: "center",
        [theme.breakpoints.down("sm")]: {
            justifyContent: "flex-start",
        },
    },
}));

export const SendExcelMailPopup = observer(() => {
    const store = useScreenStaffInfoStore();
    const classes = useStyles();
    const handleClose = () => {
        store.isSendExcelMailPopUpVisible = false;
    };

    return (
        <div>
            <Dialog className={classes.dialog} open={store.isSendExcelMailPopUpVisible} aria-labelledby="responsive-dialog-title">
                <div className={classes.dialogDiv}>
                    <DialogTitle className={classes.root} id="responsive-dialog-title">
                        {" "}
                        {t("screenStaffInfo.sendExcelPopup.title")}{" "}
                    </DialogTitle>
                    <DialogContent>
                        <DialogContentText> {t("screenStaffInfo.sendExcelPopup.description", { email: store.email })} </DialogContentText>
                    </DialogContent>
                    <DialogActions className={classes.buttonsContainer}>
                        <Button className={classes.buttons} size="small" type="submit" variant="contained" onClick={handleClose}>
                            {t("screenCouponsUser.popUp.closeButton")}
                        </Button>
                    </DialogActions>
                </div>
            </Dialog>
        </div>
    );
});
