import React from "react";
import { makeStyles } from "@mui/styles";
import { observer } from "mobx-react";
import { Page } from "components";

const useStyles = makeStyles(() => ({
    root: {
        marginBottom: 20,
    },
}));

export const DataPage = observer(({ children }) => {
    const classes = useStyles();

    return <Page className={classes.root}>{children}</Page>;
});
