import { t } from "../../../../../common/localization/translate";

export function formatMinutesToDisplay(minutes) {
    const MINUTES_IN_HOUR = 60;
    const MINUTES_IN_DAY = 1440;
    if (minutes < MINUTES_IN_HOUR) {
        return t("screenPurchaseFromSupport.coupons.column.duration.minutes", {
            time: minutes,
        });
    }
    if (minutes === MINUTES_IN_HOUR) {
        return t("screenPurchaseFromSupport.coupons.column.duration.oneHour", {
            time: minutes,
        });
    }
    if (minutes === MINUTES_IN_HOUR * 2) {
        return t("screenPurchaseFromSupport.coupons.column.duration.twoHours", {
            time: minutes,
        });
    }
    if (minutes < MINUTES_IN_DAY) {
        const hours = Math.round(minutes / MINUTES_IN_HOUR);
        return t("screenPurchaseFromSupport.coupons.column.duration.hours", {
            time: hours,
        });
    }
    if (minutes === MINUTES_IN_DAY) {
        return t("screenPurchaseFromSupport.coupons.column.duration.oneDay");
    }
    if (minutes === MINUTES_IN_DAY * 2) {
        return t("screenPurchaseFromSupport.coupons.column.duration.twoDays");
    }
    const days = Math.round(minutes / MINUTES_IN_DAY);
    return t("screenPurchaseFromSupport.coupons.column.duration.days", {
        time: days,
    });
}
