import React from "react";
import { observer } from "mobx-react";
import { useScreenPurchaseFromSupportStore } from "../../../store";
import { makeStyles } from "@mui/styles";
import { CircularProgress, Typography } from "@mui/material";

const useStyles = makeStyles(() => ({
    streetsContainer: {
        display: "flex",
        position: "absolute",
        flexDirection: "column",
        gap: "0px",
        padding: "16px",
        marginTop: "8px",
        maxWidth: "205px",
        minWidth: "205px",
        maxHeight: "150px",
        overflowY: "auto",
        border: "1px solid #ddd",
        borderRadius: "8px",
        backgroundColor: "#fff",
        "&::-webkit-scrollbar": {
            display: "none",
        },
        msOverflowStyle: "none",
        scrollbarWidth: "none",
        zIndex: 100,
    },
    listItem: {
        padding: "4px 12px",
        borderBottom: "1px solid #ddd",
        backgroundColor: "#fff",
        cursor: "pointer",
        transition: "background-color 0.2s ease",
        "&:last-child": {
            borderBottom: "none",
        },
    },
    spinnerContainer: {
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        height: "100%",
    },
}));

export const StreetAndHouseList = observer(() => {
    const store = useScreenPurchaseFromSupportStore();
    const classes = useStyles();
    const { pickupStreetAndHouseSearchResult, isFetchingPickupStreetAndHouse, isOpenedPickupStreetAndHouse } = store;

    if (!pickupStreetAndHouseSearchResult || !isOpenedPickupStreetAndHouse) {
        return null;
    }

    const addresses = pickupStreetAndHouseSearchResult.map(city => ({
        name: city.name,
        placeId: city.placeId,
    }));

    const handleStreetClick = address => {
        store.streetAndHouseSearch = address.name;
        store.isOpenedPickupPoints = true;
        store.sortByDistance(address);
    };

    return (
        <div className={classes.streetsContainer}>
            {isFetchingPickupStreetAndHouse ? (
                <div className={classes.spinnerContainer}>
                    <CircularProgress color="primary" size={20} />
                </div>
            ) : (
                addresses.map((address, index) => (
                    <div key={index} className={classes.listItem} onClick={() => handleStreetClick(address)}>
                        <Typography>{address.name}</Typography>
                    </div>
                ))
            )}
        </div>
    );
});
