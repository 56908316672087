import React from "react";
import { TextField } from "@mui/material";
import { observer } from "mobx-react";
import { makeStyles } from "@mui/styles";
import { useScreenPurchaseFromSupportStore } from "../../../store";
import { t } from "../../../../../../common/localization/translate";
import { StreetAndHouseList } from "./streetAndHouseList";

const useStyles = makeStyles(() => ({
    container: {
        marginRight: "16px",
    },
    input: {
        width: "205px",
        "& .MuiOutlinedInput-root": {
            borderRadius: "10px",
        },
    },
}));

export const StreetAndHouseInput = observer(() => {
    const store = useScreenPurchaseFromSupportStore();
    const classes = useStyles();

    const handleInputChange = event => {
        store.isOpenedPickupStreetAndHouse = true;
        const { value } = event.target;
        store.streetAndHouseSearch = value.name;
        store.isOpenedPickupPoints = false;
        store.errorMessageStreetAndHouse = "";
        store.searchStreetAndHouseCollectionPoints(value);
    };

    const handleBlur = () => {
        setTimeout(() => {
            store.isOpenedPickupStreetAndHouse = false;
        }, 500);
    };

    const error = store.errorMessageStreetAndHouse;

    return (
        <div className={classes.container}>
            <TextField
                disabled={store.isFetchingPickupPoints}
                value={store.streetAndHouseSearch}
                className={classes.input}
                id="standard-basic"
                type="string"
                variant="outlined"
                error={error}
                label={t("screenPurchaseFromSupport.coupons.deliveryModal.streetAndHouse")}
                helperText={error}
                onChange={handleInputChange}
                onBlur={handleBlur}
            />
            <StreetAndHouseList />
        </div>
    );
});
