import React, { useState } from "react";
import { observer, useObserver } from "mobx-react";
import MUIDataTable from "mui-datatables";
import { InputAdornment, TextField } from "@mui/material";
import { makeStyles } from "@mui/styles";
import { useScreenPurchaseFromSupportStore } from "../../store/index";
import { LoadingSpinner } from "../../../../../common/ui/loadingSpinner";
import { t } from "common/localization/translate";
import { getTableColumns } from "./tableColumns";
import { ImageModal } from "./imageModal";
import { Search } from "@mui/icons-material";

const useStyles = makeStyles(() => ({
    searchContainer: {
        marginTop: 55,
        marginBottom: 35,
        width: "32%",
    },
}));

export const AllCouponsTable = observer(() => {
    const store = useScreenPurchaseFromSupportStore();
    const classes = useStyles();
    const [open, setOpen] = useState(false);
    const [currentImages, setCurrentImages] = useState([]);
    const [currentIndex, setCurrentIndex] = useState(0);
    const [searchText, setSearchText] = useState("");

    const handleImageClick = coupon => {
        let urlsList = coupon.images ? coupon.images.map(image => image.uri) : [coupon.imageUrl];
        store.setCurrentCoupon(coupon);
        setCurrentImages(urlsList);
        setCurrentIndex(0);
        setOpen(true);
    };

    const closeModal = () => {
        setOpen(false);
        setCurrentImages([]);
        setCurrentIndex(0);
        store.setCurrentRow(null);
    };

    const nextImage = () => {
        setCurrentIndex(prevIndex => (prevIndex + 1) % currentImages.length);
    };

    const prevImage = () => {
        setCurrentIndex(prevIndex => (prevIndex - 1 + currentImages.length) % currentImages.length);
    };

    const handleSearchChange = event => {
        setSearchText(event.target.value);
    };

    const highlightText = (text, highlight) => {
        if (!highlight.trim()) {
            return text;
        }
        const regex = new RegExp(`(${highlight})`, "gi"); // global search and case-insensitive
        return text.split(regex).map((part, index) =>
            part.toLowerCase() === highlight.toLowerCase() ? (
                <span key={index} style={{ backgroundColor: "yellow" }}>
                    {part}
                </span>
            ) : (
                part
            )
        );
    };

    return useObserver(() => {
        const { couponsTableData, errorCode, userName, setCurrentRow, currentRow, isFetching } = store;
        const columns = getTableColumns(handleImageClick, setCurrentRow, searchText, highlightText);

        const filteredData = couponsTableData.filter(row => Object.values(row).some(value => value?.toString().toLowerCase().includes(searchText.toLowerCase())));

        const rowsSelected = currentRow === null ? [] : [currentRow];
        const tableOptions = {
            filter: false,
            search: false,
            download: false,
            print: false,
            viewColumns: false,
            responsive: "scrollMaxHeight",
            selectableRows: "none",
            customToolbar: () => (
                <TextField
                    placeholder={t("screenPurchaseFromSupport.table.search")}
                    variant="standard"
                    size="small"
                    value={searchText}
                    onChange={handleSearchChange}
                    className={classes.searchContainer}
                    InputProps={{
                        startAdornment: <InputAdornment position="end">{<Search />}</InputAdornment>,
                    }}
                />
            ),
            onRowsDelete: () => false,
            textLabels: {
                body: {
                    noMatch: t("screenPurchaseFromSupport.table.col.noMatch"),
                },
                pagination: {
                    rowsPerPage: t("screenPurchaseFromSupport.table.pagination.rows"),
                },
            },
            rowsSelected,
        };

        let content = (
            <div style={{ minWidth: "100%" }}>
                <MUIDataTable title={t("screenPurchaseFromSupport.coupons.title")} data={filteredData} columns={columns} options={tableOptions} />
                <ImageModal open={open} currentImages={currentImages} currentIndex={currentIndex} onClose={closeModal} onNext={nextImage} onPrev={prevImage} couponTitle={store.coupon?.couponName} />
            </div>
        );
        if (errorCode || (couponsTableData.length === 0 && !userName)) {
            content = <div />;
        }

        return <LoadingSpinner isFetching={isFetching}>{content}</LoadingSpinner>;
    });
});
