export function getValidatedParams(params) {
    const keysArray = Object.keys(params);
    const newParams = {};
    let value;
    for (const key of keysArray) {
        value = params[key];
        if (value == null || value === undefined || (typeof value !== "string" && isNaN(value))) {
            newParams[key] = "---";
        } else newParams[key] = params[key];
    }
    return newParams;
}
