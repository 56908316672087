import React from "react";
import { formatNumber } from "common/formatNumber";
import { t } from "common/localization/translate";
import { tableDataOptions } from "common/ui/tableDataOptions";
import Skeleton from "@mui/material/Skeleton";
import { formatStepsDate } from "../store/logic/formatStepsDate";

export const getTableData = ({ isAddingSteps, selectedDaysPeriodLength }) => {
    const renderWithLoader = renderFn => (value, tableMeta) => {
        if (isAddingSteps && tableMeta.rowIndex < selectedDaysPeriodLength) {
            return <Skeleton variant="text" width="80%" />;
        }
        return renderFn(value);
    };

    const columns = [
        {
            name: "day",
            label: t("screenDailyStepsAndGoals.table.col.day"),
            options: {
                ...tableDataOptions,
                customBodyRender: renderWithLoader(value => <p style={{ textAlign: "right" }}>{formatStepsDate(value)}</p>),
            },
        },
        {
            name: "dailySteps",
            label: t("screenDailyStepsAndGoals.table.col.dailySteps"),
            options: {
                ...tableDataOptions,
                customBodyRender: renderWithLoader(value => <p style={{ textAlign: "right" }}>{formatNumber(value)}</p>),
            },
        },
        {
            name: "goal",
            label: t("screenDailyStepsAndGoals.table.col.goal"),
            options: {
                ...tableDataOptions,
                customBodyRender: renderWithLoader(value => <p style={{ textAlign: "right" }}>{formatNumber(value)}</p>),
            },
        },
        {
            name: "goalMet",
            label: t("screenDailyStepsAndGoals.table.col.goalMet"),
            options: {
                ...tableDataOptions,
                customBodyRender: renderWithLoader(isGoalMet => {
                    const styles = { green: { color: "green" }, red: { color: "red" } };
                    const text = isGoalMet ? t("screenDailyStepsAndGoals.table.userDidMetGoal") : t("screenDailyStepsAndGoals.table.userDidNotMetGoal");
                    const style = isGoalMet ? styles.green : styles.red;
                    return <p style={{ ...style, textAlign: "right" }}>{text}</p>;
                }),
            },
        },
    ];

    const options = {
        download: false,
        filter: false,
        print: false,
        viewColumns: false,
        responsive: "scrollMaxHeight",
        selectableRows: "none",
        textLabels: {
            body: {
                noMatch: t("screenDailyStepsAndGoals.table.col.noMatch"),
            },
            pagination: {
                rowsPerPage: t("screenAddSteps.table.pagination.rows"),
            },
        },
    };
    return { options, columns };
};
