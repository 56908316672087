import { t } from "../../../../../common/localization/translate";
import { validatePhoneNumber } from "../../../editUserInfo/store/logic/validatePhoneNumber";
import { activationTypes } from "./coupontypeActivationTypes";
import { validateFullName } from "./validateFullName";
import { validateHouseNumber } from "./validateHouseNumber";
import { validateStreet } from "./validateStreet";

export function validateInputs(store) {
    const { deliveryUserName, deliveryUserPhone, street, house, coupon } = store;

    const isNameValid = validateFullName(deliveryUserName);
    if (!isNameValid) {
        store.userNameError = t("screenPurchaseFromSupport.coupons.deliveryModal.name.error");
    }

    const isPhoneValid = validatePhoneNumber(deliveryUserPhone, true);
    if (!isPhoneValid) {
        store.deliveryUserPhoneError = t("screenPurchaseFromSupport.coupons.deliveryModal.phone.error");
    }

    const isStreetValid = validateStreet(street);
    if (!isStreetValid) {
        store.errorMessageStreet = t("screenPurchaseFromSupport.coupons.deliveryModal.street.error");
    }
    const isHouseValid = validateHouseNumber(house);
    if (!isHouseValid) {
        store.houseError = t("screenPurchaseFromSupport.coupons.deliveryModal.house.error");
    }

    if (isNameValid && isPhoneValid && isStreetValid && isHouseValid) {
        return true;
    }

    if (!store.city && !store.street) {
        store.errorMessageStreet = t("screenPurchaseFromSupport.coupons.deliveryModal.streetAndCity.error");
    }

    if (coupon.activationType == activationTypes.PICKUP) {
        if (!store.selectedPickupPoint) {
            store.pickupErrorMessage = t("'screenPurchaseFromSupport.coupons.deliveryModal.pickup.city.error.missing'");
        }

        if (!store.streetAndHouseSearch) {
            store.errorMessageStreetAndHouse = t("screenPurchaseFromSupport.coupons.deliveryModal.pickup.streetAndHouse.error");
        }
    }

    return false;
}
