import { t } from "common/localization/translate";

const periodTypes = {
    LAST_THIRTY_DAYS: "LAST_THIRTY_DAYS",
    LAST_THREE_MONTHS: "LAST_THREE_MONTHS",
    LAST_SIX_MONTHS: "LAST_SIX_MONTHS",
};

export function getPeriods() {
    return [
        {
            type: periodTypes.LAST_SIX_MONTHS,
            text: t("businessHome.ImpressionChart.dropDownOption.6Months"),
        },
        {
            type: periodTypes.LAST_THREE_MONTHS,
            text: t("businessHome.ImpressionChart.dropDownOption.3Months"),
        },
        {
            type: periodTypes.LAST_THIRTY_DAYS,
            text: t("businessHome.ImpressionChart.dropDownOption.30days"),
        },
    ];
}
