import React from "react";
import { observer } from "mobx-react";
import Grid from "@mui/material/Unstable_Grid2";
import { makeStyles } from "@mui/styles";
import { Typography } from "@mui/material";
import { useScreenAddStepsStore } from "../../../store";
import { t } from "../../../../../../common/localization/translate";

const useStyles = makeStyles(() => ({
    row: {
        width: "100%",
        alignItems: "center",
        padding: 20,
    },
    placeholder: {
        height: 100,
    },
}));

export const UserName = observer(() => {
    const store = useScreenAddStepsStore();
    const { errorCode, userName } = store;

    const classes = useStyles();

    if (errorCode || !userName) {
        return <Grid className={classes.placeholder} container lg={12} xs={12} spacing={2.3}></Grid>;
    }

    return (
        <Grid className={classes.row} container lg={12} xs={12} spacing={1}>
            <div>
                <Typography sx={{ fontSize: "15px", fontWeight: "normal", paddingLeft: 3, color: "#596D79", marginBottom: 1 }}>{t("screenPurchaseFromSupport.userInfo.name")}</Typography>
                <Typography sx={{ fontSize: "26px", fontWeight: "normal", paddingLeft: 3 }}>{userName}</Typography>
            </div>
        </Grid>
    );
});
